@media only screen and (min-width: 576px) {
    .container{
        max-width: unset !important;
    }

}

@media only screen and (min-width: 992px) {
    .selects-body .form-label {
        font-size: 14px;
    }
    
    .selects-body .form-select {
        font-size: 14px;
    }
    
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1440px){
    .main-logo {
        padding: 20px 20px;
    }
    .write-off {
        font-size: 12px;
        color: var(--Black, #121212);
        /* font-family: Outfit; */
        /* font-size: 16px; */
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    .page-title h4 {
        font-size: 20px;
    }
    
    .Totalpackages p {
        font-size: 14px;
    }
    
    .Totalpackages p span {
        font-size: 14px;
    }
    
    .admin-profile {
        gap: 15px;
    }
    
    .MainHeader {
        padding: 20px;
    }

    .main-logo a img {
        width: 108px;
    }
    
    /* .sidebar {
        width: 20%;
    } */
    
    .mainprime {
        width: 80%;
    }
    
    .right-search input {
        /* padding-left: 40px !important; */
        
    }
    .earning-data h2 {
        font-size: 28px;
    }
    
    .daily-earning {
        padding: 20px;
    }
.package-service {
    padding-top: 80px;
}
.canceladd-btn {
    padding: 20px 0 0;
}
.managepackege-table {
    overflow-x: auto;
}

.table-header tr th {
    padding: 22px;
    white-space: nowrap;
    font-size: 14px;
}

.table-body tr td:first-child {
    white-space: nowrap;
    font-size: 14px;
}

.table-body tr td {
    font-size: 14px;
    padding: 22px;
    white-space: nowrap;
}
.search-package {
    max-width: 50%;
}
.search-package {
    max-width: 50%;
}

.packagename-td {
    font-size: 14px;
}

.green-money {
    font-size: 14px;
}

.red-money {
    font-size: 14px;
}
.created-date {
    font-size: 14px;
}

.date-created {
    font-size: 14px;
}

.days-left {
    font-size: 14px;
}

.red-data {
    font-size: 14px;
}
.col-heading h4 {
    font-size: 24px;
    margin: 0;
}

.col-heading h4 span {
    font-size: 24px;
}
.mega-chart {
    padding: 20px;
}

.leads-body {
    padding: 10px;
    gap: 10px;
}
.new-leads {
    font-size: 16px;
}

.leds-ct {
    font-size: 28px;
}

.leds-ct span {
    font-size: 18px;
}
.details-heading {
    margin-bottom: 10px;
}
.editandmember {
    width: 60%;
}
.editandmember {
    width: 60%;
}

.shadow-padd {
    padding: 20px;
}

.member-code h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.member-code h4 span {
    font-size: 16px;
}

.member-code img {
    width: 120px;
}

.about-profile h3 {
    font-size: 18px;
}

.about-profile h5 {
    font-size: 16px;
}

.about-profile p {
    font-size: 16px;
}

.about-profile p span {
    font-size: 16px;
}
.editandmember {
    width: 60%;
}

.shadow-padd {
    padding: 20px;
}

.member-code h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.member-code h4 span {
    font-size: 16px;
}

.member-code img {
    width: 120px;
}

.about-profile h3 {
    font-size: 18px;
}

.about-profile h5 {
    font-size: 16px;
}

.about-profile p {
    font-size: 16px;
}

.about-profile p span {
    font-size: 16px;
}

.info-layout {
    gap: 5px;
}

.label-text {
    font-size: 14px;
}

.label-sub-text {
    font-size: 14px;
}

.change-date {
    font-size: 14px;
}

.pay-balance {
    font-size: 12px;
    margin: 0 5px 0 5px;
}

span.cash-text {
    font-size: 14px;
}

.sales-person {
    font-size: 16px;
}

.info-heading {
    font-size: 18px;
}

.Convertibility-radio {
    flex-wrap: wrap;
    gap: 15px;
}
.add-service {
    width: 50%;
}

/* .can-btn {
    width: 50%;
} */
.mem-ren .can-btn {
    width: unset;
}

} 
@media screen and (min-device-width: 1441px) and (max-device-width: 1600px) {
    .main-logo {
        padding: 17px 24px;
    }
}

@media only screen and (min-width: 1440px) {
    
    .active-content{
        font-size: 16px;
    }

    .g-text span{
        font-size: 14px;
    }
    .source-body-content span{
        font-size: 14px;
    }

    .col-heading h4 {
        font-size: 20px;
    }
    
    .col-heading h4 span {
        font-size: 20px;
    }
    
    .payment-text {
        font-size: 20px;
    }
    
    .source-text {
        font-size: 20px;
    }
    
    .mem-count {
        font-size: 20px;
    }
    
    .expected-text {
        font-size: 20px;
    }
    
    .expected-text .for-month {
        font-size: 12px;
    }
    
    .right-search input::placeholder{
        font-size: 13px;
    }
    .right-search input {
        /* padding-left: 40px !important; */
        min-width: unset !important;
    }

    .collection-data h5 {
        font-size: 20px;
    }
    .collection-data h5 {
        font-size: 20px;
    }
    
    .leds-ct {
        font-size: 20px;
    }
    
    .pending-text {
        font-size: 16px;
    }
    
    .number-count {
        font-size: 16px;
    }
    .gym-sidebarmenu a {
        font-size: 16px;
    }
    
    .accordion-button a {
        font-size: 16px;
    }
    
    .member-sub-menu .gym-submenu a {
        font-size: 16px;
        
    }
    .mainprime-wrapper {
        padding: 24px;
    }
    
    .MainHeader {
        padding: 27px 24px;
    }
    
    
    .home-wrapper {
        grid-template-columns: 3fr 9fr;
    }
    .page-title h4 {
        font-size: 26px;
    }
    
    .Totalpackages p {
        font-size: 16px;
    }
    
    .Totalpackages p span {
        font-size: 16px;
    }
    .mega-chart {
        padding: 20px;
        margin-top: 20px;
    }
    
    .table-header tr th {
        font-size: 14px;
        padding: 22px 5px;
    }
    
    .table-header tr th:first-child {
        padding: 22px 10px;
    }
    
    .table-body tr td {
        padding: 22px 5px;
        font-size: 14px;
    }
    
    .table-body tr td:first-child {
        padding: 22px 10px;
    }
    
    .packagename-td {
        font-size: 14px;
    }
    
    .green-money {
        font-size: 14px;
    }
    
    .red-money {
        font-size: 14px;
    }
    
    .table-header tr th:last-child {
        padding: 22px 5px;
    }
    
    .table-body tr td:last-child {
        padding: 22px 10px;
    }
    .selects-body .form-select {
        font-size: 14px;
    }
    .selects-body .form-label {
        font-size: 14px;
    }
    .days-left {
        font-size: 14px;
    }
    
    .red-data {
        font-size: 14px;
    }
    
    .created-date {
        font-size: 14px;
    }
    
    .date-created {
        font-size: 14px;
    }
    .label-text {
        font-size: 16px;
    }
    
    .label-sub-text {
        font-size: 16px;
    }
    
    .about-profile h3 {
        font-size: 22px;
    }
    
    .member-code h4 {
        font-size: 16px;
    }
    
    .about-profile h5 {
        font-size: 16px;
    }
    
    .about-profile p {
        font-size: 16px;
    }
    
    .about-profile p span {
        font-size: 16px;
    }
    
    .info-layout {
        gap: 20px;
    }
    .Convertibility-radio .form-check label {
        font-size: 14px;
    }
    

}

@media only screen and (max-width: 992px) {
    /* .sidebar{
        display: none;
    } */
    .mainprime{
        width: 100%;
    }
    nav.mobile-header-bg {
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .mobile-header{
        display: block;
        
    }
    .MainHeader{
        display: none;
    }
    nav.mobile-header-bg {
        background-color: #FFFFFF;
    }
    
    .main-logo {
        padding: 0;
        border-bottom: 0;
        box-shadow: unset;
        min-height: unset;
    }
    .gym-sidebarmenu-dropdown a {
        padding: 29px 20px 29px 20px !important;
        border-radius: 0 !important;
        gap: 0px;
        border-color: unset !important;
        background-color: unset !important;
        box-shadow: unset !important;
        color: #494A4C;
        /* font-family: Outfit; */
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-bottom: 1px solid #EFEFEF !important;
    }

    .navbar-nav .dropdown-menu {
        border: 0;
        background-color: #F8F8F8;
        padding: 0  15px;
        width: max-content;
    }
    .gym-sidebarmenu-dropdown  .dropdown-menu {
        width: auto;
    }
    .navbar-nav .dropdown-menu span{
        padding-left: 15px;
    }
}

@media only screen and (max-width: 992px) {
    .empty-box {
        display: none;
    }
    .form-label .sub-text {
        font-size: 10px;
    }
    
    .admin-profile-log-mobile {
        padding: 29px 20px;
    }
    .admin-profile-log-mobile .nav-link {
        padding: 0px 0px;
        display: block;
        /* border-bottom: 0.8px solid #EFEFEF; */
        color: #494A4C;
        /* font-family: Outfit; */
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    
    .mobile-header .navbar-collapse {
        position: fixed;
        display: block !important;
        top: 95px;
        background: rgb(255, 255, 255);
        width: 100%;
        left: 0px;
        z-index: 999;
        transform: translateX(-100%);
        transition: transform 0.3s linear;
        height: 100vh !important;
    }
    .mobile-header .navbar-collapse.show{
        transform: translateX(0%);
    }

    .mobile-header .navbar-toggler {
        border: 0 !important;
    }
    .mobile-header .navbar-toggler:focus{
        box-shadow: unset !important;
    }
    
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAyElEQVR4AZ3SzQ2DMAwF4AQv0JVgoap3UIOAeyci5dZ1OkHqVwUppflxeAcgjvwpgPWyLFfn3KXv+1GdiO/XDS68NvM831Vl0MP9DxyE1nV9tW2rOabrOsXrTYrgAIyMwzAYQtFa+6zBjghqtG9KsRjyA0mwFPIH5bAcgmiVyDRNhrFvsy8lkSx0wFQOQZocxIiLPcdCqY3wm/B9K/1NKiF4HcloUAnZ6yWMJIgEIylSwqgGyWG6FgkTzNkNc/TGQy2CoMePh/oAXDfsaXWobFgAAAAASUVORK5CYII=);
        width: 1.5em !important;
        height: 1.5em !important;
        background-size: inherit;
    }
    .target-title{
        font-size: 16px;
    }
    .add-member-header a{
        width: 50%;

    }
    .search-package{
        max-width: 50%;
    }
    .selects-body .form-label{
        font-size: 14px;
    }
    .selects-body .form-select{
        font-size: 14px;
    }
    .new-leads {
        font-size: 16px;
    }
    
    .leds-ct {
        font-size: 28px;
    }
    
    .leds-ct span {
        font-size: 18px;
    }
    
    .leads-body {
        height: 100%;
        gap: 10px;
    }
    .input-radio{
        gap:69px;
        justify-content: flex-start;
    }
    .editandmember{
        width: 60%;
    }
    .pay-balance {
        font-size: 14px;
        margin: 0 9px;
    }
    
    .write-off {
        font-size: 14px;
    }
    .canceladd-btn {
        padding: 10px;
    }
    .convert-cancel {
        justify-content: flex-start;
        padding-left: 0;
    }
    .noti-hum {
        display: flex;
    }
    body:has(.noti-hum .show) {
        overflow: hidden;
    }
    .package-buttons {
        left: 0;
    }
    
    .manapackage-header {
        padding-top: 70px;
    }
}

@media only screen and (max-width: 820px) {
    .manapackage-header {
        padding-top: 70px;
    }
    
    .package-buttons {
        left: 0;
    }
    .mobile-header .navbar-collapse {
        position: fixed;
        display: block !important;
        top: 95px;
        background: rgb(255, 255, 255);
        width: 100%;
        left: 0px;
        z-index: 999;
        transform: translateX(-100%);
        transition: transform 0.3s linear;
        height: 100vh !important;
    }
    body:has(.noti-hum .show) {
        overflow: hidden;
    }
    .mobile-header .navbar-collapse.show {
        overflow-y: auto;
    }
}

@media only screen and (max-width: 768px) {
    .red-money {
        font-size: 14px;
    }
    
    .days-left {
        font-size: 14px;
    }
    
    .red-data {
        font-size: 14px;
    }
    
    .packagename-td {
        font-size: 14px;
    }
    
    .created-date {
        font-size: 14px;
    }
    
    .date-created {
        font-size: 14px;
    }
    .table-header tr th {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .managepackege-table {
        overflow-x: auto;
    }
    
    .table-header tr th:first-child {
        padding: 22px 30px;
    }
    
    .table-header tr th:last-child {
        padding: 22px 30px;
    }
    
    tbody.table-body {
        overflow-x: auto;
    }
    
    .table-body tr td:first-child {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .table-body tr td {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .table-body tr td:last-child {
        padding: 22px 30px;
    }
    .package-buttons a .addpack-btn {
        width: 100%;
    }
    .daily-earning {
        padding: 10px;
    }
    .show-search {
        margin: 0 !important;
    }
    .notification-ring svg {
        width: 24px;
    }
    
    .noti-count span {
        font-size: 10px;
    }
    
    .mega-chart {
        padding: 15px;
        margin-top: 25px;
    }
    .mega-chart .col-lg-2 {
        display: none;
    }
    .yourcol-head {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }
    
    .col-heading h4 {
        font-size: 24px;
    }
    
    .col-heading h4 span {
        font-size: 24px;
    }
    .mainprime-wrapper {
        padding: 15px;
    }
    .add-member-header {
        flex-direction: row;
        gap: 15px;
    }
    
    .add-member-header a {
        width: 100%;
    }
    
    .search-package {
        max-width: 100%;
    }
    
    .admin-profile-log-mobile {
        padding: 29px 20px;
    }
    
    .admin-profile-log-mobile .nav-link {
        padding: 0px 0px;
        display: block;
        /* border-bottom: 0.8px solid #EFEFEF; */
        color: #494A4C;
        /* font-family: Outfit; */
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .mobile-header .navbar-collapse {
        position: fixed;
        display: block !important;
        top: 70px;
        background: rgb(255, 255, 255);
        width: 100%;
        left: 0px;
        z-index: 999;
        transform: translateX(-100%);
        transition: transform 0.3s linear;
        height: 100vh !important;
    }
    .mobile-header .navbar-collapse.show{
        transform: translateX(0%);
    }

    .mobile-header .navbar-toggler {
        border: 0 !important;
    }
    .mobile-header .navbar-toggler:focus{
        box-shadow: unset !important;
    }
    
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAyElEQVR4AZ3SzQ2DMAwF4AQv0JVgoap3UIOAeyci5dZ1OkHqVwUppflxeAcgjvwpgPWyLFfn3KXv+1GdiO/XDS68NvM831Vl0MP9DxyE1nV9tW2rOabrOsXrTYrgAIyMwzAYQtFa+6zBjghqtG9KsRjyA0mwFPIH5bAcgmiVyDRNhrFvsy8lkSx0wFQOQZocxIiLPcdCqY3wm/B9K/1NKiF4HcloUAnZ6yWMJIgEIylSwqgGyWG6FgkTzNkNc/TGQy2CoMePh/oAXDfsaXWobFgAAAAASUVORK5CYII=);
        width: 1.5em !important;
        height: 1.5em !important;
        background-size: inherit;
    }
    .package-buttons {
    flex-direction: column;
    align-items: end;
    /* transform: translatex(-50%); */
    width: 100%;
}
.manapackage-header {
    padding-top: 171px;
}
.package-buttons a {
    width: 100%;
}
.addser-btn {
    width: 100%;
}
.package-buttons {
    flex-direction: row;
    align-items: end;
    /* transform: translatex(-50%); */
    width: 100%;
}
.manapackage-header {
    padding-top: 80px;
}
.package-buttons a {
    width: 50%;
}
.addser-btn {
    width: 100%;
}

.tabs-group .nav-item .nav-link {
    width: 100%;
}
.your-collection {
    padding: 15px;
}

.notification-text {
    font-size: 24px;
}

.notification-header .btn-close {
    top: 17px;
}

.modal-body {
    margin-top: 10px;
}

.list-content {
    padding: 17px 0;
}

.modal-listng {
    padding: 0 10px;
}

.notification-content {font-size: 14px;}

.name-content {
    font-size: 14px;
}

.notification-day {
    font-size: 14px;
}
.earning-data h2 {
    font-size: 24px;
}
.manage-member-header {
    flex-direction: column;
    align-items: baseline;
}

.editandmember {
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.editandmember a {
    width: 100%;
}

.sales-target {
    padding: 15px;
}

.member-code img {
    width: 120px;
}

.shadow-padd {
    padding: 20px;
}

.about-profile h3 {
    font-size: 20px;
}

.member-code h4 {
    font-size: 16px;
}

.about-profile h5 {
    font-size: 16px;
}

.about-profile p {
    font-size: 16px;
}

.about-profile p span {
    font-size: 16px;
}

.info-heading {
    font-size: 18px;
}

.info-layout {
    gap: 10px;
}

.label-text {
    font-size: 14px;
}

.label-sub-text {
    font-size: 14px;
}

.change-date {
    font-size: 14px;
}

.green-money {
    font-size: 14px;
}

span.cash-text {
    font-size: 10px;
}

.sales-person {
    font-size: 10px;
}

.pay-balance {
    margin: 0 10 0px 10px;
    font-size: 13px;
}
.managepackage-main {
    gap: 20px;
}

.canceladd-btn {
    padding: 0;
}
.form-label {
    font-size: 14px;
}

.form-select {
    font-size: 14px;
}

input.form-control {
    font-size: 14px;
}
.form-check .form-check-input {
    width: 16px;
    height: 16px;
}
.form-check .form-check-input {
    width: 16px;
    height: 16px;
}

label.expected-label {
    font-size: 10px;
}

.form-check {
    display: flex;
    align-items: center;
}

label.declare-text {
    margin-bottom: 0;
}
textarea.form-control {
    font-size: 14px;
}
.update-left {
    padding: 0;
}
.Convertibility-radio .form-check label {
    font-size: 10px;
}
.input-radio-gender label {
    font-size: 10px;
}

.input-radio .form-check label {
    font-size: 10px;
}

.convert-cancel button {
    width: 100%;
}

.details-heading {
    font-size: 18px;
}
.frezze-box svg {
    width: 60px;
    height: auto;
}

.member-code h4 span {
    font-size: 16px;
}
.search-package input::placeholder{
    font-size: 12px;
}


}


@media only screen and (max-width: 576px) {
    .add-package-form .canceladd-btn .add-btn {
        width: 50%;
    }
    .package-tabs .tabs-group {
        width: 100%;
    }
    .main-logo a img {
        width: 100px;
    }
    .canceladd-btn .cancel-btn {
    padding: 15px 10px;
    width: 50%;
}
    .days-left {
        font-size: 14px;
    }
    
    .red-data {
        font-size: 14px;
    }
    
    .packagename-td {
        font-size: 14px;
    }
    
    .created-date {
        font-size: 14px;
    }
    
    .date-created {
        font-size: 14px;
    }
    .table-header tr th {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .managepackege-table {
        overflow-x: auto;
    }
    
    .table-header tr th:first-child {
        padding: 22px 30px;
    }
    
    .table-header tr th:last-child {
        padding: 22px 30px;
    }
    
    tbody.table-body {
        overflow-x: auto;
    }
    
    .table-body tr td:first-child {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .table-body tr td {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    
    .table-body tr td:last-child {
        padding: 22px 30px;
    }
    .managepackege-table {
        overflow-x: auto;
    }
    .table-header tr th {
        padding: 22px 30px;
        font-size: 14px;
        white-space: nowrap;
    }
    .add-can-btn {
        flex-direction: column;
    }
    .convert-cancel {
        flex-direction: column;
        align-items: baseline;
    }
    .canceladd-btn .add-btn {
        width: 100%;
        padding: 15px 10px;
    }
    .pay-balance {
        margin: 0 0 5px 0;
        font-size: 13px;
    }
    .editandmember {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .package-buttons a {
        width: 100%;
    }
    .package-buttons {
        flex-direction: column;
        align-items: end;
        /* transform: translatex(-50%); */
        width: 100%;
    }
    .manapackage-header {
        padding-top: 150px;
    }
    .tabs-group .nav-item {
         width: 100% !important; 
    }
    .add-member-header {
        flex-direction: column;
        gap: 15px;
    }
    .addpack-btn {
        width: 100%;
    }
}

@media only screen and (max-width: 325px) {
    .choose-btn {
        width: 100%;
    }
    
    .camera-btn {
        width: 100%;
    }
    
    .chooseandcamera {
        flex-direction: column;
    }
    .addser-btn {
        padding: 15px 10px;
    }
    .page-title .breadcrumb a {
        font-size: 12px;
    }
    
    .breadcrumb-item.active {
        font-size: 12px;
    }
    
    .form-label .sub-text {
        font-size: 12px;
    }
    
    .canceladd-btn {
        flex-direction: column;
    }
    
    .canceladd-btn .cancel-btn {
        width: 100%;
    }
    .other-info-body {
        flex-direction: column;
        align-items: baseline;
    }
    
    .since-member {
        width: 100%;
    }
    .pay-balance {
        font-size: 10px;
        margin: 0 0 0 10px;
    }
    
    .write-off {
        font-size: 10px;
    }
    
    .label-sub-text {
        /* display: flex; */
        /* flex-direction: column; */
    }
    
    .info-heading {
        font-size: 14px;
    }
    
    .table-heading {
        font-size: 18px;
    }
    .check-out-in {
        flex-direction: column;
    }
    
    .check-out-in a {
        width: 100%;
    }
    .input-radio-three {
        flex-wrap: wrap;
    }
    .Convertibility-radio {
        flex-wrap: wrap;
    }
    .about-profile h3 {
        font-size: 16px;
    }
    .footer-tabs .footer-tab-bg {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.nav-fill .nav-item, .nav-fill>.nav-link {
    flex: none;
}

.footer-tabs .footer-tab-bg .nav-link {
    border-radius: 0;
}

.footer-tabs .footer-tab-bg .nav-item:last-child .nav-link {
    border-right: 0;
}
.main-logo a img {
    width: 100px;
}

.pay-balance {
    margin: 5px;
}
.footer-tabs .footer-tab-bg .nav-item {
    padding: 10px 0;
}
}