

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;
}

body {
  padding-right: 0 !important;
  font-family: 'Poppins', sans-serif;
  color: var(--base03);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  background-color: var(--base-white-bg);
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Colors Variables */
:root {
  --base00: #E31B54;
  ;
  --base01: #667085;
  --base02: #000000;
  --base03: #344054;
  --form-placeholder: #98A2B3;
  --base-border: #D0D5DD;
  --form-input: #101828;
  --base-error: #F04438;
  --base-green: #ACDC79;
  --base-note: #E0F2FE;
  --base-note-text: #026AA2;
  --base-white: #ffffff;
  --or-after: #EAECF0;
  --homecomponent-bg: #FCFCFD;
  --icons-bg: #E6F4D7;
  --icons-color: #4F7A21;
  --teritory-text: #C01048;
  --teritory-bg: #FFF1F3;
  --teritroy-bghover: #FFE4E8;
  --teritory-active: #FFCCD6;
  --teritory-focus: #FFF1F3;
  --lightgreen-bg: #FAFDF7;
  --green-border: #CEEAB0;
  --total-greenbg: #86CB3C;
  --btn-pinkhover: #F63D68;
  --states-bg: #F5FBEE;
  --states-head: #669F2A;
  --states-subhead: #3F621A;
  --testi-bg: #F0F9FF;
  --error-border: #FDA29B;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  transition: .1s ease;
  cursor: pointer;
}

.mobile-header{
  display: none;
}

.form-control {
  text-transform: normal;
  width: 100%;
  background: var(--base-white);
  color: var(--form-input);
  border: 1px solid var(--base-border);
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px 12px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  height: auto;
  transition: 0.1s ease;
}

.form-control::placeholder {
  color: var(--form-placeholder);
}

.form-control {
  color: var(--base03);
}

.form-control:focus,
.form-control:focus-visible {
  background: var(--base-white) !important;
  border: 1px solid #363F72 !important;
  box-shadow: 0px 1px 2px rgb(62 71 132 / 5%), 0px 0px 0px 4px #eaecf5 !important;
}

/* new-css-added */
body {
  background-color: #F3F3F3;
}

.home-wrapper {
  display: flex;
  

}

.new-sidebar {
  height: 100vh;
  background-color: #FFFFFF;
  overflow-y: auto;
  width: 20%;
}

.mainprime {
  width: 80%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.primesidebar {
  height: 100%;
}

.adminheader {
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
}

.main-logo {
  min-height: 102px;
  padding: 17px 20px;
  border-bottom: 0.8px solid #EFEFEF;
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
}

.gym-sidebarmenu  {
  padding: 29px 20px;
  display: block;
  border-bottom: 0.8px solid #EFEFEF;
  color: #494A4C;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.member-sub-menu .gym-submenu  {
  padding: 29px 50px;
  display: block;
  color: #494A4C ;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.member-sub-menu .gym-submenu:hover{
  color:#494A4C ;
}
a:hover {
  color: unset;
}
.member-sub-menu .gym-submenu a {
  color:#494A4C ;
}

 .gym-sidebarmenu.active {
  background-color: #ED1B24;
  color: #FFFFFF;
  font-weight: 700;

}
.member-sub-menu .gym-submenu.active {
  background-color: #ED1B24;
  color: #FFFFFF;
  font-weight: 700;

}

.MainHeader {
  background-color: #FFFFFF;
  padding: 35px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 102px;
}

.gym-sidebar {
  height: calc(100dvh - 125px);
  overflow-y: auto;
}

/* hide-scroolbar */
::-webkit-scrollbar {
  display: none;
}
.example::-webkit-scrollbar {
  display: none;
}

.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mainprime-wrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
}

/* .homebar {
    padding: 38px;
} */

.previous-page {
  color: #494A4C;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

/* new-css-addedd */
.show-search {
  position: relative;
}
.method-body  .method-card-head {
  /* color: #FFF; */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
select#filterBy, select#leadType, select#filterBy, select#filterBy, select#filterYear  {
  min-width: 200px;
}
.right-search .member-search {
  flex: 1;
}

.collection-card-wrappers .collection-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* .collection-card-wrappers .collection-data .coll-money {
  
} */
.collection-card-wrappers .collection-data h5 {
  margin: 0;
}

.right-search {
  width: 100%;
  position: relative;
  height: 58px;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  background: var(--Neutral-White, #FFF);
  padding: 6px;
  display: flex;
  align-items: center;
}

.search-first-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}

.right-search input {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: unset;
  background: var(--Neutral-White, #FFF);
  width: 100%;
  height: 100%;
  /* padding-left: 54px !important; */
  box-shadow: unset;
}

body .css-1jqq78o-placeholder{
  margin-left:5px;
}
.right-search input::placeholder{
  color: var(--Grey, #7D7E80);
 font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.right-search input:focus-visible {
  background: var(--base-white) !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

/* .search-left  {
  width: 50px;
  height: 100%;
  float: left;
  background: #f40820;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
} */

/* .modal-dialog {
  max-width: 1018px !important;
} */

.modal-listng {
  padding: 0 34px;
  background-color: #FFEFF0;

}
.notificatons-content .modal-listng:last-child{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.list-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
  padding: 34px 0;
}

.modal-header {
  justify-content: center !important;
  border-bottom: 0 !important;
}
.modal-header{
  padding: 0 1rem 1rem !important;
  
}
.close-header {
  position: relative;
}

.close-button {
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translateY(-50%);
}

.notification-text {
  color: #000000;
  text-align: center;
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.notificatons-content {
  padding: 0;
  margin: 0;
}
.modal-footer {
  padding: 1rem 1rem 0 !important;
}
.modal-footer button {
  font-size: 20px;
  font-weight: 500;
}
.modal-body {
  padding: 0 !important;
  margin-top: 0px;
  font-size: 18px;
  
}

.notification-content {
  margin: 0;
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.notification-day {
  margin: 0;
  color: var(--Primary, #ED1B24);
  text-align: right;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.name-content {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.modal-content {
  border-radius: 8px !important;
  padding: 20px 0 0;
}
.modal-title{
  font-size: 28px;
  font-weight: 400;
}

.sales-target .range-col {
  gap: 0 !important;
}

.custom-range {
  width: 100% !important;
}

.target-title {
  margin: 0;
   font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
}

.value-text {
  margin: 0;
   font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: right;
  color: #ED1B24;

}

.value-points {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sales-target {
  border-radius: 12px;
  background: #FFF;
  padding: 24px ;
}
.sales-target .row {
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.custom-sh {
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.08), 0px 6.1px 24.21px 0px rgba(0, 0, 0, 0.04);
}

/* custom-range */

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 12px;
  background: #E9E9E9;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 12px;
  background: #E9E9E9;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  height: 12px;
  width: 15px;
  background-color: #ED1B24;
  border-radius: 50%;
  border: 2px solid #ED1B24;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #ED1B24;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 12px;
  width: 15px;
  background-color: #ED1B24;
  border-radius: 50%;
  border: 1px solid #ED1B24;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #ED1B24;
}


/*=============
Aesthetics 
=========================*/

.range {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  background: #fff;
  padding: 0px 0px;
}

/* custom-range-end */
.mega-chart {
  border-radius: 12px;
  background: #FFF;
  padding: 34px;
  margin-top: 34px;
}

.your-collection {
  border-radius: 12px;
  border: 1px solid #F2F2F2;
  background: var(--White-White-100, #FFF);
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.08), 0px 6.1px 24.21px 0px rgba(0, 0, 0, 0.04);
  padding: 24px;
  margin-top: 34px;
  margin-bottom: 34px;

}

.yourcol-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.col-heading h4 {
  color: #000000;
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.leds-ct span {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-heading h4 span {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 4px;
}

.custom-drop .custom-button {
  border-radius: 8px;
  border: 1px solid #858688;
  background: var(--White-White-100, #FFF);
  color: #858688;
   font-family: Outfit;
  font-size: 16.841px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.844px;
  /* 123.767% */
  padding: 14px 19px;
}

.custom-drop .custom-button:active {
  border: 1px solid #858688 !important;
}

.custom-drop .custom-button .show {
  border: 1px solid #858688 !important;
}

.custom-drop .custom-button span {
  /* padding-right: 45px; */
  color: #858688;
 font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20.844px; /* 123.767% */
}

.collection-data {
  border-radius: 8px;
  border: 1px solid #EEE;
  background: var(--White-White-100, #FFF);
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
  padding: 18px;
  position: relative;
  height: 100%;
}

.collection-data h5 {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.collection-data p {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 18px;
}

.coll-icon {
  position: absolute;
  top: 24px;
  right: 18px;
}

.coll-money {
  border-radius: 4px;
  background: var(--Primary, #ED1B24);
  padding: 8px 10px;
  color: #FFF;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-design .container {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.payment-text {
  color: #000000;
   font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.payment-heading {
  margin-top: 34px;
  margin-bottom: 24px;
}

.method-body {
  border-radius: 8px;
  background: #F3F3F3;
  padding: 20px;
}

.g-text {
  margin-bottom: 12px;
}

.g-text span {
  color: #000000;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.amount-text {
  color: #000;
   font-family: Outfit;
  font-size: 28.946px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.leads-body {
  border-radius: 8px;
  border: 1px solid #EEE;
  background: var(--White-White-100, #FFF);
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
  padding: 20px 24px !important ;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-leads {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.leds-ct {
  margin: 0;
  color: var(--Black, #121212);
  /* text-align: right; */
   font-family: Outfit;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pending-data {
  border-radius: 11.842px;
  background: #FFF0F0;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pending-text {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.number-count {
  color: var(--Primary, #ED1B24);
  text-align: right;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.Sources-sec {
  margin-top: 34px;
  margin-bottom: 24px;
}

.source-text {
  color: #000000;
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.source-body {
  border-radius: 8px;
  background: #F3F3F3;
  padding: 15px;
}

.source-body-content {
  margin: 0;
  display: flex;
    align-items: center;
    gap: 13px;
}

.walkins-img img {
  max-width: 48px;
  height: auto;
}

.source-body-content span {
  color: #000000;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  
}

.revenue-count {
  padding: 32px 18px;
  border-radius: 8px;
  height: 100%;
}

.joined-mem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.managepackage-main  .member-image {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.freez-heading {
  font-size: 18px;
  font-weight: 600;
}

.mem-count {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 0; */
}

.active-content {
  color:  #121212;
   font-family: Outfit;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
  margin-top: 18px !important;
}

.pack-mem {
  padding: 24px;
  border-radius: 12px;
}

.expected-text {
  margin: 0;
  color: #000000;
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.expected-text .for-month {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 8px;
}

.mem-revenue {
  padding: 24px;
  margin: 34px 0 0;
  border-radius: 12px;
}

.daily-earning {
  border-radius: 10px;
  background: var(--Primary, #ED1B24);
  padding: 24px;
  position: relative;
  cursor: pointer;
}

.earning-body {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 18px;
}

.earning-body li svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.earning-data p {
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 5px;
}

.earning-data h2 {
  color: #FFFFFF;
  font-family: Outfit;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.continue-earning {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.continue-earning p {
  margin: 0;
  line-height: normal;
}

.continue-earning p span {
  color: var(--white, #FFFFFF);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 6px;
}

.accordion-button a {
  color: #494A4C;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.gym-sidebarmenu-dropdown .accordion-button {
  padding: 29px 20px 29px 20px !important;
  border-radius: 0 !important;
  gap: 0px;
  border-color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.gym-sidebarmenu-dropdown .accordion-item {
  border-radius: 0;
  border-bottom: 0.8px solid #EFEFEF;
  border-top: 0;
  border-right: 0;
}

.gym-sidebarmenu-dropdown .accordion-body {
  padding: 0;
}

.member-sub-menu .gym-submenu {
  border-bottom: 0.8px solid #EFEFEF;
}



.gym-sidebarmenu-dropdown .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}



/* range 3 */
.range-slider {
  flex: 1;
}

.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.sliderticks span {
  display: flex;
  justify-content: center;
  width: 1px;
  height: 10px;
  background: #d3d3d3;
  line-height: 40px;
  white-space: nowrap;
}

/*=============
Aesthetics 
=========================*/

.range {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.admin-profile {
  display: flex;
  align-items: center;
  gap: 25px;
}

.admin-button {
  background: transparent;
  border: 0;
  position: relative;
}

.admin-button .admin-text {
  color: #000000;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.admin-profile-log .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-image: url("/images/admindown-erow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 14px;
  display: unset;
  margin-left: unset;
  vertical-align: unset;
  content: "";
  border-top: unset;
  border-right: unset;
  border-bottom: 0;
  border-left: unset;
}

.admin-button {
  /* background: transparent; */
  /* border: 0; */
}

.admin-button .admin-text {
  color: #000000;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-right: 25px;
}

.admin-button:hover {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: unset !important;
}

.notification-ring {
  position: relative;
  cursor: pointer;
}

.noti-count {
  position: absolute;
  top: -3px;
  left: 0px;
  background-color: #ED1B24;
  width: 18px;
  border-radius: 50%;
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noti-count span {
  color: #FFFFFF;
   font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
}
.edit-delete svg circle {
  fill: #000;
}

.pagination-student > .active > a, .pagination-student > .active > span, .pagination-student > .active > a:hover, .pagination-student > .active > span:hover, .pagination-student > .active > a:focus, .pagination-student > .active > span:focus {
  color:#fff !important;
}

.admin-menu {
  border-bottom: 1px solid var(--Primary, #ED1B24) !important;
  background: #FFF;
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0;
  border: 0;
  /* transform: translate(0px, 81px) !important; */
  padding: 0 15px !important;
}

.admin-menu .admin-list {
  padding: 12px 0;
  border-bottom: 1px solid #DDDDDD;
}

.admin-menu .admin-list:nth-child(2) {
  border-bottom: 0;
}

.admin-list span {
  padding-left: 9px;
}

.admin-menu .admin-list:hover {
  background-color: unset !important;
}

/* manage-package */

.managepackage-main {
  border-radius: 12px;
  background: #FFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

/* table-css */

.table-header {
  background: #121212;
  /* padding: 22px 34px !important; */
}

.table-header tr {
  border: 0;
  border-style: none !important;
}

.table-header tr th {
  padding: 22px ;
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}

.table-header tr th:first-child {
  padding: 22px 22px 22px 22px;
}

.table-header tr th:last-child {
  padding: 22px 22px 22px 22px;
}

.table-body tr td:last-child {
  padding: 22px 22px 22px 22px;
  
}

.table-body tr td {
  border: 0;
  padding: 22px;
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}

.table-body tr td:first-child {
  padding: 22px 22px 22px 22px;
}

.table-body tr td:first-child img {
  /* width: 100%; */
}



.table-body tr:nth-child(even) {
  background: #F0F0F0;
}

.managepackege-table {
  margin-top: 24px;
  /* overflow-x: auto; */
}

.package-service {
  display: block;

}

.manapackage-header {
  position: relative;
}


.tabs-group {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  padding: 4px;
  width: fit-content;
}

.tabs-group .nav-item .nav-link {
  color: var(--Black, #121212);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 13px 10px;
  width: 100%;
}

.tabs-group .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  color: #FFFFFF;
}

.tabs-group .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.tabs-group .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active .nav-link {
  color: #FFFFFF;
}

.addpack-btn {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 24px;
}

.addser-btn {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  border: 0;
  padding: 15px 24px;
}

.addpack-btn span {
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 8px;
}

.addser-btn span {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.package-buttons {
  display: flex;
  align-items: center;
  gap: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.search-package {
  max-width: 500px;
  position: relative;
  width: 100%;

}

.search-package input {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  background: var(--Neutral-White, #FFF);
  padding: 16px 18px 16px 54px;
}

.search-package input:focus-visible {
  outline: 0;
}

.search-package input::placeholder {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}

/* add-packages-form */

.add-package {
  border-radius: 12px;
  background: #FFFFFF;
  padding: 32px 20px;
}

.add-package-form {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}



.form-label {
  color: #121212;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 12px;
}

.form-label .star-tp {
  color: var(--Primary, #ED1B24);
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.form-label .sub-text {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

/* .form-control {
  padding: 15px 18px !important;
} */

.form-select {
  padding: 15px 18px !important;
  color: #98a2b3;

}

.dollar-in {
  position: relative;
}

.dollar-body {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  color: var(--Grey, #7D7E80);
  text-align: right;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.form-select:focus-visible {
  border: 1px solid #D1D1D1;
  box-shadow: unset;
}
.yesorno .form-check {
  display: flex;
  align-items: center;
  padding-left: 1.5em;
}
.yesorno .form-check label {
  margin-left: 8px;
  margin-bottom: 0;
  white-space: nowrap;
}
.physical-visit  .form-check {
  flex: 0 0 50% !important;
}

.form-check .form-check-input {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-top: 0;
  border: 1px solid #7D7E80;
}

.form-check .form-check-label {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 12px;
}

.form-check-input:checked {
  background-color: #ED1B24;
  border-color: #ED1B24;
}

.form-control {
  padding: 15px 18px !important;
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  background: var(--Neutral-White, #FFF);
}

.form-control:focus,
.form-control:focus-visible {
  box-shadow: unset !important;
  outline: 0 !important;
  border: 1px solid #D1D1D1 !important;
}

.canceladd-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 18px;
  padding: 34px 211px;
}

.canceladd-btn .cancel-btn {
  border-radius: 8px;
  background: #F0F0F0;
  border: 0;
  padding: 15px 65px;
  width: 50%;
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.canceladd-btn .add-btn {
  width: 50%;
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 65px;
  color: var(--white, #FFF);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-member-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalmem-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 12px;
}

.mem-textcontent {
  color: var(--Black, #121212);
  text-align: right;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.malefemale-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
}

.male-content {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.male-count {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.five-selects {
  border-radius: 12px;
  background: var(--Black, #121212);
  padding: 24px;
}

.selects-body .form-label {
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.selects-body .form-select {
  border-radius: 8px;
  border: 1px solid #515151;
  background: #1D1D1D;
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 18px;
  background-image: url("/images/blackdown-erow.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right .75rem center !important;
}

.member-name {
  color: #121212;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 12px !important;
  text-align: center;
  max-width: 150px;
  white-space: break-spaces;
}
.edit-delete .admin-text {
  padding-right:0;
  margin-left: auto;
}
.edit-delete .dropdown{
  
  text-align: center;
}

.packagename-td {
  color: #121212;
   font-family: Outfit;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.green-money {
  color: #0E8421;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.red-money {
  color: var(--Primary, #ED1B24) !important;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.page-title .breadcrumb a {
  color: #000000 !important;
}

.breadcrumb {
  background: unset !important;
}
body span {
  white-space: normal;
}

.breadcrumb .breadcrumb-item a{
  display: flex;
  align-items: center;
}

.go-back-btn {
  color: #121212;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 14px;
}


.profile-update {
  text-align: center;
}

.profile-img {
  position: relative;
  width: 255px;
  height: 255px;
  margin: 0 auto;
}

.update-camera {
  position: absolute;
  bottom: -28px;
  right: 60px;
  /* transform: translateX(-50%); */
  gap: 18px;
}

.label-for-upload .form-label {
  margin: 0;
  line-height: 0;
}

.about-proile {
  margin-top: 30px;
}

.about-proile h5 {
  color: #7D7E80;
  text-align: center;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.about-proile h4 {
  color: #000000;
  text-align: center;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 18px;
  margin-top: 4px;
}

.about-proile p {
  color: #7D7E80;
  text-align: center;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-proile p span {
  color: #121212;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.declare-text {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 26px;
}

.input-radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 0 0 13px;
}

.input-radio .form-check .form-check-input {
  border-radius: 50%;
  margin-right: 8px;
}

.input-radio .form-check label {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.proof-text {
  color: #121212;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 12px !important;
}

.chooseandcamera {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.file-input-picker-cont.add-new-student {
  border: none;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
}
.file-input-picker-cont.add-new-student .file-input-name {
  display: none;
}
.file-input-picker-cont.add-new-student .btnUpload span:last-child {
  display: block;
  line-height: normal;
}
.file-input-picker-cont.add-new-student i{
  display: none;
}
.file-input-picker-cont.add-new-student .btnUpload.upload-file {
  background: #ED1B24;
  color: #fff;
}
.file-input-picker-cont.add-new-student .btnUpload {
  border-radius: 8px;
  flex:0 0 48%;
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px;
  background:#F0F0F0 ;
  color:#121212;
  border:none;
  height: unset;
}

.input-radio-three {
  flex-wrap: wrap;
}
.input-radio-three .form-check {
  flex:0 0 25%;
}

.input-radio-three .form-check{
  margin-bottom:15px;
}

.add-personal-details .row {
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.choose-btn {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  padding: 14px 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-btn label {
  color: var(--white, #FFF);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.camera-btn {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  padding: 14px 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-btn p {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.details-heading {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 24px;
}

.label-for-upload {
  cursor: pointer;
}

.open-camera {
  cursor: pointer;
}

.input-radio-three {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 13px;
}

.input-radio-three .form-check .form-check-input {
  border-radius: 50%;
  margin-right: 8px;
}

.manage-member-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}



.membership-form {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  border: 0;
  padding: 15px 0px;
  width: 100%;
}

.membership-form span {
  color: var(--Black, #121212);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.pagination-student {
  gap:8px;
}

.editandmember a {
  width: 50%;
}


.managepackage-main  .row {
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}
.edit-profile {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 24px;
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
}

.edit-profile span {
  padding-left: 8px;
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.editandmember {
  width: 40%;
  gap: 13px;
  display: flex;
  align-items: center;
}

.shadow-padd {
  border-radius: 8px;
  border: 1px solid #EEE;
  background: var(--White-White-100, #FFF);
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
  padding: 28px 34px;
  margin-bottom: 18px;
}

.shadow-padd {
  border-radius: 8px;
  border: 1px solid #EEE;
  background: var(--White-White-100, #FFF);
  box-shadow: 0px 0px 1.684px 0px rgba(0, 0, 0, 0.04), 0px 8.1px 34.21px 2px rgba(0, 0, 0, 0.03);
  padding: 28px 34px;
  margin-bottom: 18px;
}

.member-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.member-code h4 {
  color: var(--Grey, #7D7E80);
  text-align: center;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
  margin-bottom: 24px;
}

.member-code h4 span {
  color: #000000;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.change-photo {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 29px;
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;
}

.about-profile h3 {
  color: #000000;
  text-align: center;
   font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.about-profile {
  text-align: center;
}

.about-profile h5 {
  color: var(--Grey, #7D7E80);
  text-align: center;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.about-profile p {
  color: var(--Grey, #7D7E80);
  text-align: center;
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.about-profile p span {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.serviceadd-btn {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 10px;
  width: 100%;
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-on-services {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.checkin-btn {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  border: 0;
  padding: 15px 10px;
  width: 100%;
}

.checkin-btn span {
  color: var(--Green, #0E8421);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.checkout-btn {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  border: 0;
  padding: 15px 10px;
  width: 100%;
  /* color: var(--Primary, #ED1B24); */
  /* text-align: center; */
   font-family: Outfit;
  /* font-size: 16px; */
  /* font-style: normal; */
  /* font-weight: 400; */
  /* line-height: normal; */
}

.checkout-btn span {
  color: var(--Primary, #ED1B24);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.check-out-in {
  display: flex;
  gap: 18px;
}

.check-out-in a {
  width: 50%;
}

.blocklist-btn:focus {
  border: 1px solid var(--Primary, #ED1B24);

}

.blocklist-btn {
  border-radius: 8px;
  border: 1px solid var(--Primary, #ED1B24);
  background: transparent;
  padding: 15px 10px;
  width: 100%;
  color: var(--Primary, #ED1B24);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.frezze-box {
  text-align: center;
}

.frezze-box p {
  color: var(--Black, #121212);
  text-align: center;
   font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.label-text {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.label-sub-text {
  color: #000000;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.since-member {
  width: 50%;
}

.other-info-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-layout {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.info-heading {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.table-heading {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.footer-tabs {
  margin-top: 44px;
}


.footer-tabs .footer-tab-bg {
  border-radius: 12px 12px 0px 0px;
  background: #F6F6F6;
  /* padding: 18px 0; */
}

.footer-tabs .footer-tab-bg .nav-item {
  padding: 18px 0;
}

.footer-tabs .footer-tab-bg .nav-item .active {
  border-radius: 0;
  background: unset;
  color: #FFFFFF;
  border: 0;
}

.footer-tabs .footer-tab-bg .nav-link {
  border-radius: 0;
  border-right: 1px solid #DEDEDE;
  color: var(--Grey, #7D7E80);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-tabs .footer-tab-bg .nav-link:focus{
  border-color: unset;
  border: 0;
}
.footer-tabs .footer-tab-bg .nav-link:focus-visible{

}
.footer-tabs .nav-item:has(.active) {
  background-color: #ED1B24;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.footer-tabs .footer-tab-bg .nav-item:last-child .nav-link{
  border-right: 0;
}
.enquery-form {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

.input-radio-gender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
}

.input-radio-gender label {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 4px;
}

.Convertibility-radio .form-check .form-check-input {
  border-radius: 50%;
}

.Convertibility-radio .form-check label {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 4px;
}

.Convertibility-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.input-radio-gender .form-check .form-check-input {
  border-radius: 50%;
}

.update-btn .add-btn {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
  padding: 15px 63px;
  color: var(--white, #FFF);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.alert-red {
  border-radius: 8px;
  background: var(--Primary, #ED1B24);
  border: 0;
}

.convert-btn {
  padding: 15px 0;
}
.convert-btn span {
  color: var(--white, #FFF);
   font-family: Outfit;
  /* font-size: 16px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding-left: 8px; */
}

.grey-bt {
  border-radius: 8px;
  background: var(--Button, #F0F0F0);
  border: 0;
}

.cancel-lead {
  padding: 15px 0;
}

.cancel-lead span {
  color: var(--Black, #121212);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.convert-cancel {
  padding-left: 36px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: end;
}

.follow-up-history {
  /* border-left: 1px solid #E6E6E6; */
  margin: 34px 0;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.add-service {
  padding: 15px 48px;
  color: var(--white, #FFF);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
.four-box button{
  padding: 0;
}

.four-box .row > div  {
  margin: 0;
}
.four-box .row {
  margin-top: 0;
  grid-row-gap: 30px;
  row-gap: 18px !important;
}

.can-btn {
  padding: 15px 66px;
  color: var(--Black, #121212);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-can-btn {
  display: flex;
  gap: 12px;
  margin-top: 18px;
}

.freeze {
  padding: 15px 66px;
  color: var(--white, #FFF);
  text-align: center;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.freezing-history {
  border-top: 1px solid #E6E6E6;
  padding: 34px 0;
  margin-top: 34px;
}
.custom-date-range-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 15px 0; */
  border-radius: 8px;
  border: 1px solid #D1D1D1;
  background: var(--Neutral-White, #FFF);
  padding: 12px;
  width: fit-content;
}

.package-detail-table {
  margin-top: 34px;
}

.twoselect-option {
  display: flex;
  border-radius: 8px;
  border: 1px solid #515151;
  background: #1D1D1D;
  padding: 12px 0;
}

.twoselect-option .form-select {
  border: 0;
  padding: 0 18px !important;
}

.twoselect-option .form-select:first-child{
  border-right: 1px solid #515151;
  border-radius: 0;
}

.pending-text span {
  color: #797A7C;
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.days-left {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}


.red-data {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.created-date {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.date-created {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.change-date {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.change-icon {
  padding-left: 12px;
}

.change-date {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.change-icon {
  padding-left: 12px;
  padding-right: 5px;
}

.pay-balance {
  padding: 8px 19px;
  color: var(--white, #FFF);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0 12px;
}

.write-off {
  padding: 8px 18px;
}


span.cash-text {
  color: var(--Black, #121212);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 0 6px;
}

.sales-person {
  color: var(--Grey, #7D7E80);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.notification-header {
  display: unset;
  position: relative;
  padding: 0;
}

.notification-header .btn-close {
  position: absolute;
  top: 22px;
  right: 34px;
  padding: 0;
}

.edit-delete .dropdown-toggle::after{
  display: none;
}
.cust-date-wrapper .custom-date {
  flex: 0 0 50%;
  padding: 8px 10px !important;
}

.cust-date-wrapper  {
  display: flex;
  align-items: center;
}

.edit-delete .admin-button {
  padding: 0;
}

.edit-delete  .admin-menu {
  border-bottom: 0 !important;
  background: #FFF;
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0;
  border: 0;
  transform: translate(0px, 35px) !important;
  padding: 0 24px;
  right: 4px !important;
  top: 10px !important;
  left: unset !important;
}

.update-left {
  padding: 51px 0 18px;
}

 .disabled-sec a , .disabled-sec button{
  opacity: 0.6;
  pointer-events: none;
}


.twoselect-option .form-select {
  margin-bottom: 0;
}

.expected-head {
  margin-bottom: 32px;
}

.page-title {
  display: flex;
  align-items: center;
}

.page-title h4 {
  color: #121212;
   font-family: Outfit;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.Totalpackages p {
  color: #121212;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.Totalpackages p span {
  color: var(--Primary, #ED1B24);
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.Totalpackages {
  margin-left: 24px;
  display: flex;
  gap: 24px;
}

.update-right {
  align-self: unset;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.package-tabs .tabs-group {
  width: 356px;
}
.package-tabs .tabs-group .nav-item {
  width: 50%;
}

.pending-text .new-text {
  color: #797A7C;
   font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.content-wrapper{
  position: unset;
  width: 100%;
}


/* new-css-added-27-6-24 */
.twoselect-option input {
  width: 50%;
  background: transparent;
  border: 0;
  color: #7D7E80 !important;
  border-right: 1px solid;
  border-radius: 0;
  padding: 0 18px !important;
}

.twoselect-option input:nth-child(2){
  border-right: 0;
}
.twoselect-option input:focus{
  background-color: unset !important;
    border: 0 !important;
}

.edit-delete button {
  width: 100%;
}

 .table-body tr td:last-child  {
  padding: 0 !important;
}
.edit-delete .admin-button{
  padding: 14px 10px !important;
}

.controlled  {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 999 !important;
}

.managepackage-main .canceladd-btn .add-btn {
  width: max-content;
}

.convertibility .form-check {
  flex: 0 0 14%;
}

.Enquiryfor {
  margin-bottom: 18px;
}

.convert-cancel button {
  width: 50%;
}

.convert-cancel button {
  width: 50%;
}

.custom-badge {
  font-size: 16px;
}

.history-list {
  padding: 24px  0;
  border-bottom: 1px solid #E0E0E0;
}
.custom-control {
    min-height: 56px;
}

.yousure .modal-header {
  border-bottom: 1px solid #dee2e6 !important;
}

.yousure .modal-body {
  padding: 18px !important;
}

.yousure .modal-content {
  padding: 20px 0;
}
.or-div {
  text-align: center;
  position: relative;
  margin: 22px 0 !important;
}

.or-div::before {
  position: absolute;
  content: "";
  background-color: hsl(0, 0%, 80%);
  width: 20%;
  height: 1px;
  left: 48%;
  top: 50%;
  margin-left: auto;
  transform: translate(-100%, -50%);
}

.or-div.form-label {
  text-transform:unset;
  color: hsl(0, 0%, 80%);
}

.or-div::after {
  position: absolute;
  content: "";
  background-color: hsl(0, 0%, 80%);
  width: 20%;
  height: 1px;
  right: 38%;
  top: 50%;
  margin-right: auto;
  transform: translate(50%, -100%);
}

.comments-wrapper {
  border-left: 1px solid #E6E6E6;
  padding-left: 34px;
  height: 100%;
  flex: 1;
  overflow: auto;
}

.comments-wrapper .comment {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;

}
.comments-wrapper .comment-details {
  font-size: 14px;
  font-weight: 500;
  color: #868686;
  /* margin-bottom: 8px; */
}
.comment-details {

}

.confirm-payment .confirm-modal {
  margin: 0;
}

.confirm-payment .modal-body {
  padding: 20px;
}

.confirm-payment  form {
  padding: 20px;
}

.confirm-payment .video-form-inner {
  padding: 20px ;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  background: #f8f8f8;
    
}
.confirm-payment .add-can-btn {
  justify-content: center;
}
.disable {
  background-color: #f8f8f8;
  pointer-events: none;
}
.main-date-box .time-box{
  font-size: 18px;
  font-weight: 400;
  color: #7D7E80;
  text-align: end;
}
  .main-date-box .react-calendar__tile {
    border: 1px solid #eee !important;
    padding: 27px 10px;
    /* flex: 0 0 10% !important; */

}
.main-date-box .react-calendar__tile > abbr {
  font-size: 20px;
  font-weight: 500;
  color: #121212;
  text-align: end;
  padding-block-end: 14px;
  width: 100%;
  display: inline-block;
}
.calender-absent{
  background-color: #ED1B24 !important;
}
.main-date-box .calender-absent abbr{
  color: #fff !important;

}
.main-date-box .calender-absent .time-box{
  color: #fff !important;

}
.main-date-box .calender-leave{
  background-color: #121212 !important;

}
.main-date-box .calender-leave abbr{
  color: #fff !important;

}
.main-date-box .calender-leave .time-box{
  color: #fff !important;

}

.color-sheet{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-block-start: 24px;
}
.color-sheet .absent span {
  border-radius: 100%;
  background-color: #ED1B24;
  display: inline-block;
  width: 14px;
  height: 14px;
}
.color-sheet .leave span{
  border-radius: 100%;
  background-color: #121212;
  display: inline-block;
  width: 14px;
  height: 14px;
}
.color-sheet div{
display: flex;
gap: 10px;
align-items: center;
}
.color-sheet div p{
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #121212;
}
.apexcharts-canvas {
  width: 100% !important;
}