.sidebar::-webkit-scrollbar {
  width: 4px !important;
}
// right sidebar scroll css
body {
  background: #f8f8f8;
}
.sidebar-right::-webkit-scrollbar {
  background: #fff;
}

.sidebar-right::-webkit-scrollbar-button {
  background: #fff;
}

.sidebar-right::-webkit-scrollbar-track {
  background: #fff;
}

.sidebar-right::-webkit-scrollbar-track-piece {
  background: #fff;
}

.sidebar-right::-webkit-scrollbar-thumb {
  background: #fff;
}

.sidebar-right::-webkit-scrollbar-corner {
  background: #fff;
}

.sidebar-right::-webkit-resizer {
  background: #fff;
}
//left sidebar scroll css

.sidebar::-webkit-scrollbar {
  background: #fff;
}

.sidebar::-webkit-scrollbar-button {
  background: #fff;
}

.sidebar::-webkit-scrollbar-track {
  background: #fff;
}

.sidebar::-webkit-scrollbar-track-piece {
  background: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #f4f4f4;
}

.sidebar::-webkit-scrollbar-corner {
  background: #fff;
}

.sidebar::-webkit-resizer {
  background: #fff;
}
// responsive css
@media (min-width: 1440px) {
  .container {
    max-width: 1350px;
  }

  .container-xl .container {
    max-width: 1440px;
    padding: 0;
  }
}
@media (min-width: 1340px) {
  .video-div img {
    height: 160px;
  }
}
@media (max-width: 1300px) {
  .thumb-box img {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .side-menu-icon {
    padding-right: 10px;
  }
  .video-big-wrapper iframe {
    height: 100% !important;
  }

  .download-btn {
    width: 180px !important;
  }

  .content-wrapper.w-95.full-width {
    padding-right: 0px !important;
  }

  .banner-wrapper {
    height: auto !important;
  }
  .ev-casestudy-page .case-div {
    width: 100% !important;
  }

  .white-box {
    height: 100% !important;
  }

  .thumb-box img {
    height: 60px !important;
    width: 100% !important;
  }

  .sidebar.fliph {
    width: 14% !important;
    transition: all 0.2s ease-in-out;
  }

  .close-sidebar-right {
    display: none !important;
  }

  .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 28px 10px 0 !important;
    width: auto !important;
  }

  .sidebar {
    width: 100% !important;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    height: 100%;
    float: left;
    position: fixed;
    left: 0;
    // top: 60px !important;
    z-index: 999;
    overflow-x: scroll;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
  }

  .sidebar.fliph {
    width: 100%;
    transition: all 0.2s ease-in-out;
  }

  .sidebar {
    width: 50px;
  }

  .sidebar .nav-label {
    display: none;
  }

  .sidebar.fliph .nav-label {
    display: inline-block !important;
  }

  .sidebar-right.right {
    display: none;
  }

  .sidebar-right-view {
    display: block !important;
    width: 100% !important;
    z-index: 9999;
    overflow-y: scroll;
  }

  .logo-timer {
    text-align: center;
    padding-top: 35px !important;
  }

  .logout {
    padding-top: 15px !important;
    padding-right: 5px !important;
  }

  .logout .col-10 {
    padding-right: 0;
  }

  .logo-timer {
    text-align: center;
    padding-top: 7px;
  }

  .header-top {
    height: 61px;
    display: flex;
  }

  .careousal-btn ul {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-wrapper {
    width: calc(100% - 0px) !important;
    left: 0 !important;
    padding: 0 0 !important;
    top: 90px;
  }

  .content .bottom {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .clipart-img img {
    max-width: 100%;
    position: relative;
    right: -25%;
    height: 250px;
    top: 0;
    object-fit: contain;
  }

  .profile-view a {
    text-align: right;
    float: right;
    margin-right: 20px;
    padding-top: 10px;
    color: #f40820;
    font-size: 14px;
    display: block;
    border-bottom: 0;
  }

  .white-box {
    padding: 15px 0 !important;
  }

  .upcoming-class h6 {
    font-size: 15px !important;
    line-height: 15px !important;
  }

  .ppb-wrapper h3 {
    font-size: 20px;
    line-height: 25px;
  }

  .data-box {
    margin-bottom: 15px;
  }

  .icon-box {
    height: 40px;
    width: 40px;
    padding: 10px;
  }

  .upcoming-class h6 {
    font-size: 15px;
    font-family: Proxima-Nova-Bold;
    font-weight: 500;
    color: black;
    letter-spacing: -0.25px;
    margin-bottom: 0;
    margin-top: 6px;
    line-height: 10px;
  }

  .white-bg-box {
    background: #fff;
    margin-top: 15px;
  }

  .thumb-box img {
    background: #f40820;
    border-radius: 8px;
    height: 60px;
    width: 70px;
    object-fit: cover;
  }

  .logo img {
    display: none;
  }

  .menu-icon {
    text-align: left;
    cursor: pointer;
    display: block;
    margin-left: 20px;
    /* position: absolute; */
  }

  .menu-icon img {
    filter: brightness(0) invert(1);
    margin-left: -5px;
  }

  .sidebar ul {
    padding-top: 17px;
  }

  .fliph .menu-icon {
    text-align: left;
  }

  .sidebar.left.fliph {
    background-color: #fff;
    // width: 65px !important;
    width: 0 !important;
  }

  .logo-m {
    display: block !important;
  }

  .logo-d {
    display: none;
  }

  .logo-timer {
    text-align: center;
    padding-top: 0 !important;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #053367;
  }

  .user-mbl {
    width: 25px;
  }

  .careousal-btn ul li {
    display: inline-block;
    padding: 7px 2px;
    width: 100%;
  }

  .btn-careousal {
    background: #f40820;
    border-radius: 8px;
    color: #fff;
    padding: 8px 15px;
    font-size: 16px;
  }

  .video-header {
    display: block;
  }

  .video-list-inner-wrapper {
    padding: 15px;
  }

  .inner-head {
    font-size: 17px;
  }

  .video-single-inner-header {
    display: block;
  }

  .w-80 {
    padding-top: 20px;
  }

  .profile-icon img {
    float: left;
    margin-right: 0;
    height: 25px;
    display: block !important;
    filter: brightness(0) invert(1);
    cursor: pointer;
  }
  ul.scroll_horizontal li {
    width: 100% !important;
    margin-left: 30px;
  }

  .w-80 {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .careousal-btn ul li {
    display: inline-block;
    padding: 7px 1px !important;
  }
}
// styles css
@font-face {
  font-family: Proxima-Nova-Black;
  src: url("./fonts/Proxima-Nova-Black.otf");
  // src: url(../fonts/Proxima-Nova-Black.otf);
  font-weight: 700;
}
@font-face {
  font-family: Proxima-Nova-Extra-Bold;
  src: url("./fonts/Proxima-Nova-Extrabold.otf");
  font-weight: 600;
}
@font-face {
  font-family: Proxima-Nova-Bold;
  src: url("./fonts/Proxima-Nova-Bold.otf");
  font-weight: 500;
}
@font-face {
  font-family: Proxima-Nova-Regular;
  src: url("./fonts/Proxima-Nova-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: Proxima-Nova-Thin;
  src: url("./fonts/Proxima-Nova-Thin.otf");
  font-weight: 300;
}

body,
html {
  scroll-behavior: smooth;
}

body {
  font-family: Proxima-Nova-Regular, sans-serif;
  overflow-x: hidden;
}

:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.light-blue {
  color: #f40820 !important;
}

button:focus {
  outline: none;
  border: none;
}

a:hover {
  text-decoration: none;
}

b {
  font-family: Proxima-Nova-Bold;
}
/*======== Header =======*/
header {
  /* height: 100px; */
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-top: 22px;
}

header .common-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-timer {
  text-align: center;
  padding-top: 40px;
}

.logo img {
  height: 48px;
}

.dropdown-toggle::after {
  display: none;
}

header .dropdown-item {
  color: black;
  font-weight: 500;
  position: relative;
  padding: 10px 15px;
  font-size: 14px;
}

header .dropdown-item:hover:before {
  background: #00b8ef;
}

header .dropdown-item:focus,
header .dropdown-item:hover {
  color: #00b8ef;
  text-decoration: none;
  background-color: #ffffff;
}

.head-cc-right {
  float: right;
  width: 303px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: transparent;
  border-color: transparent;
}

.head-user {
  float: right;
  margin-left: 25px;
}

.head-user-dd {
  float: left;
}

.head-user-dd button {
  background: white;
  border: 0;
  color: black;
  font-weight: bold;
  font-size: 13px;
  margin-top: 10px;
}

.head-user-dd button:hover {
  color: #01b9f0;
  background-color: #ffffff;
}

.head-user-dd .show > .btn-secondary.dropdown-toggle:focus {
  color: #49545a !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 0;
  outline: 0;
}

.btn-secondary.dropdown-toggle {
  box-shadow: 0 0 0;
  outline: 0;
}

.head-user-dd img {
  width: 12px;
  margin-left: 10px;
}

.head-cc-left {
  float: left;
}

.head-cc-right {
  float: right;
}

.side-menu-icon {
  padding-right: 30px;
}
/*======== Header End =======*/
/***********************  Side Bar ********************/
.dashborad-section {
  width: 100%;
  float: left;
  background: #f8f8f8;
}

.sidebar {
  width: 18%;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  height: 100%;
  float: left;
  position: fixed;
  // top: 65px;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.bg-defoult {
  background-color: #fff;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 28px 0 0 28px;
}

.side-menu-icon img {
  filter: brightness(0) invert(1);
  cursor: pointer;
  height: 15px;
  width: 20px;
}

.sidebar li {
  padding: 0px 0;
}
.sidebar li:first-child {
  border-bottom: unset;
}

.sidebar li a,
.sidebar li a.collapsed.active {
  display: block;
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
  // font-family: Outfit;
  color: #f40820;
  letter-spacing: -0.23px;
  padding: 20px 15px;
}
.sidebar li a:hover,
.sidebar li a.collapsed.active:hover {
    background: #f40820;
    color: #ffffff;
}
.sidebar li a:hover{
  color: #FFFFFF;
}
.sidebar li a:hover::before, .sidebar li a[aria-expanded="true"]::before  {
  color: #fff !important;
}

.sidebar li a[aria-expanded="true"], .sidebar li a[aria-expanded="true"] span.nav-label {
  background: #f40820;
  color: #fff !important;
}
.sidebar li a:hover span, .sidebar li a.collapsed.active:hover span {
    color: #ffffff;
}
.sub-menu {
  padding-top: 15px !important;
  padding-left: 0 !important;
}
.sub-menu.course-sub-menu {
  // background: #eeeeee;
  padding-left: 0px !important;
  margin-top: 0px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.sub-menu.course-sub-menu li{
  padding: 0 !important;
}
.sub-menu li {
  padding: 0;
}

.sidebar li a.active {
  border-right: 4px solid #00b8ef;
  transition: all 0.5s ease-in-out;
}

.sidebar li a i {
  padding-right: 5px;
}

.sidebar ul li .sub-menu li a {
  position: relative;
  padding-left: 30px !important;
}
.sidebar ul li .sub-menu li a + ul a{
  position: relative;
  padding-left: 45px !important;
}


// .sub-menu li a {
//   padding: 0 10px 0px 37px;
//   font-weight: 400;
//   opacity: 0.85;
//   font-family: Proxima-Nova-Bold;
//   font-size: 14px;
//   color: #193366;
//   letter-spacing: -0.2px;
// }
.sub-menu li a {
      color: #494A4C;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
//   .sub-menu {
//     /*	padding: 0px 22px 0px !important;
//       margin-top: -10px !important; */
//   }

.sidebar li a .nav-label,
.sidebar li a .nav-label + span {
  transition: all 0.5s ease-in-out;
}

.sidebar.fliph li a .nav-label,
.sidebar.fliph li a .nav-label + span {
  display: none !important;
  transition: all 0.5s ease-in-out;
}

.logo-timer {
  width: auto !important;
}

.sidebar.fliph {
  width: 0;
  transition: all 0.2s ease-in-out;
}

.sidebar.fliph li {
  position: relative;
}

.user-panel {
  clear: left;
  display: block;
  float: left;
}

.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}

.user-panel > .info,
.user-panel > .info > a {
  color: #fff;
}

.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}

.user-panel {
  clear: left;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding: 25px 15px;
  border-bottom: 1px solid;
}

.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}

.fliph .user-panel {
  display: none;
}

.side-option-icon {
  height: 15px;
  padding-right: 10px;
  position: relative;
  top: -2px;
}

.sidebar li:hover .side-option-icon {
}

.content-wrapper {
  width: 62%;
  float: left;
  padding: 0px 40px 30px 25px;
  position: relative;
  margin: 0 auto;
  clear: both;
  display: block;
  transition: all 0.2s ease-in-out;
  left: 16.5%;
  background: #f8f8f8;
  min-height: calc(100vh - 100px);
}

.heading-box {
  background: #f5f8ff;
  color: #193367;
  padding: 15px;
  border-radius: 8px;
  float: left;
  width: 100%;
}

.owl-carousel {
  width: 100%;
  float: left;
}

.heading-box p {
  font-size: 17px;
  color: #193367;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}

.careousal-btn ul {
  list-style: none;
  padding-left: 0;
  text-align: right;
}

.careousal-btn ul li {
  display: inline-block;
  padding: 7px 6px;
}

.btn-careousal {
  background: #f40820;
  border-radius: 40px;
  color: #fff;
  padding: 8px 16px;
  font-size: 16px;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.white-box {
  background: rgba(25, 51, 103, 0.5);
  height: 100%;
  padding: 30px !important;
  box-shadow: 0 2px 2px 0 #f8f8f8;
  border-radius: 13px;
  position: relative;
  overflow: hidden;
  width: 100%;
  float: left;
}

.banner-wrapper {
  background: url("/img/lms-bg.png");
  border-radius: 13px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: auto;
  width: 100%;
  float: left;
}

.content h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 30px;
  color: black;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.content p {
  font-size: 18px;
  color: black;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  opacity: 0.7;
}

.banner-wrapper .content h3 {
  color: #fff !important;
}

.banner-wrapper .content p {
  color: #fff !important;
}

.banner-wrapper .content h5 {
  color: #fff !important;
}

.content .bottom {
  padding-top: 60px;
}

.content h5 {
  margin-bottom: 5px;
  font-size: 20px;
  color: black;
  letter-spacing: -0.33px;
}

.clipart-img img {
  max-width: 100%;
  position: absolute;
  right: -15px;
  height: 250px;
  top: -15px;
}

.ppb-wrapper {
  padding-top: 30px;
}

.ppb-wrapper h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 28px;
  color: #193367;
  letter-spacing: -0.5px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 18px;
}

.data-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 30px;
  height: 100%;
}

.data-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: black;
  letter-spacing: -0.3px;
  font-weight: 500;
}

.data-box a {
  background: #f40820;
  border-radius: 40px;
  padding: 5px 8px;
  text-align: center;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.data-box img {
  height: 12px;
  margin-top: -2px;
}

.white-bg-box {
  background: #fff;
}

.table-box {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-box p {
  color: black;
  opacity: 1;
  font-size: 13px;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-top: 15px;
  font-weight: 600;
}

.video-single-wrapper .heading {
  background: #f5f8ff;
  color: #193367;
  padding: 15px;
  font-size: 15px;
  margin-top: 20px !important;
}

.like-reply .comment-wrapper img {
  height: 30px;
  width: 30px;
}

.comment-wrapper .like-reply textarea {
  padding: 0 15px !important;
}

.heading {
  background: #f5f8ff;
  border-radius: 8px;
  font-size: 15px;
  color: #193367;
  letter-spacing: -0.23px;
  padding: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.video-details {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.video-details img {
  max-width: 100%;
}

.video-details h5 {
  color: black;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
}

.content p {
  font-size: 13px;
  color: black;
  opacity: 0.5;
}

.upcoming-class {
  height: 235px;
  overflow-y: scroll;
  padding: 30px;
}

.upcoming-class .row:last-child {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-top: 15px;
}

.icon-box {
  background: #e4f7fd;
  border-radius: 8px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.upcoming-class h6 {
  font-size: 15px;
  font-family: Proxima-Nova-Bold;
  font-weight: 500;
  color: black;
  letter-spacing: -0.25px;
  margin-bottom: 0;
  margin-top: 4px;
}

.upcoming-class span {
  font-size: 12px;
  color: #606060;
  letter-spacing: -0.2px;
}

.thumb-box img {
  background: #f40820;
  border-radius: 8px;
  height: 60px;
  width: 100%;
  object-fit: cover;
}
/* sidebar right css */
.sidebar-right {
  width: 20%;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  height: 100%;
  float: left;
  position: fixed;
  top: 60px;
  right: 0;
  padding: 30px;
  box-shadow: 0 2px 0 0 #f2f1f1;
  overflow-y: scroll;
  padding-bottom: 100px;
  padding-right: 15px;
}

.logout {
  padding-top: 20px;
}

.logout a p {
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  text-align: right;
  margin-top: 4.5px;
}

.logout a img {
  height: 15px;
  filter: brightness(0) invert(1);
  cursor: pointer;
}

.profile {
  padding-top: 20px;
}

.user-image img {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 100px;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.username {
  font-family: Proxima-Nova-Bold;
  font-weight: 500;
  padding-top: 15px;
  font-size: 15px;
  color: black;
  text-align: center;
  margin-bottom: 0;
}

.profile a {
  font-size: 12px;
  color: #606060;
  text-align: center;
}

.stats-section {
  padding-top: 20px;
  padding-left: 15px;
}

.pink-box {
  background: #fef4f8;
  border-radius: 8px;
  padding: 20px;
}

.pink-box .icon-box {
  background: #f93d83 !important;
  border-radius: 40px !important;
  margin-left: -40px;
}

.stats-section h6 {
  margin-bottom: 0;
  font-size: 13px;
  color: black;
  white-space: nowrap;
  margin-top: 5px;
}

.stats-section span {
  font-family: Proxima-Nova-Bold;
  font-weight: 500;
  font-size: 15px;
  color: black;
}

.blue-box {
  background: #f5f8ff;
  border-radius: 8px;
  padding: 20px;
}

.blue-box .icon-box {
  background: #4173fa !important;
  border-radius: 40px !important;
  margin-left: -40px;
}

.purple-box {
  background: #faf6fe;
  border-radius: 4px;
  padding: 20px;
}

.purple-box .icon-box {
  background: #a145ef !important;
  border-radius: 40px !important;
  margin-left: -40px;
}

.green-box {
  background: #f6fcf5;
  border-radius: 8px;
  padding: 20px;
}

.green-box .icon-box {
  background: #55d036 !important;
  border-radius: 40px !important;
  margin-left: -40px;
}

.footer {
  text-align: center;
  padding-top: 50px;
  width: 100%;
  float: left;
}

.footer p {
  font-size: 12px;
  color: black;
  margin-bottom: 0;
}

.footer a {
  color: #f40820;
}

.logo-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.profile-view a {
  display: none;
}

.close-sidebar-right {
  display: none;
}

.logo-m {
  display: none;
}
/* Videos Listing*/
.w-95 {
  width: 95% !important;
}

.w-80 {
  width: 83%;
}

.video-listing-wrapper {
  width: 100%;
  float: left;
}

.breadc {
  width: 100%;
  float: left;
}

.breadc ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadc ul li {
  display: inline-block;
  font-size: 14px;
  padding-right: 20px;
  letter-spacing: -0.17px;
  position: relative;
  vertical-align: text-top;
}

.breadc ul li::after {
  background: url("./img/right-arrow.png") center center no-repeat;
  width: 4px;
  height: 6px;
  content: "";
  position: absolute;
  right: 7px;
  top: 7px;
}

.cl-2.cl-new ul li:first-child img {
  height: 16px;
}

.no-eye-icon ul li:first-child {
  display: none;
}

.breadc ul li:last-child::after {
  display: none;
}

.breadc ul li a {
  color: black;
  font-family: Proxima-Nova-Bold;
  text-transform: capitalize;
}

.breadc ul li:last-child a {
  font-family: Proxima-Nova-Regular;
  color: #f40820;
  cursor: default;
  pointer-events: none;
}

.video-list-inner-wrapper {
  width: 100%;
  float: left;
  padding: 25px 35px;
  background: #f8f8f8;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.inner-head {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: black;
  letter-spacing: -0.33px;
}

.video-div {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.video-div img {
	height: 150px;
	object-fit: cover;
	width: 100%;
	// margin-bottom: 15px;
	border-radius: 0;
}

.video-div h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.23px;
  margin-bottom: 5px;
}

.video-div p {
  font-family: Proxima-Nova-Regular;
  font-size: 13px;
  color: #606060;
  letter-spacing: -0.2px;
  margin: 0;
}

.see-more {
  background: #f8f8f8;
  border-radius: 2px;
  width: 120px;
  color: #909090;
  height: 40px;
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid #e3e3e3;
  justify-content: center;
  margin-top: 30px;
  transition: all ease 0.2s;
}

.see-more:hover {
  color: #909090;
  transition: all ease 0.2s;
}

.see-more:hover img {
  transition: all ease 0.2s;
}

.video-header {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
}

.video-header ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.video-header ul li {
  display: inline-block;
}

.video-header ul li span {
  font: 13px;
}

.video-header ul li select {
  border-radius: 10px;
  border: 0;
  background: #eeeeee;
  color: black;
  font-size: 14px;
  height: 30px;
  width: 70px;
  margin-left: 5px;
}

.video-single-wrapper {
  width: 100%;
  float: left;
  padding: 0;
  border-top: 2px solid #eee;
  margin-top: 10px;
  padding-top: 10px;
}

.video-single-inner-wrapper {
  width: 100%;
  float: left;
  background: white;
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 0 0 8px 8px;
  padding-bottom: 10px;
}

.video-big-wrapper {
  width: 100%;
  float: left;
}

.video-big-wrapper img {
  border-radius: 0 0 6px 6px;
}

.video-single-inner-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: left;
  padding: 30px 20px;
}

.video-single-inner-header p {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #606060;
  letter-spacing: -0.23px;
}

.video-single-inner-header h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: black;
  letter-spacing: -0.33px;
  display: flex;
}

.download-btn {
  background: #f40820;
  border-radius: 33px;
  color: white;
  height: 37px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
  width: 155px;
}

.download-btn:hover {
  color: white;
  transition: all ease 0.2s;
}

.download-btn:hover img {
  position: relative;
  top: 2px;
  transition: all ease 0.2s;
}

.download-btn img {
  margin-right: 15px;
  position: relative;
  left: 0;
  transition: all ease 0.2s;
}

.video-single-content {
  width: 100%;
  float: left;
  padding: 0 20px;
}

.video-single-content b {
  color: black;
  font-size: 15px;
}

.video-single-content p {
  font-size: 14px;
  margin-bottom: 5px;
}

.video-comment {
  width: 100%;
  float: left;
  padding-top: 30px;
}

.video-comment b {
  color: black;
}

.comment-wrapper {
  width: 100%;
  float: left;
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.comment-user {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.comment-wrapper textarea {
  width: calc(100% - 80px);
  border: 0;
  padding: 0 25px;
  font-size: 13px;
  height: 20px;
}

.comment-wrapper button {
  width: 30px;
  height: 30px;
  border: 0;
  background: white;
}

.comment-wrapper textarea:focus {
  outline: 0;
}

.commenter {
  margin-right: 15px;
}

.commnet-content {
  width: 100%;
  float: left;
  padding: 0 15px;
  margin-top: 20px;
}

.commnet-in-content {
  display: flex;
  margin-top: 10px;
  width: 100%;
  float: left;
}

.commnet-data {
  width: 100%;
  float: left;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
}

.commnet-header {
  width: 100%;
  float: left;
  display: flex;
}

.commnet-header b {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  color: black;
  letter-spacing: -0.23px;
  width: 160px;
}

.commnet-header span {
  font-size: 14px;
  color: #606060;
  letter-spacing: -0.17px;
  margin-left: 15px;
  width: 150px;
}

.banner-wrapper .content p {
  color: #fff !important;
}

.banner-wrapper .content h5 {
  color: #fff !important;
}

.content .bottom {
  padding-top: 20px;
}

.content h5 {
  margin-bottom: 5px;
  font-size: 20px;
  color: black;
  letter-spacing: -0.33px;
}

.clipart-img img {
  max-width: 100%;
  position: absolute;
  right: -15px;
  height: 250px;
  top: -15px;
}

.ppb-wrapper {
  padding-top: 1.5rem;
}

.ppb-wrapper h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 28px;
  color: #193367;
  letter-spacing: -0.5px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 20px;
}

.data-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 30px;
  height: 100%;
}

.data-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: black;
  letter-spacing: -0.3px;
  font-weight: 500;
}

.data-box a {
  background: #f40820;
  border-radius: 40px;
  padding: 5px 8px;
  text-align: center;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.data-box img {
  height: 12px;
  margin-top: -2px;
}

.white-bg-box {
  background: #fff;
}

.table-box {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-box p {
  color: black;
  opacity: 1;
  font-size: 13px;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-top: 15px;
  font-weight: 600;
}

.video-single-wrapper .heading {
  background: #f5f8ff;
  color: #193367;
  padding: 15px;
  font-size: 15px;
  margin-top: 20px !important;
}

.like-reply .comment-wrapper img {
  height: 30px;
  width: 30px;
}

.like-reply {
  width: 100%;
  float: left;
  display: flex;
  justify-content: left;
  position: relative;
}

.reply-button {
  border-left: 1px solid #dedede;
  padding-left: 10px;
  margin-left: 10px;
  margin-top: 5px;
}

.commnet-content.reply-comment {
  padding-left: 0;
  margin-top: 10px;
}

.comment-wrapper.reply-comment-field {
  padding-left: 0;
}

.like-reply ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.like-reply ul li a {
  color: #606060;
  font-family: Proxima-Nova-Regular;
}

.like-reply ul li.like {
  color: #f40820;
  margin-right: 15px;
}
//   .like-reply ul ul li.dislike {}
//   .like-reply ul img {}

.like-reply a {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: black;
  letter-spacing: -0.2px;
  text-align: right;
  cursor: pointer;
  line-height: 18px;
}

.commnet-data .commnet-data {
  border: 0;
}

.side-box .upcoming-class {
  overflow: auto;
  height: initial;
  background: #f8f8f8;
  margin-top: 10px;
  border-radius: 6px;
  padding: 0 15px;
}

.side-box {
  background: none;
}
// changes by Rohit Dangi

.setsidebar {
  margin-left: 0 !important;
  overflow-y: auto;
  padding-bottom: 100px;
}
.history-tab-table-wrapper .row .card p.duration svg {
  margin-right: 10px;
}
/* 6 may css start */
.side-box {
  background: none;
  margin-top: 10px;
}

.video-single-inner-header {
  padding: 30px 20px 0 !important;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}

.video-comment {
  padding-top: 10px !important;
}

.commnet-data p {
  margin-bottom: 5px;
  padding-top: 0;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 0;
}

.commnet-data {
  border: none;
  padding-bottom: 5px !important;
}

.video-single-inner-header h4 {
  font-size: 20px;
}

.video-single-content b {
  color: black;
  font-size: 16px;
}

.video-single-content p {
  font-size: 15px;
}

.commnet-header b {
  font-size: 16px;
}

.like-reply img {
  height: 15px;
  margin-right: 5px;
}

.video-big-wrapper img {
  border-radius: 0 0 0 0;
}

.video-single-inner-header {
  padding: 30px 0 0 !important;
}

.video-single-inner-wrapper {
  background: #f8f8f8;
}

.comment-wrapper {
  background: #f8f8f8;
}

.comment-wrapper textarea {
  background: #f8f8f8;
}

.comment-wrapper button {
  background: #f8f8f8;
}

.video-single-content {
  padding: 0;
}

.thumb-box img {
  background: #f40820;
  border-radius: 0;
  height: 70px;
  width: 100%;
  object-fit: cover;
}

.ub-list .thumb-box img {
  height: 150px !important;
  object-fit: cover;
  width: 100% !important;
  margin-bottom: 15px;
  border-radius: 0;
}

.upcoming-class h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

.upcoming-class span {
  font-size: 14px;
}

.stats-section .icon-box img {
  height: 26px;
}

// .header-top {
//   width: 100%;
//   float: left;
//   display: block;
//   background: white;
//   height: 65px;
//   position: fixed;
//   top: 0;
//   z-index: 999;
//   display: flex;
// }

.logo-timer {
  // text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 13px;
}

.logo-timer {
  // float: left;
  display: inline-flex;
  align-items: center;
  width: 255px;
  height: 65px;
}
.logo-timer.logo-bg {
  width: 100% !important;
  justify-content: center;
  height: auto;
}

.logo-timer.logo-bg img {
  height: auto;
  max-width: 70%;
}
// .full-width {
//     width: 77%;
//     left: 4.2%;
// }

.full-width {
  width: 81.2%;
  left: 0;
}

.see-more img {
  height: 12px;
  transform: rotate(90deg);
}

.video-div h6 {
  color: #909090;
  font-size: 14px;
  margin-bottom: 0;
}

.see-more img.rotate-active {
  transform: rotate(270deg) !important;
}

.text-single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
//.content-wrapper.full-width .owl-carousel .owl-item.active {
//	width: 24% !important;
//}
//.content-wrapper .owl-carousel .owl-item.active {
//	width: 15.8% !important;
//}

.content-wrapper .owl-carousel .owl-stage-outer {
  height: 220px !important;
  width: 100%;
  float: left;
}

.video-listing-single-row .row .col-lg-3 {
  display: none;
}

.video-listing-single-row .row .col-lg-3:nth-child(-n + 4) {
  display: block;
}

.clipart-img {
  display: none;
}

.banner-wrapper .content a {
  background: #51b5f3;
  color: #fff;
  font-size: 13px;
  padding: 8px 15px;
  display: flex;
  width: 100px;
  height: 33px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  margin-bottom: 10px;
}

.bottom h5 span {
  margin-right: 5px;
  letter-spacing: 0.1px;
  font-family: Proxima-Nova-Bold;
  font-size: 17px;
}

.vp-center .player {
  width: 100% !important;
  max-width: 100% !important;
}

div#player {
  max-width: 100% !important;
}

.profile-icon img {
  display: none;
}

.video-div a {
  font-size: 14px;
  color: #f40820;
}

.dashborad-section .sidebar .nav-label {
  white-space: nowrap;
}

.products-wrapper {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #f40820;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.video-big-wrapper {
  // border-top: 2px solid  #eee;
  // padding: 10px 0px;
  border-bottom: 2px solid #eee;
}

.reply-toggle {
  font-size: 15px;
  color: #193367;
  float: left;
  padding-top: 5px;
}

.reply-toggle img {
  height: 6px;
  padding-left: 5px;
}

.vp-center .player {
  width: 100% !important;
  max-width: 100% !important;
}

.video-big-wrapper iframe {
  // background: #000;
}

.video-big-wrapper .player .vp-video-wrapper .vp-preview {
  background-size: cover !important;
}

.careousal-btn button.owl-prev {
  font-size: 15px !important;
  margin: 0;
}

.careousal-btn button.owl-next {
  font-size: 15px !important;
  margin: 0;
}

.sub-menu {
  padding-left: 0px !important;
}

.sub-menu li {
  padding: 5px 0 5px;
}
.sub-menu li:last-child {
  border: 0px;
}
.sub-menu.course-sub-menu li a {
  padding: 20px 15px !important;
  opacity: 1 !important;
  margin: 0 !important;
}

.login-tab-btn {
  background: transparent;
  color: #193368;
  border: 1px solid #193368;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.login-tab-btn:hover {
  background: #193368;
  color: #fff;
  border: 1px solid #193368;
}

.login-tab-btn.btn-outline-primary {
  background: #193368;
  color: #fff;
  border: 1px solid #193368;
}

.dnld-btn button:hover {
  background: #78fed1;
  color: #193368;
}

.hand-cursor {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.course-sub-menu {
  padding-left: 0 !important;
  margin-bottom: 5px !important;
}

a.collapsed.product-name {
}
// new   gurkaran and dangi

h5.title-video {
  color: #193367;
  font-size: 20px;
  letter-spacing: 0.2px;
}

// .careousal-btn {
// 	position: absolute;
// 	bottom: -85px;
// 	z-index: 9;
// 	right: 31px;
// }

.bottom h3 {
  font-size: 22px;
  margin-bottom: 0;
  font-size: 14px;
  margin-bottom: 0;
  font-family: Proxima-Nova-Regular, sans-serif;
  opacity: 0.7;
  text-transform: uppercase;
}

.w-100 {
  width: 100% !important;
  float: left;
}
/* New Akash*/
.sub-menu {
  width: 100% !important;
}
/* 20th may */
h5.title-video {
  font-size: 24px;
  font-family: Proxima-Nova-Bold;
  padding-left: 15px;
}

.content-wrapper.w-95.full-width {
  width: 100% !important;
  padding-right: 30px;
}

.video-single-wrapper .heading {
  background: none;
  font-family: Proxima-Nova-Bold;
  padding-bottom: 0;
}

.no-class .col-4 {
  max-width: 100%;
  width: 100%;
  flex: 0 0 100%;
}
/* 20th may end */
/*19 may */
@media (max-width: 1440px){
  .common-wrapper {
    width: 100%;
    margin-top: 29px;
}
}
@media (max-width: 1300px) {
  .sidebar ul {
    // width: 230px;
  }
}
@media (max-width: 991px) {
  .banner-wrapper .content h3.uppercase {
    width: calc(100% - 30px);
    font-size: 22px !important;
    margin-top: 5px;
  }
  .banner-wrapper .content h3 {
    width: calc(100% - 0px);
    font-size: 18px;
    margin-top: 5px;
  }

  .careousal-btn {
    bottom: -74px;
    right: 15px;
    transform: scale(0.8);
  }

  .ppb-wrapper h3 {
    font-size: 22px;
    line-height: 26px;
  }

  .content-wrapper.w-95.full-width {
    width: 100% !important;
  }

  .video-list-inner-wrapper {
    padding: 20px 0;
  }

  .sidebar {
    width: calc(100% + 16px) !important;
  }
}

.video-list-inner-wrapper {
  padding: 25px 0 !important;
  // border-top: 1px solid lightgrey !important;
  //     border-radius: 0px !important;
}

.faclity-name {
  // font-family: Proxima-Nova-Bold;
  // color: #7d7d7d;
  // margin-top: -5px;
  // display: block;
  // margin-bottom: 5px;
  font-family: Proxima-Nova-Bold !important;
  color: #7d7d7d !important;
  margin-top: -5px;
  display: block;
  font-size: 14px !important;
  margin-bottom: 5px;
}

.video-listing-boder-bottom {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}
/*21 may*/
.data-box a {
  padding: 5px 15px;
}

.video-list-inner-wrapper {
  padding: 0 !important;
  box-shadow: 0 0 !important;
}

.content-wrapper.w-80 {
  padding-right: 0;
}

.thumb-box img {
  height: 60px;
  width: 100%;
}

.upcoming-class .thumb-box {
  width: "calc(100% -0px)";
}

.heading {
  background: white;
  font-family: Proxima-Nova-Bold;
  padding-left: 30px;
  padding-bottom: 0;
}

.disable-nav-student {
  opacity: 0.4 !important;
}

.logout-wrapper {
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}

.logout-icon {
  margin-left: 15px;
}

.backbtn {
  background: #f40820;
  border-radius: 40px;
  padding: 5px 15px;
  text-align: center;
  transform: rotate(180deg);
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.backbtn img {
  height: 12px;
  margin-top: -2px;
  transform: rotate(180deg);
}

.profile {
  padding-top: 10px;
}

.pt-60 {
  padding-top: 60px !important;
}

a.product-name {
  position: relative;
}

.menu-icon-reset a.product-name.collapsed::before {
  content: "\f105";
  transform: rotate(0deg);
}

.menu-icon-reset a.product-name::before {
  content: "\f101";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 20px;
  font-size: 20px;
  transition: all ease 0.2s;
  transform: rotate(90deg);
  top: -2px;
  color: #f40820;
}

a.product-name {
  position: relative;
  padding-left: 35px !important;
  left: 8px;
  margin-bottom: 10px;
}
.product-name.collapsed {
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.sidebar ul li a{
  position: relative;
}

.sidebar ul li a.collapsed[data-toggle=collapse]::before {
  transform: translate(0px, -50%) rotate(0deg);
}
.sidebar ul li a[data-toggle=collapse]::before {
  transition: all ease 0.2s;
  color: #193367;
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0px, -50%) rotate(90deg);
  font-family: 'FontAwesome';
  font-size: 20px;
}


// .sidebar ul li a.collapsed[data-toggle=mainCollapse]::before {
//   transform: translate(0px, -50%) rotate(0deg);
// }
// .sidebar ul li a[data-toggle=mainCollapse]::before {
//   transition: all ease 0.2s;
//   color: #000000;
//   content: "\f105";
//   position: absolute;
//   right: 20px;
//   top: 50%;
//   transform: translate(0px, -50%) rotate(90deg);
//   font-family: 'FontAwesome';
//   font-size: 20px;
// }

.upcoming-class h6 span {
  color: black;
  font-size: 14px;
}

.upcoming-class span {
  color: #7d7d7d;
}

.sidebar li {
  border-bottom: 1px solid #f6f7f9;
}

.video-div a {
  font-size: 14px;
  color: #7c7c7c;
}
.dash-icon-right img {
  position: absolute;
  left: -5px;
  top: -5px;
  height: 20px;
  display: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sidebar ul {
    padding-left: 0px !important;
  }
  .upcoming-new-css .ub-list li {
    width: 276px !important;
  }
  .logout-wrapper {
    padding-right: 0px;
  }
}
// notification on student dashboard by akash
.backbtn {
  background: #f40820;
  border-radius: 40px;
  padding: 5px 15px;
  text-align: center;
  transform: rotate(180deg);
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
}

.backbtn img {
  height: 12px;
  margin-top: -2px;
  transform: rotate(180deg);
}

.profile {
  padding-top: 10px;
}

.innoti {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 20px;
  margin-top: -6px;
  transition: all ease 0.3s;
}

.innoti a {
  font-size: 16px;
  font-family: Proxima-Nova-Bold;
  color: #053467;
}

.innoti a:hover {
  color: #f40820;
}

.noti-bar {
  width: 100%;
  float: left;
}

.noti-section {
  width: 100%;
  float: left;
}

.notification-box {
  width: 100%;
  float: left;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #f8f8f8;
}

.noti-section {
  width: 100%;
  float: left;
}

.notification-box:first-child {
  margin-top: 10px;
}

.notification-box h6 {
  font-family: Proxima-Nova-Bold;
  font-weight: 500;
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: space-between;
}

.notification-box h6 span {
  font-family: Proxima-Nova-Regular;
  font-weight: 500;
  font-size: 12px;
  color: #8e8e8e;
  word-break: normal;
}

.notification-box span {
  font-family: Proxima-Nova-Regular;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  display: block;
  word-break: break-word;
}

.time-head {
  font-size: 13px;
  font-family: Proxima-Nova-Bold;
  color: #949494;
  margin-top: 20px;
  display: block;
  width: 100%;
  float: left;
  margin-bottom: -10px;
  // padding-left: 15px;
}

// .dropdown-menuu {
//   display: none;
// }

// .dropdown-menuu.open {
//   display: block;
// }

ul.list-sidebar.bg-defoult {
  padding: 0px 0px;
  min-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.sub-menu.course-sub-menu li {
  border-bottom: unset !important;
}
.notificationscontainer {
  position: relative;
}
// .notificationImage {
// 	position: absolute;
// }
.dot {
  float: right;
  margin-right: -13px;
  margin-top: 10px;
}
// 	overflow: auto;
// 	padding: 30px;
// 	margin: 0 auto;
// 	min-width: 260px;
// 	width: initial;
// }
span.innoti.notiheadd {
  border-top: 1px solid #eee;
  width: 100%;
  border-radius: 0px;
  padding-top: 4px;
  margin-top: 10px;
  float: left;
}
.innoti a:hover {
  color: #053467;
}
.flexmee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.video-single-wrapper .side-box:first-child {
  margin-top: 0px;
}
.video-single-wrapper .side-box .heading {
  padding-top: 0px;
  margin-top: 0px !important;
}
.upcoming-class .pt-4 {
  padding-top: 10px !important;
}
@media (max-width: 1300px) {
  ul.list-sidebar.bg-defoult {
    max-width: 100%;
    padding: 30px 0px !important;
  }
}

.class-description {
  color: #909090 !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
}

.join-now {
  background: #51b5f3;
  color: #fff !important;
  font-size: 13px;
  padding: 8px 15px;
  display: flex;
  width: 100px;
  height: 33px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  box-shadow: 0 25px 28px 1px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  margin-bottom: 10px;
}

.join-now-wrapper-trainer {
  position: relative;
  margin-bottom: 15px;
  .join-now {
    position: absolute;
    top: 55px;
    margin: 0 auto;
    left: 30%;
    z-index: 2;
    display: none;
  }
  .blue-background {
    position: absolute;
    top: 0px;
    width: 100%;
    height: calc(100% - 15px);
    background: #f40820;
    z-index: 1;
    opacity: 0.7;
    display: none;
  }
  &:hover {
    .join-now,
    .blue-background {
      display: block;
    }
  }
}

ul.scroll_horizontal {
  list-style: none;
  padding-left: 0px;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 15px;
}
ul.scroll_horizontal li {
  display: inline-block;
  width: 50%;
  margin-left: 30px;
}
ul.scroll_horizontal li:first-child {
  margin-left: 0px !important;
}

.join-now-wrapper-trainer .fa {
  color: #003366;
  font-size: 20px;
  position: absolute;
  bottom: 22px;
  right: 15px;
}

.three-dot-trainer {
  float: right;
  color: #7d7d7d;
  width: 20px;
  height: 20px;
  text-align: center;
  background: #efefef;
  padding-top: 2px;
  cursor: pointer;
}

.preserve-space {
  white-space: pre-wrap;
}

// class edit modal

.video-modal .modal-dialog {
  max-width: 926px;
}
.video-modal .modal-dialog .modal-body {
  padding: 10px 20px;
}
.modal-content {
  border-radius: 0px;
}
.video-modal-head {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.video-modal-head h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: #f40820;
  letter-spacing: -0.4px;
  line-height: 40px;
}

.video-modal-head ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.video-modal-head ul li {
  display: inline-block;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
  cursor: pointer;
}

.video-modal-head ul li button {
  background: #f40820;
  color: white;
  border-radius: 28px;
  padding: 5px 15px;
  border: 0px;
  margin-left: 10px;
  height: 34px;
}


.video-form-inner {
  width: 100%;
  padding: 20px 20px;
  // margin: 10px;
}

.confirm-modal {
  margin-left: 30px;
  margin-top: 20px;
}

.row-bg {
  background-color: #F8F8F8;
}

.form-modal-div {
  width: 100%;
}

.display-membership {
  font-size: 14px !important;
  margin-top: 78px !important;
}

.not-selected {
  color: #908e8e;
}

.form-control-width {
  width: 70px !important;
}

.form-modal-div label span {
  color: #ff0000;
}
.form-modal-div .form-control.form-disabled {
  background-color: #f3f3f3;
}
.video-form-inner h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32.3534px;
  line-height: 39px;
  color: black;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
.video-form-inner .form-modal-div label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: #000000;
}
.video-form-inner .form-modal-div input , .video-form-inner .form-modal-div textarea {
  border-radius: 0;
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  padding: 13px 20px;
  // height: auto;
}
.upload-imgbar {
  position: relative;
}

.imgbarininner {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  justify-content: center;
}

#imageUploadBox.has-image .imgbarininner {
  background: rgba(255,255,255,0.85);
}

#imageUploadBox:not(.has-image) img{
  display: none;
}

.upload-imgbar input#file {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  z-index: 9;
}

.upload-imgbar{
  width: 425px;
  height: 349px;
  background: rgba(0, 180, 243, 0.1);
  border: 1px dashed #f40820;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img#imageUploadBoxImage {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#imageUploadBox.has-image {
  position: relative;
}

.upload-imgbar .imgbarin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7;
  height: 100%;
  width: 100%;
}

.upload-imgbar .imgbarin svg {
  font-size: 40px;
  margin-bottom: 10px;
  color: #f40820;
  transition: .5s ease;
}
.upload-imgbar .imgbarin p {
  margin: 0;
  color: #f40820;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.upload-imgbar:hover .imgbarin svg {
  transform: translate(0px, -10px);
}
.video-modal .modal-footer {
  padding: 20px 0;
}
.video-modal .modal-footer ul {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.video-modal .modal-footer ul li {
  margin-left: 30px;
}
.video-modal .modal-footer ul li span.close-top , .video-modal .modal-footer ul li button {
  padding: 12px 40px;
  background: #ffffff;
  border: 1px solid #f40820;
  color: #f40820;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 50px;
  cursor: pointer;
}
.video-modal .modal-footer ul li button {
  background: #f40820 !important;
  color: #ffffff;
}
.video-modal .modal-footer ul li span.close-top svg {
  margin-right: 10px;
  font-size: 14px;
}
.form-modal-div.btn-div button {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 45px;
  width: 100%;
  box-shadow: 0px 0px 0px;
  background-image: url("/img/down-arrow.png");
  background-image: url("/img/down-arrow.png");
  background-position: 98% center;
  -webkit-appearance: initial;
  background-repeat: no-repeat;
}
.form-modal-div.btn-div div {
  width: 100%;
}
.form-modal-div.btn-div div div {
  width: 100%;
}
.form-modal-div.btn-div div div div {
  width: initial;
}
.form-modal-div button svg {
  display: none;
}
.form-modal-div select.form-control {
  background-image: url("/img/down-arrow.png");
  background-image: url("/img/down-arrow.png");
  background-position: 98% center;
  -webkit-appearance: initial;
  background-repeat: no-repeat;
}
.form-modal-div textarea.form-control {
  min-height: 130px;
}
.form-modal-div .date-icon {
  background-image: url("/img/date-icon.png");
  background-image: url("/img/date-icon.png");
  background-position: 95% center;
  background-repeat: no-repeat;
}
.form-modal-div .time-icon {
  background-image: url("/img/time-icon.png");
  background-image: url("/img/time-icon.png");
  background-position: 95% center;
  background-repeat: no-repeat;  
}
.form-modal-div {
  margin-bottom: 15px;
}

.form-modal-div .form-control {
  margin-bottom: 0px;
  border: 1px solid #858C94;
  border-radius: 8px;
}
.form-modal-div .form-control:first-child {
  margin-bottom: 0px;
}
.video-form-inner-2 {
  float: left;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
}
.upload-video-file {
  width: 100%;
  float: left;
  display: flex;
}
.upload-video-file span {
  height: 45px;
  width: calc(100% - 135px);
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  padding: 0px 15px;
}
.upload-video-file .fake-btn {
  width: 100px;
  margin-left: 10px;
  height: 45px;
  position: relative;
  cursor: pointer;
}
.upload-video-file .fake-btn input {
  opacity: 0;
  position: absolute;
  top: 22px;
  cursor: pointer;
  left: 0px;
  width: 100%;
}
.upload-video-file .fake-btn button {
  background: #f40820;
  color: #fff;
  border-radius: 36px;
  border: 0px;
  height: 45px;
  width: 125px;
  float: right;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-video-file .plus-btn-upload {
  height: 30px !important;
  width: 45px !important;
  margin-top: 17px !important;
}
.upload-video-file .fake-btn button i {
  font-size: 14px;
}
.form-modal-div table a {
  color: black;
}
.form-modal-div .table thead th,
.form-modal-div .table td {
  font-weight: normal;
  border-top: 0px;
}
.video-modal-head ul li span img {
  position: relative;
  top: -1.5px;
}
h5.batch_name.mb-3 span {
  font-size: 22px;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #8e8e8e;
  position: relative;
  top: -3px;
  margin-left: 10px;
}
.green-dot {
  background: #4caf50 !important;
}
.red-dot {
  background: #f44336 !important;
}
.video-single-inner-header .three-dot-trainer {
  float: right;
  color: #7d7d7d;
  width: 20px;
  height: 20px;
  text-align: center;
  background: none;
  padding-top: 3px;
  margin-left: 23px;
}
.cursor {
  cursor: pointer;
}
td.cursor:hover {
  color: #f44336;
}
.close-top {
  background: #f3f3f3;
  color: #184473;
  border-radius: 40px;
  padding: 7px 16px;
  border: 0px;
  margin-left: 10px;
  height: 34px;
}
@media (max-width: 991px) {
  .video-modal .modal-dialog .modal-body {
    padding: 10px;
  }
  .video-modal-head {
    display: block;
  }
  .form-modal-div {
    margin-bottom: 0px;
  }
}

.data-box {
  .upcoming-batch {
    img {
      height: 20px;
    }
  }
}

.upcoming-batch-wrapper {
  height: 150px;
  p {
    color: #909090;
    font-size: 14px;
    margin-bottom: 0;
  }
  h5 {
    font-family: Proxima-Nova-Bold;
    font-size: 18px;
    color: black;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
    white-space: break-spaces;
  }
}

// evaluator 3 tabs

.three-tab-wrapper {
  width: 100%;
  float: left;
}

.three-tab-wrapper .nav-pills .nav-item {
  width: 100%;
  padding: 0px 10px;
}

.three-tab-wrapper .nav-pills .nav-item:first-child {
  padding-left: 0px;
}

.three-tab-wrapper .nav-pills .nav-item:last-child {
  padding-right: 0px;
}

.three-tab-wrapper .nav-pills {
  flex-wrap: nowrap;
}

.three-tab-wrapper .nav-pills .nav-link {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  color: #053467;
  font-family: Proxima-Nova-Bold;
  height: 55px;
  font-size: 14px;
  padding: 18px 20px;
  border-radius: 8px;
}

.three-tab-wrapper .nav-pills .nav-link.active {
  background: #053467;
  box-shadow: 0 2px 0 0 #f2f1f1;
  color: #fff;
  font-family: Proxima-Nova-Bold;
}

.case_study_tab {
  width: 100%;
  float: left;
}

.cst-form {
  width: 100%;
  float: left;
  margin-top: 20px;
  padding: 25px;
  background: #ebf4f7;
  border-radius: 9.5px;
}

.cst-form label {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: black;
  letter-spacing: -0.23px;
}

.cst-form select.form-control {
  background-image: url("/img/down-arrow.png");
  background-position: 98% center;
  -webkit-appearance: initial;
  background-repeat: no-repeat;
}

.cst-form .form-control {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 45px;
}

ul.cst-form-ul {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  list-style: none;
  height: 100%;
  align-items: center;
  padding-top: 25px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2ab8ef;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #2ab8ef;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("/img/radio_tick.png");
}

.custom-radio .custom-control-label::before {
  border-radius: 4px;
}

.cst-div {
  width: 100%;
  float: left;
  margin-top: 15px;
}

.cst-div h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: black;
  letter-spacing: -0.33px;
  line-height: 40px;
}

.cst-div h3 img {
  position: relative;
  top: -1px;
  margin-right: 10px;
  display: none;
}
.page-title-text {
  color: #193367 !important;
}
.cst-cases-wrapper {
  width: 100%;
  float: left;
}

.case-div {
  width: 275px !important;
  float: left;
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-right: 15px;
  margin-bottom: 15px;
}
.case-upper {
  height: 150px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.case-upper span {
  border-radius: 0px 5px 5px 0px;
  position: absolute;
  padding: 0px 5px;
  left: 0px;
  top: 15px;
  font-size: 8px;
  color: #000000;
  letter-spacing: -0.13px;
}
.sql-bg span {
  background: #ff7f07;
}
.sql-bg {
  background-image: linear-gradient(180deg, #0076d0 0%, #0270c4 100%);
}
.case-lower {
  width: 100%;
  float: left;
  padding: 15px;
  background: white;
  border-top: 1px solid #eee;
}
.cl-1 {
  width: 50%;
  float: left;
  display: flex;
}
.cl-1 img {
  width: 8px;
  height: 11px;
  margin-right: 10px;
}
.cl-1 b {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
  display: block;
  margin-top: -6px;
}
.cl-1 span {
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.2px;
  display: block;
}
.cl-2 {
  display: flex;
  width: 50%;
  float: left;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  position: relative;
  right: -15px;
}
.cl-2 ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.cl-2 ul li {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}
.xl-bg {
  background-image: linear-gradient(180deg, #1c673d 0%, #12502e 100%);
}
.xl-bg span {
  background: #ffd507;
}

//eye css
.video-single-inner-header i.fa-info-circle {
  color: #fff;
  position: relative;
  top: -1.5px;
  padding: 3px;
  width: 40px;
  height: 26px;
  border-radius: 20px;
}
// side list cursor
.new-hand-cursor {
  cursor: pointer;
}

.upload_file_box {
  label {
    width: 40px;
    height: 40px;
    float: left;
    border: 1px solid #ecedf1;
    display: block;
    padding: 8px;
    cursor: pointer;
    i {
      color: #0073e6;
      font-size: 25px;
    }
  }
  .upload_file {
    opacity: 0;
    margin-right: -12px;
    width: 10px;
  }
}

/*history tabs*/
.history-tab-table-wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 30px 40px;
}
.history-tab-table-wrapper .row {
  // width: 100%;
}

.history-tab-table-wrapper .row .card {
  padding: 50px 30px;
}

.history-tab-table-wrapper .row .card h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32.3534px;
  line-height: 39px;
  color: black;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.history-tab-table-wrapper .row .card p.price {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 61px;
/* identical to box height */
  color: #000000;
  margin-bottom: 40px;
}

.history-tab-table-wrapper .row .card p.duration {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20.8869px;
  line-height: 35px;
  color: #030303;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.history-tab-table-wrapper .table th,
.history-tab-table-wrapper .table td {
  border-top: 0px;
  font-family: Proxima-Nova-Regular;
  font-weight: 400;
}
.history-tab-table-wrapper .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.history-tab-table-wrapper .table-striped tbody tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.history-tab-table-wrapper .table-striped tbody td b {
  font-family: Proxima-Nova-Bold;
  color: black;
}
.history-tab-table-wrapper .row .card {
  padding: 50px 30px;
  border: 1px solid #CBCBCB;
  border-radius: 20px;
  transition: .5s ease;
  cursor: pointer;
}
.history-tab-table-wrapper .row .card:hover {
  transform: translate(0px, -10px);
}
.history-tab-table-wrapper .table-striped tbody td {
  color: black;
}
//   letter-spacing -0.3px;
span.tbadge {
  padding: 5px 25px;
  border-radius: 20px;
}
.yellowbadge {
  background: #ffd507;
  padding: 5px 25px;
  border-radius: 20px;
}
.greenbadge {
  background: #00b501;
  padding: 5px 25px;
  border-radius: 20px;
}
.failedbadge {
  background: #ff7f07;
  padding: 5px 25px;
  border-radius: 20px;
}
.file-vault-wrapper {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.fv-head {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fv-head h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: black;
  letter-spacing: -0.4px;
  margin: 0px;
}
.fv-head a {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: black;
  letter-spacing: -0.2px;
  text-align: right;
}
.fv-body {
  width: 100%;
  float: left;
}
.fv-content {
  width: 185px;
  float: left;
  background: #fff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.fv-content .fvimg {
  height: 165px;
  object-fit: cover;
}
.fv-bottom {
  display: flex;
  //   height: 35px;
  padding: 10px;
  align-items: center;
  border-top: 1px solid #f8f8f8;
  justify-content: space-between;
}

.fv-bottom span {
  font-size: 12px;
  margin: 0px 10px;
}
@media (max-width: 991px) {
  .history-tab-table-wrapper .table {
    width: 800px;
  }
}

.cl-new {
  ul {
    width: 100%;
    li {
      float: left;
      margin-left: 5px;
      &:first-child {
      }
    }
  }
}

.under-my-observation {
  display: flex;
  justify-content: space-between;
  .right-section {
    a {
      font-size: 14px;
      margin-left: 15px;
      color: #073467;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-right: 5px;
        display: initial !important;
      }
      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}

.case-study-status {
  font-size: 12px;
  font-family: Proxima-Nova-Regular, sans-serif;
}

/*Case study Wrapper*/
.case-study-wrapper {
  width: 100%;
  float: left;
}
.study-header {
  width: 100%;
  float: left;
  margin-top: 20px;
}
.colour-wrapper {
  padding: 15px 20px;
  width: 100%;
  float: left;
  background: white;
  display: flex;
  align-items: center;
  border-radius: 9.5px;
  margin-bottom: 20px;
  height: 100%;
}
.colour-wrapper img {
  width: 36px;
  margin-right: 15px;
}
.colour-wrapper span {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: black;
  letter-spacing: -0.23px;
}
.colour-wrapper h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: black;
  letter-spacing: -0.3px;
  margin: 0px;
}
.pink-wrapper {
  background: #f8eef2;
}
.blue-wrapper {
  background: #eef1f8;
}
.purple-wrapper {
  background: #f3eff7;
}
.green-wrapper {
  background: #eff5ee;
}
.common-wrapper {
  width: 100%;
  // margin-top: 59px;
}
.common-wrapper h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: #f40820;
  letter-spacing: -0.4px;
  margin: 0px;
}
td.result {
}
.tdark {
  background: #f8f8f8;
}
.result {
  display: none;
}
.result.active {
  display: table-cell;
}
.result .commnet-data {
  border-bottom: 0px;
}
.result .commnet-in-content {
  border-top: 1px solid #f9f9f9;
  padding-top: 15px;
}
.fv-content {
  width: 185px;
  float: left;
  background: #fff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
  float: left;
  margin-right: 20px;
  overflow: hidden;
}
.no-eye-icon ul {
  position: relative;
  right: -45px !important;
}

.cl-2.cl-new ul li img {
  height: 20px;
  cursor: pointer;
}

.case-div .cl-new ul {
  position: relative;
  right: -15px;
  top: 5px;
}

//custom
.case-div-custome-height .case-div {
  height: 230px !important;
}

// BY Gurkaran
.case-study-student .case-div {
  // width: 23.8%;
  width: 18.7%;
}
.case-study-student .cl-1 {
  width: 70% !important;
}
// .case-div:last-child {
// 	margin-right: 0px !important;
// }

.case-study-student .cl-2 {
  width: 30% !important;
  right: -10px !important;
}

.upload_file_box img {
  height: 18px;
  margin-top: -4px;
}

.w-100px-text {
  width: 120px !important;
  font-weight: normal;
}

// filter box css
.filter-img img {
  height: 20px;
  float: right;
  cursor: pointer;
  margin-top: 10px;
}

.filter-box {
  width: 300px;
  background: #ffffff;
  padding: 25px;
  position: absolute;
  right: 0px;
  top: 35px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.download-filter-box {
  width: 300px;
  background: #ffffff;
  padding: 25px;
  position: absolute;
  border-radius: 8px;
  margin-left: -280px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.filter-box ul {
  list-style: none;
  padding-left: 0px !important;
  width: 100%;
  float: left;
  padding-top: 10px;
}
.filter-box span {
  color: #193367;
  letter-spacing: 0.3px;
  font-family: Proxima-Nova-Bold;
}
.filter-box ul li {
  width: 100%;
  float: left;
}
.filter-box .custom-checkbox {
  color: #193367;
  font-size: 15px;
  float: left;
  font-weight: normal;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.filter-box ul li small {
  letter-spacing: 0.5px;
  font-size: 11px;
  padding-left: 2px;
}

.filter-box-wrapper {
  float: right;
  position: absolute;
  top: 17px;
  right: 0;
  z-index: 99;
}
.right-top2 .filter-box-wrapper {
  position: relative;
  top: 0px;
}
.filter-box ul li label {
  color: #212529;
  font-size: 15px;
}

.status-list {
  background: #ebf4f7;
  width: 100%;
  float: left;
  border-radius: 8px;
  padding: 15px 20px;
}

.status-list ul li {
  display: inline-block;
  width: 50%;
}

.custom-type-badge {
  background: #51b5f3;
  display: flex;
  color: #fff;
  width: 30px;
  font-size: 16px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 60%;
}

.expanable-table th,
.expanable-table td {
  background: #ebf4f7;
}

.expanable-table {
}

// file vault downlaod button
.downloadfiles {
  height: 20px;
}

.blue-color {
  color: #003367 !important;
  font-family: Proxima-Nova-Bold !important;
}

.small-icon {
  height: 24px;
  // width: 24px;
  object-fit: contain;
}

.clear-filter {
  margin-right: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #f40820;
  color: #f40820;
  font-size: 12px;
  padding-bottom: 0px;
  &:hover {
    color: #f40820;
  }
}

.information-icon {
  float: right;
  color: #848584;
  font-size: 18px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
}

.fv-top {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: auto !important;
    // object-fit: cover;
    width: 64px;
  }
}

.eclips-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

//custom
.custom-top {
  top: unset !important;
}
// case study hide show toggle
.cst-cases-wrapper .case-div {
  display: none;
}

.cst-cases-wrapper .case-div:nth-child(-n + 4) {
  display: block !important;
}

.ev-casestudy-page .case-div {
  width: 23.4%;
}

.ev-casestudy-page .case-div .cl-new ul {
  position: relative;
  right: 0px;
  top: 0px;
}
.cst-cases-wrapper.tenbox-show .case-div {
  display: block !important;
}

.heading_size {
  font-size: 14px;
}
.cur-point {
  cursor: pointer !important;
  line-height: 0.9;
}

.firm-cur-point {
  cursor: pointer !important;
  line-height: 0.9;
}

.margin-20 {
  margin: 20px 0px;
}
.see-more-btn {
  background: #f40820;
  padding: 5px 15px;
  border-radius: 8px;
  color: #fff;
  margin-top: 15px;
  font-size: 12px;
}

.wrap-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
}

.clear-filter-ev {
  margin-right: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #f40820;
  color: #f40820;
  font-size: 12px;
  white-space: nowrap;
  padding-bottom: 0px;
  position: relative;
  z-index: 99;
  top: -24px;
  left: -25px;
}

.hidden-div-non-clickable {
  width: 100%;
  height: 100vh;
  background: none;
  position: fixed;
  top: 0px;
  z-index: 9;
}

.bread-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-top2 {
  display: inline-flex;
  align-items: center;
}

/*student listing*/
.student-list-table td ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.student-list-table td ul li {
}
td.ul-list-edit {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}
td.ul-list-edit img {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px;
}
.student-list-table tr:hover td.ul-list-edit img {
  display: block;
}
.top-header h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: black;
  letter-spacing: -0.4px;
}
.search-member-header h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: black;
  letter-spacing: -0.4px;
}
.or-label h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.4px;
  text-align: center;
  margin-top: 12px;
}
.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-left: 10px;
}
.right-flex-header {
  float: right;
  display: inline-flex;
}

.right-flex-headerr {
  justify-content: right;
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 30px;
}
.sideBox {
  height: 74px;
  width: 962px;
  background-color: #ffffff;
  margin-left: 79px;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
}
.sideBox div{
  flex: 1;
  padding-left: 20px;
  padding-top: 17px;
}
.sideBox span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #929292;
}
.sideBox p {
  font-size: 14px;
  color: #09101D;
  opacity: 0.8;
}
.search-head {
  min-width: 300px;
  line-height: 40px;
  display: inline-flex;
  align-items: center;
}
.btns-head {
  display: inline-flex;
  margin-left: 15px;
  align-items: center;
  width: auto;
  line-height: 40px;
}
.btn-head {
  display: inline-flex;
  margin-left: -58px;
  align-items: center;
  width: 103px;
  line-height: 40px;
}
.btns-head .filter-btn {
  margin-right: 15px;
}
.btn-common-img {
  background: #f40820;
  border-radius: 6px;
  width: 120px;
  color: white;
  height: 40px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
  margin-left: 50px;
}
.btn-common-imgg {
  // border-color: #f40820;
  background: #f40820;
  border-radius: 6px;
  width: 120px;
  color: white;
  height: 29px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
  margin-left: 50px;
}

.convertBtn {
  background: #f40820;
  border-radius: 6px;
  transition: all ease 0.2s;
  border: none;
  color: white;
  height: 25px;
  font-size: 12px;
  margin-right: 10px;
}

.actionBtns {
  display: flex;
  justify-content: space-evenly;
}


.btn-wd {
  width: fit-content;
  padding: 8px 20px;
}
.btn-common-img img {
  margin-right: 5px;
}
.btn-common-img:hover {
  color: #fff;
  text-decoration: none;
}
.show-search {
  width: 100%;
  float: left;
  display: flex;
}
.searchleft {
  width: 50px;
  float: left;
  background: #f40820;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.searchleft img {
  width: 16px;
}

.searchrightt {
  float: left;
  position: relative;
}

.searchright {
  width: calc(100% - 20px);
  float: left;
  position: relative;
}
.searchright input {
  border: 1px solid #f3f3f3;
  border-radius: 0px 8px 8px 0px;
  height: 45px;
}
.searchright a {
  position: absolute;
  right: 15px;
  top: 2px;
}
.searchright a img {
  width: 22px;
}


.searchBar {
  width: calc(100% - 50px);
  float: left;
  position: relative;
}
.searchBar input {
  border: 1px solid #f3f3f3;
  border-radius: 0px 8px 8px 0px;
  height: 36px;
}
.searchBar a {
  position: absolute;
  right: 15px;
  top: 5px;
}
.searchBar a img {
  width: 22px;
}

.search-padding {
  padding-top: 10px !important;
}
.row-margin {
  margin-top: 18px;
}
.search-btn {
  margin-top: 20px;
  text-align: center;
  margin-left: 108px;
}
@media (max-width: 767px) {
  .right-flex-header {
    width: 100%;
  }
  .right-flex-headerr {
    flex-direction: column;
  }
  .flex-header {
    display: block;
  }
  .flex-header .btn-common-img span {
    display: none;
  }
  .btn-common-img {
    width: 40px;
  }
  .btn-common-img img {
    margin: 0px;
  }
  .right-flex-header .btns-head {
    width: 85px;
  }
   
}

//custom
.hidden-button-class {
  position: absolute;
  width: 118px;
  opacity: 0;
}

span.addphone-btn {
  width: 45px;
  background: #38b5f3;
  height: 45px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-bottom: 5px;
}

.rotate-imge {
  transform: rotate(45deg);
}

/* profile page css start */

.top-profile-box {
  background: #ebf4f7;
  padding: 20px;
  border-radius: 9.5px;
}

.profile-content img {
  background: #d8d8d8;
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 100px;
}
.profile-content h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  line-height: 30px;
  color: black;
  margin-bottom: 0px;
}
.profile-content p {
  font-family: Proxima-Nova-Regular, sans-serif;
  font-size: 12px;
  color: #555555;
  margin-bottom: 0px;
  letter-spacing: -0.2px;
}

.profile-options ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 8px;
  margin-top: 12px;
}
.profile-options ul li {
  display: inline-block;
  padding-left: 15px;
}
.profile-options ul li a {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: black;
  letter-spacing: -0.2px;
}
.profile-options ul li img {
  height: 11px;
  margin-top: -2px;
  padding-right: 5px;
}
.profile-options {
  float: right;
}

.profile-options p {
  font-family: Proxima-Nova-Regular;
  font-size: 10px;
  color: #555555;
  letter-spacing: -0.17px;
  margin-bottom: 0px;
  text-align: right;
}
.profile-options a {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: black;
  letter-spacing: -0.2px;
}
.profile-tab-wrapper .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
  font-family: Proxima-Nova-Bold;
  border-bottom: 2px solid #003367;
  border-radius: 0px;
}
.profile-tab-wrapper .nav-pills .nav-link {
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  margin-right: 15px;
}
.next-prev-btn-list {
  float: right;
}
.next-prev-btn-list ul {
  padding-left: 0px;
  list-style: none;
}

.next-prev-btn-list ul li {
  display: inline-block;
  margin-left: 5px;
}
.next-prev-btn-list ul li a img {
  height: 10px;
  margin-top: -1px;
}
.next-prev-btn-list ul li a {
  background: #f40820;
  border-radius: 12px;
  display: flex;
  height: 25px;
  width: 35px;
  align-items: center;
  justify-content: center;
}

.assign-products ul {
  list-style: none;
  padding-left: 0px;
}

.assign-products h2 {
  font-family: Proxima-Nova-Bold;
  font-size: 22px;
  color: #f40820;
  letter-spacing: -0.4px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f40820;
}

.assign-products label {
  font-size: 14px;
  font-family: Proxima-Nova-Regular;
  color: black;
  letter-spacing: -0.23px;
  font-weight: 100 !important;
  text-transform: capitalize;
  padding-left: 25px;
  &::after {
    left: 0 !important;
  }
}

.assign-products .custom-control-label::before {
  position: absolute;
  top: 2px;
  left: -2px;
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.batch-courses h2 {
  font-family: Proxima-Nova-Bold;
  font-size: 22px;
  color: #f40820;
  letter-spacing: -0.4px;
  padding-bottom: 10px;
}

.create-order-gst {
  font-family: Proxima-Nova-regular;
  font-size: 11px;
  color: #555555;
  letter-spacing: -0.23px;

  margin-bottom: 0px;
}

.batch-expanable-box h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: black;
  letter-spacing: -0.33px;
}

.batch-expanable-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  cursor: pointer;
  padding: 30px;
}

.add-course-btn {
  background: #f40820 !important;
  border-radius: 25px !important;
  color: #fff !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  transition: all ease 0.3s;
  position: absolute;
  top: 33%;
  opacity: 0;
  left: 40%;
}
.add-course-btn i {
  padding-right: 5px;
}

.add-course-btn:hover {
  transition: all ease 0.3s;
  transform: scale(1.05);
}

.batch-expanable-box:hover .batch-hover {
  opacity: 0.3;
}

.batch-expanable-box:hover .add-course-btn {
  opacity: 1;
}
.batch-expanable-box.expand-open-box .add-course-btn {
  display: none;
}
.batch-expanable-box.expand-open-box p {
  display: none;
}
.close-expand-box i {
  background: #f40820;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  margin-top: -30px;
  font-size: 14px;
  border-radius: 10px;
}
.close-expand-box {
  display: none;
}
.batch-expanable-box.expand-open-box .close-expand-box {
  display: block !important;
}

.batch-expanable-box.expand-open-box:hover .batch-hover {
  opacity: 1 !important;
}
.expand-form-box {
  display: none;
}

.batch-expanable-box.expand-open-box .expand-form-box {
  display: block;
}
.expand-form-box {
  background: #fbfbfb;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 30px;
  margin: 25px 0px;
}
.expand-form-box span {
  color: #555555;
  font-size: 14px;
}
.expand-form-box h6 {
  color: #193367;
  font-size: 18px;
  font-family: Proxima-Nova-Bold;
}

.expand-form-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: #f40820;
  letter-spacing: -0.3px;
  margin-bottom: 0px;
  float: right;
  margin-top: 15px;
}

.expand-form-box label {
  font-size: 14px;
  font-family: Proxima-Nova-Regular;
  color: black;
  letter-spacing: -0.23px;
}
.expand-form-box select {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 45px !important;
}

.expand-form-box input {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 45px !important;
}
.form-control:focus {
  border-color: #e5e5e5;
  outline: 0;
  box-shadow: unset;
}

.submit-btn {
  background: #f40820 !important;
  border-radius: 25px !important;
  color: #fff !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  margin-top: 20px !important;
}

.submit-btn i {
  padding-right: 5px !important;
}

/* profile page responsve css */

@media (max-width: 991px) {
  .top-profile-box {
    text-align: center;
  }
  .profile-options {
    float: unset;
    text-align: center;
  }
  .profile-options ul li {
    padding-left: 0px;
    padding-right: 15px;
  }
  .profile-options p {
    text-align: center;
  }

  .profile-tab-wrapper .nav-item {
    width: 100%;
    text-align: center;
  }
  .next-prev-btn-list {
    float: unset;
    text-align: center;
    margin-left: -15px;
  }
}

.hover-text-case-study {
  background: rgba(7, 51, 103, 0.9);
  color: #fff !important;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.case-upper {
  &:hover {
    .hover-text-case-study {
      display: flex;
    }
  }
}

.assign-products .custom-control {
  float: left;
  width: 100%;
  padding-left: 0px;
  margin-bottom: 0px !important;
}

.profile-tab-wrapper {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
}
.pending-tab {
  border: 5px solid black;
  display: flex;
  flex-direction: row;
}
.bg-col-custom {
  background: #f8f8f8;
}
.btn-styl {
  border-radius: 28px !important;
  color: white !important;
}
.btn-styl-background {
  background-color: #f40820;
  color: black;
}
.btn-no-style:hover {
  background-color: #fff;
  border: none;
  padding-left: 0;
  padding-top: 10;
  padding-bottom: 10;
}
.btn-no-style {
  background-color: #fff;
  border: none;
  padding-left: 0;
  padding-top: 10;
  padding-bottom: 10;
}
.inner-filter-box {
  border: 1px solid #eee;
}
.inner-filter-box .custom-checkbox .custom-control-label::before {
  top: 14px;
}

.inner-filter-box .custom-checkbox .custom-control-label::after {
  top: 14px;
}
.new-fltr {
  float: right;
  position: absolute;
  right: 0;
  top: -10px;
  height: 30px;
}
.no-data {
  text-align: center;
  display: block;
  font-size: 16px;
  padding-top: 10px;
}
.filter-m .custom-control {
  margin-bottom: -5px !important;
}
.margin-proceed {
  margin-left: 10px;
}
.float-me {
  position: fixed;
  bottom: 20px;
  right: 30px;
}

.profile-wrapper {
  .btn {
    &:hover {
      color: #fff;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}
.width-fixed {
  width: 140px;
}

/* Payment Module Css Start */

.profile-tab-wrapper .payment-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: transparent;
  font-family: Proxima-Nova-Bold;
  border-bottom: unset !important;
  background: #ebf4f7;
  border-radius: 8px;
}

.profile-tab-wrapper .payment-tabs .nav-pills .nav-link {
  text-transform: capitalize !important;
  color: black;
  font-size: 14px;
  letter-spacing: -0.27px;
  margin-right: 0px !important;
}

.right-minus-30 {
  right: -30px;
}

.height-0 {
  height: 0px !important;
}

.payment-tabs-content .expand-form-box .custom-checkbox input {
  height: 0px !important;
}
.Installment-box label {
  font-size: 14px;
  font-family: Proxima-Nova-Regular;
  color: black;
  letter-spacing: -0.23px;
}

.Installment-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #505050;
  letter-spacing: -0.23px;
}

.badge-custom {
  background: #f40820;
  color: #fff;
  font-weight: normal !important;
  border-radius: 10px;
  padding: 2px 8px;
}

.calculation-boxes h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  color: black;
  letter-spacing: -0.25px;
  margin-bottom: 0px;
}

.calculation-boxes h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.17px;
}

.calculation-boxes {
  padding-top: 7px;
}

.calculation-boxes .fa {
  color: #f40820;
  margin-top: 10px;
}

.di-icon img {
  height: 24px;
  position: absolute;
  right: 10%;
  top: 30%;
}

.calc-total-box {
  background: #ebf9fe;
  border-radius: 8px;
  padding: 10px 15px;
  width: 125px;
  position: relative;
  margin-top: -10px;
}

.total-box-netpay h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #555555;
  letter-spacing: -0.27px;
  margin-bottom: 4px;
}

.total-box-netpay h3 {
  font-size: 18px;
  color: #49c7f3;
  letter-spacing: -0.4px;
  margin-bottom: 4px;
}

.total-box-netpay {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  cursor: pointer;
  padding: 21px 25px;
}

.cancel-order {
  color: black;
  float: right;
  font-size: 15px;
}

.order-table-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}
.text-bold {
  font-family: Proxima-Nova-Bold;
  color: #757575;
}

.table-bg-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0#F2F1F1;
  border-radius: 8px;
  cursor: pointer;
  padding: 30px;
}

.table td,
.table th {
  border-top: unset;
}
.table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
.table-bg-box th {
  font-weight: normal;
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.2px;
}
.sno {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.17px;
}
.value-text {
  font-size: 14px;
  color: black;
  letter-spacing: -0.23px;
}

.table-bg-box table td input {
  width: 105px;
  height: 25px;
  background: #fbfbfb;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  font-size: 11.5px;
  padding-left: 5px;
  margin-left: -3px;
  color: black;
}
.table-bg-box table td select {
  width: 100px;
  height: 25px;
  background: #fbfbfb;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  font-size: 10px;
  padding-left: 5px;
  margin-left: -3px;
  color: black;
}

.badge {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: Proxima-Nova-Regular;
  font-weight: normal !important;
}
.sub-heading-tb {
  font-size: 12px;
}
.due-date {
  font-size: 14px;
  color: black;
  letter-spacing: -0.23px;
}
.due-date-icon {
  position: relative;
  left: -15px;
}
.due-date-icon input {
  // position: absolute;
  // top: -20px;
  // right: 20px;
  // width: 20px !important;
  // height: 20px !important;
  // z-index: 9;
  // opacity: 0;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 20px !important;
  height: 20px !important;
  z-index: 9;
  opacity: 0;
}

.due-date-icon img {
  position: absolute;
  height: 15px;
  right: 22px;
  top: -18px;
}

.btn-tb-action i {
  position: relative;
  padding-right: 5px;
  font-size: 12px;
  top: -2px;
}

.btn-tb-action {
  background: #f40820 !important;
  color: #fff !important;
  font-weight: normal !important;
  border-radius: 15px !important;
  font-size: 10px !important;
  width: auto !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 8px;
}
// .calculation-boxes .col-lg-3 {
// 	height: 60px;
// }

/* payment module  responsive css start */

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .calculation-boxes h3 {
    font-size: 18px;
  }
  .total-box-netpay h6 {
    font-size: 12px;
  }

  .due-date-icon {
    position: relative;
    // right: -25px;
    // top: -2px;
    top: -22px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .calculation-boxes .col-lg-3 {
    height: 60px;
  }
  .cancel-order {
    float: left;
    padding-top: 5px;
  }

  #pills-notes .demo-editor.rdw-editor-main {
    height: auto !important;
  }

  .upcoming-new-css .ub-list li {
    width: 276px !important;
  }
  .video-div {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 550px) and (max-width: 992px) {
  .upcoming-new-css .ub-list li {
    width: 276px !important;
  }
}

// june 15
.scroll-dev {
  max-height: 300px;
  overflow: scroll;
}
.scroll-dev1 {
  max-height: 250px;
  overflow: scroll;
}
// .react-datepicker-wrapper{
// 	width: 22% !important;
// 	height: 15px !important;
// 	background: red;
// }

// .profile-tab-wrapper .react-datepicker-wrapper{
// 	width: unset !important;
// 	background: red;
// }
.consolure-filter {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

/* signup page Css start */
.signup-page {
  width: 100%;
  float: left;
}

.signup-bg-image {
  background: url(/img/signup-bg.png);
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 75px 0px;
  background-size: cover;
}

.an-logo img {
  height: 51px;
}
.signup-content {
  color: #fff;
  padding-top: 65px;
}

.signup-content h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 18px;
  color: #ffffff;
}

.signup-content h4 {
  font-family: Proxima-Nova-Extra-Bold;
  font-size: 36px;
  color: #ffffff;
  line-height: 46px;
  margin-bottom: 0px;
}

.signup-content p {
  font-family: Proxima-Nova-Regular;
  font-size: 18px;
  color: #ffffff;
}

.signup-stats {
  width: 50%;
  margin-top: 50px;
}

.ss-icon-box {
  background: #ffffff;
  border-radius: 14px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ss-icon-box img {
  height: 24px;
}

.signup-stats h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: #78ffd1;
  letter-spacing: -0.4px;
  margin-bottom: 0px;
}

.signup-stats small {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0.3px;
}

.green-btn img {
  height: 16px;
  position: relative;
  top: -2px;
  padding-right: 5px;
}

.green-btn {
  background: #78ffd1 !important;
  border-radius: 22.5px !important;
  text-transform: uppercase !important;
  font-family: Proxima-Nova-Bold !important;
  font-size: 14px !important;
  color: #013469 !important;
  white-space: nowrap !important;
  padding: 8px 16px !important;
  padding-top: 10px !important;
}

.signup-box {
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 57px 50px;
  width: 480px;
  float: right;
}
.signup-box h5 {
  font-family: Proxima-Nova-Bold !important;
  font-size: 24px;
  color: #013469;
}

.signup-form input {
  background: #ffffff;
  border: 1px solid #b0b0b0;
  border-radius: 6px;
  padding-left: 40px !important;
  padding: 8px;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  padding-top: 9px;
  margin-bottom: 0px;
  height: auto;
}

.signup-form .custom-checkbox {
  opacity: 0.8 !important;
  font-family: Proxima-Nova-Regular !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 20px !important;
}
.signup-form .icon-in-field {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 14px;
}
.signup-form input::placeholder {
  opacity: 0.3;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.signup-form label {
  opacity: 0.8;
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  font-weight: normal;
}

.signup-form .eye-icon {
  right: 0px;
  transition: all 0s ease !important;
}

.signup-form .eye-icon {
  right: 13px !important;
  left: auto;
  height: 14px !important;
  top: 13px !important;
}

.signup-form ul {
  list-style: none;
  padding-left: 0px;
}

.have-acc-box {
  background: url("/img/box-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 80px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 40px;
  text-align: center;
}
.have-acc-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: -0.3px;
  white-space: nowrap;
}

/* signup page responsive css start */

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .signup-box {
    width: 420px !important;
    float: right;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1437px) {
  .signup-box {
    width: 450px !important;
    float: right !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .row.color-boxes .col-lg-3 {
    padding: 5px 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .signin-stats .ss-icon-box {
    height: 28px !important;
    width: 28px !important;
    border-radius: 5px;
  }

  .signup-box {
    width: auto;
    float: unset;
  }
}

@media only screen and (min-width: 300px) and (max-width: 992px) {
  .signup-content {
    color: #fff;
    padding-top: 30px;
  }
  .search-head{
    min-width: 150px;
    max-width: 200px;
  }
  .signup-box {
    width: auto;
    float: unset;
  }

  .signup-stats {
    width: 100%;
    margin-top: 30px;
  }

  .del-time-box {
    margin-bottom: 15px;
  }

  .signin-stats .col-lg-4.pr-0 {
    margin-bottom: 15px !important;
  }

  .signup-box {
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 50px;
    margin-top: 50px;
  }
  .have-acc-box {
    padding: 40px 40px !important;
  }

  .ub-list li {
    // width: 100% !important;
    display: inline-block;
    margin-right: 30px !important;
  }
}

// 16June by dangi
.unset-float {
  float: unset !important;
}

.newul-css li {
  display: inline-block !important;
  width: 50% !important;
  padding-right: 15px;
}
impo .consolure-filter .react-datepicker-wrapper {
  width: 100% !important;
}
.newul-css .react-datepicker-wrapper {
  width: 100% !important;
}

/* Signin css Start */

.forgot-password {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #013469;
  float: right;
  margin-top: 10px;
  white-space: nowrap;
}

.lms-text {
  font-family: Proxima-Nova-Regular;
  padding-top: 10px;
  font-size: 18px;
  color: #ffffff;
  line-height: 0px;
  margin-bottom: 0px;
}

.signin-stats {
  width: 100%;
  margin-top: 55px !important;
}

.signin-stats .ss-icon-box {
  height: 36px;
  width: 36px;
}

.signin-stats .ss-icon-box img {
  height: 20px !important;
}

.signup-stats.signin-stats h5 {
  font-size: 16px !important;
}

.signup-stats.signin-stats small {
  font-size: 12px !important;
  position: relative;
  top: -5px;
}

.signin-owl-box {
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
  margin-top: 40px;
}

.u-image img {
  height: 44px;
  width: 44px !important;
  object-fit: cover;
  border-radius: 100px;
}

.signin-owl-box h1 {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  margin-top: 5px;
  color: #013469;
  letter-spacing: -0.25px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.signin-owl-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 10px;
  color: #013469;
  white-space: nowrap;
  letter-spacing: -0.17px;
}

.signin-owl-box h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.15px;
  line-height: 16px;
}

.signin-owl-box a {
  color: #114eae;
  // text-decoration: ;
  border-bottom: 1px solid #114eae;
}
.linkd-icon {
  height: 20px;
  width: 20px !important;
  margin-top: 4px;
  position: absolute;
  top: 0px;
  right: -5px;
}

.test-description {
  height: 50px;
  overflow: hidden;
}

a.read-more-text {
  position: absolute;
  bottom: -10px;
  left: 15px;
  font-size: 13px;
}
.width-40-percent {
  width: 40% !important;
}

/* Popup Css Start */

.payment-modal .modal-content {
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 16px;
}

.payment-modal .modal-title {
  font-family: Proxima-Nova-Regular;
  font-size: 20px;
  color: #f40820;
  letter-spacing: 0;
}

.payment-modal .modal-dialog {
  max-width: 710px !important;
}

.payment-modal .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.payment-modal .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.payment-modal .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #e4e4e4;
}

.payment-modal .slider::before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 0px;
  bottom: 0px;
  background-color: #33cf80;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.payment-modal input:checked + .slider {
  background-color: #eee;
  border: 1px solid #e4e4e4;
}

.payment-modal input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.payment-modal input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.payment-modal .slider.round {
  border-radius: 34px;
}

.payment-modal .slider.round:before {
  border-radius: 50%;
}

.discount-field p {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.25px;
  margin-bottom: 5px;
}

.payment-modal .input-group-text {
  color: #fff;
  background-color: #f40820;
  border: 1px solid #f40820;
}

.discount-field input {
  border-color: #e2e2e2;
  border-radius: 8px;
  width: 275px !important;
}

.payment-modal p {
  font-family: Proxima-Nova-Regular;
  font-size: 16px;
  color: #505050;
  letter-spacing: -0.33px;
  margin-bottom: 10px;
}
.payment-modal h4 {
  font-size: 26px;
  color: black;
  letter-spacing: -0.43px;
}
.order-cancel-date {
  font-size: 12px !important;
  color: #909090 !important;
  letter-spacing: -0.23px !important;
  float: right;
}

.payment-modal label {
  font-size: 14px;
  font-family: Proxima-Nova-Regular;
  color: black;
  letter-spacing: -0.23px;
}

@media (max-width: 991px) {
  .discount-field input {
    width: auto !important;
  }
}

.Installment-edit-box {
  background: #ebf9fe;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  margin-bottom: 15px;
}
.Installment-edit-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 13px;
  color: #555555;
  letter-spacing: -0.22px;
  padding-bottom: 5px;
}
.Installment-edit-box input {
  font-size: 14px !important;
  color: #003367 !important;
  letter-spacing: -0.27px !important;
  background: transparent;
  padding: 0px;
  margin-top: 3px;
  width: 93%;
  line-height: 0;
  border: unset;
  margin-left: 13px;
}
.edit-ins {
  position: absolute;
  right: 15px;
  top: 20px;
  height: 12px;
  width: 12px;
  display: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.Installment-edit-box:hover .edit-ins {
  display: block !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.arrow-redrict {
  position: absolute;
  right: 30px;
  top: 5px;
}

.arrow-redrict a {
  padding: 1px 5px !important;
  border-radius: 5px !important;
}

.arrow-redrict img {
  height: 10px !important;
  margin-top: -2px !important;
}

.dark-blue-box {
  margin: 0 auto;
  text-align: center;
  background: #f13c83;
  color: #fff;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.dark-blue-box h4 {
  margin-bottom: 0px;
  font-size: 18px;
}

.data-box.data-box-color h5 {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: black;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 0px;
  font-weight: 500;
}

.data-box.data-box-color {
  padding: 25px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 170px;
  cursor: pointer;
  height: 150px !important;
}
.row.color-boxes .col-lg-3 {
  width: 100% !important;
  flex: 0 0 18%;
  max-width: 100%;
}

.ub-list {
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
}

.ub-list .upcoming-class {
  height: auto !important;
  overflow: hidden;
  padding: 25px;
  background: #fff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
}

.ub-list li {
  width: 375px;
  display: inline-block;
  margin-right: 30px;
}

.ub-list .upcoming-class .row:last-child {
  padding-bottom: 20px;
  border-bottom: unset;
  padding-top: 15px;
}
.ub-list .upcoming-class h6 {
  white-space: normal !important;
}

.row.color-boxes .col-lg-3:nth-child(4n + 0) .dark-blue-box {
  background: #4ba8d5;
}

.row.color-boxes .col-lg-3:nth-child(4n + 1) .dark-blue-box {
  background: #f2dd51;
}

.row.color-boxes .col-lg-3:nth-child(4n + 2) .dark-blue-box {
  background: #80c095;
}

.row.color-boxes .col-lg-3:nth-child(4n + 3) .dark-blue-box {
  background: #e16652;
}

input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}
// 17 june

.upcoming-new-css .upcoming-class {
  height: auto !important;
  overflow-y: hidden !important;
  padding: 0px !important;
}
.upcoming-new-css .ub-list li {
  width: 276px;
  display: inline-block;
  background: #fff;
  margin-right: 15px !important;
  padding: 0px 20px !important;
}

.full-width .upcoming-new-css .ub-list li {
  width: 276px;
}

@media only screen and (min-width: 992px) and (max-width: 1180px) {
  .full-width .upcoming-new-css .ub-list li {
    width: 276px !important;
  }
}

.upcoming-new-css .upcoming-class .row:last-child {
  border-bottom: unset !important;
}

//june 18
.newicon-css {
  height: 23px !important;
  top: 9px !important;
  transition: all 0s ease !important;
}
.h-100vh {
  height: 100vh;
}
.signup-form .form-group {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}
/* Partially billed section csss */
.table-bg-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: black;
  letter-spacing: -0.23px;
  margin-bottom: 5px;
}
.total-box-netpay {
  background: #fcfcfc !important;
}
.table-bg-box .total-box-netpay h3 {
  font-family: Proxima-Nova-Bold;
  color: #003367 !important;
  white-space: nowrap;
}
.badge-custom-alt {
  font-size: 12px;
  color: #49c7f3;
  letter-spacing: -0.2px;
  background: #f5fafb;
  padding: 5px 10px;
}
.gst-value span {
  font-size: 12px;
  white-space: nowrap;
}
.btn-list-horz ul {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}
.btn-list-horz ul li {
  display: inline-block;
  padding-right: 15px;
}
.btn-list-horz .cancel-order {
  position: relative;
  top: 19px;
}
.badge-orange {
  background: #ff9931;
}
.etb-box {
  background: #f5f9fb;
  padding: 15px;
  border-radius: 8px;
}
.etb-box h6 {
  margin-bottom: 0px;
  font-family: Proxima-Nova-Regular;
  font-size: 16px;
  color: black;
  letter-spacing: -0.3px;
}
.etb-box h5 {
  float: right;
}
.value-text {
  white-space: nowrap;
}

.form-modal-div input{ 
  font-size: 14px;
}
.gender-radiobar .radiowrapper {
  font-size: 14px !important;
}
.form-modal-div .form-control {
  font-size: 14px;
}
.radiocustom {
  height: 43px !important;
}
.history-tab-table-wrapper .table-striped tbody td {
  vertical-align: middle;
}

@media (max-width: 991px) {
  .due-date-icon img {
    right: -20px !important;
  }
  .signup-bg-image {
    padding: 50px 0px !important;
  }
}

.ub-cls-name {
  font-family: Proxima-Nova-Bold;
  font-size: 18px !important;
  color: #003367 !important;
  letter-spacing: -0.3px;
  margin-bottom: 5px;
  text-transform: capitalize;
  white-space: break-spaces;
}

.ub-list .upcoming-class h6 {
  white-space: normal !important;
  color: #7d7d7d;
  display: block;
  font-size: 14px;
}

.upcoming-class span {
  color: #909090;
  font-size: 14px;
  margin-bottom: 0;
}

ul.ub-list .icon-box img {
  height: 20px;
}

.pre-cls-name {
  font-family: Proxima-Nova-Bold;
  font-size: 16px !important;
  color: #003367 !important;
  letter-spacing: -0.23px;
  margin-bottom: 5px;
}

.btch-name {
  font-size: 14px;
  margin-bottom: 5px;
  font-family: Proxima-Nova-Bold !important;
  color: #7d7d7d !important;
  margin-top: -5px;
  white-space: normal;
}

.signup-bg-100 {
  height: 100% !important;
  display: block !important;
}

// responsve css 18 june

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .container {
    max-width: 1200px !important;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1440px) {
  .container {
    max-width: 1360px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1140px) {
  .signup-bg-image {
    height: 100% !important;
    display: block !important;
  }
}

@media only screen and (min-width: 575px) and (max-width: 1023px) {
  .video-listing-single-row .row .col-sm-4:nth-child(-n + 3) {
    display: block !important;
  }
  .video-listing-single-row .row .col-lg-3:nth-child(-n + 4) {
    display: none;
  }
}

@media (max-width: 768px) {
  header{
    padding-top: 0;
  }

  .download-filter-box {
    right: 0;
    margin-left: -280px;
  }
  .signup-bg-image {
    height: 100% !important;
  }
  .total-box-netpay {
    margin-bottom: 15px;
  }
  .logout-wrapper {
    margin-top: 6px;
  }

  .logo-timer {
    padding-bottom: 6px;
  }
}
html {
  --lh: 1.4rem;
  line-height: var(--lh);
}

.double-line-text {
  --max-lines: 2;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 992px) {
}

/*Profile*/

.profile-img img {
  width: 150px;
  height: 150px;
  box-shadow: 0px 0px 0px;
}
.change-profile {
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
  margin-left: 15px;
}
.change-profile li {
  list-style: circle;
  float: left;
}
.change-profile li a {
  color: #757575;
  font-size: 15px;
}
.change-profile li.rmv {
  list-style: disc;
  margin-left: 25px;
  color: #757575;
}
.change-profile li.rmv a {
  color: black;
  margin-left: -5px;
}
.change-profile li.chng {
  list-style: none;
}
.change-profile li.chng a {
}
.phone-input {
  width: calc(100% - 60px) !important;
}
.profile-btns.video-modal-head {
  border-bottom: 0px;
}
.profile-btns.video-modal-head ul li button {
  margin-left: 0px;
  margin-top: 20px;
}
.profile-tab {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  list-style: none;
}
.profile-tab li {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: #a2a2a2;
  letter-spacing: -0.33px;
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
}
.profile-tab li.active {
  color: #193367;
  border-bottom: 2px solid #193367;
  padding-bottom: 5px;
}
.profile-tab li:hover {
  color: black;
}
.profile-left {
  width: 240px;
  float: left;
}
.profile-right {
  width: calc(100% - 240px);
  float: left;
}
.profile-wrapper-student-profile {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 9.5px;
  width: 100%;
  float: left;
  padding: 20px 5%;
  min-height: 70vh;
}
.profile-wrapper2 {
  width: 83%;
}
.profile-wrapper3 {
  width: 100%;
}
@media (max-width: 767px) {
  .profile-left {
    width: 100%;
    float: left;
  }
  .have-acc-box h5 {
    white-space: break-spaces;
  }
  .profile-right {
    width: 100%;
    margin-top: 20px;
    float: left;
  }
  .profile-wrapper-student-profile {
    height: 100%;
  }
}

.order-table {
  .custom-checkbox {
    float: inherit !important;
    padding-left: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .order-table {
    .due-date-icon {
      top: -2px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .data-box.data-box-color {
    padding: 15px !important;
    width: 170px;
  }
  .row.color-boxes .col-lg-3 {
    padding-right: 0px !important;
    padding-left: 10px;
  }
  .row.color-boxes .col-lg-3 {
    width: 100% !important;
    flex: 0 0 50%;
    max-width: 100%;
  }
}

.profile-icon {
  float: right;
}

/* active payment box */
.active-payments {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 20px;
  margin-top: 15px;
}
.active-payments p {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: black;
  letter-spacing: -0.25px;
  margin-bottom: 10px;
}
.active-payments span {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  color: black;
  letter-spacing: -0.25px;
  white-space: nowrap;
}
.payment-active p {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  color: #f40820;
  letter-spacing: -0.3px;
}
.payment-active span {
  font-family: Proxima-Nova-Bold;
  font-size: 15px;
  color: #f40820;
  letter-spacing: -0.3px;
}

.bullet-point::before {
  content: "";
  background: #193367;
  height: 6px;
  width: 6px;
  position: absolute;
  margin-top: 7px;
  border-radius: 35px;
  margin-left: -12px;
}

.bullet-point {
  padding-left: 10px;
}

.add-inst-btn {
  background: transparent !important;
  border-radius: 20px !important;
  color: #51b5f3 !important;
  // padding: 0px 8px !important;
  // position: absolute !important;
  font-size: 12px !important;
  // left: 45px !important;
  // margin-top: -43px !important;
}

.add-inst-btn:hover {
  color: #003366 !important;
}

span.status-text {
  font-size: 13px;
  color: black;
  letter-spacing: -0.23px;
}

.tb-box-bg {
  background: #fbfbfb;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  td,
  th {
    border-top: unset !important;
  }
}

.Installment-edit-box span {
  font-family: Proxima-Nova-Bold;
  font-size: 16px !important;
  color: #003367 !important;
  letter-spacing: -0.27px !important;
}

.rupe-icon {
  position: absolute;
  margin-top: 7px;
}
.mt-minus-8 {
  margin-top: -8px;
}

.installment-free {
  background: #fbfbfb;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}
.bg-white {
  background: #fff;
}

// june 19 By dangi
.transformtext {
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .sidebar-right {
    width: 300px;
    transition: all ease 0.3s;
    right: -400px;
  }
  .sidebar-right.showme {
    width: 300px;
    transition: all ease 0.3s;
    right: 00px;
    display: block;
  }
}

@media (max-width: 520px) {
  .sidebar-right {
    width: 100%;
    transition: all ease 0.3s;
    right: -110%;
  }
  .sidebar-right.showme {
    width: 100%;
    transition: all ease 0.3s;
    right: 00px;
    display: block;
  }
  .dash-icon-right img {
    display: block;
  }

  .fv-content {
    width: 46%;
    margin-right: 10px;
  }
}
.datecss-added {
  font-size: 12px;
  color: #224e7b !important;
}

.up-cls-updated {
  .icon-box {
    height: 38px;
    width: 38px;
  }
  & h6 {
    font-size: 14px !important;
  }
}

// june 22
.err-class {
  background: #eef6fc;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  color: #bf0404;
  position: relative;
}
p.err-class::before {
  content: "";
  background: #003366;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
p.err-class span {
  color: #003366 !important;
  border-bottom-color: #003366 !important;
}
.nxtlinewrap {
  white-space: break-spaces;
}
.footer-fixed-btm {
  width: 77% !important;
  position: fixed;
  align-items: center;
  height: 30px;
  bottom: 15px;
  text-align: center;
  padding: 20px;
}

.text-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.form-control:focus {
  border-color: unset !important;
  outline: 0 !important;
  box-shadow: unset !important;
}

.backbtn.close-btn-nt {
  background: transparent;
  border-radius: 0;
  padding: 0;
  text-align: left;
  transform: unset;
  box-shadow: unset;
}

.backbtn.close-btn-nt img {
  height: 20px !important;
  cursor: pointer;
  position: relative;
  top: -8px;
  left: -5px;
}

.innoti img {
  height: 20px;
  position: relative;
  top: -5px;
}
span.nav-label {
  // font-family: Proxima-Nova-Bold;
  // font-size: 14px;
  color: #193367;
  letter-spacing: -0.23px;
}

.nav-label.nav-label-divide {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #a3a3a3;
  letter-spacing: -0.17px;
}
.set-wd {
  width: 28px;
}

.noti_class {
  filter: brightness(0) invert(1);
}

@media only screen and (min-width: 300px) and (max-width: 359px) {
  .row.color-boxes .col-lg-3 {
    padding-left: 15px;
    margin: 0 auto;
  }
  .row.color-boxes .col-lg-3 {
    flex: 0 0 65%;
  }
  .thumb-box img {
    height: 60px !important;
    width: 100% !important;
  }
  .signup-box {
    padding: 30px 30px;
  }
  .cst-cases-wrapper .case-div {
    float: unset;
    margin-bottom: 15px !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 360px) and (max-width: 370px) {
  .row.color-boxes .col-lg-3 {
    padding-left: 5px !important;
  }
}
@media only screen and (min-width: 300px) and (max-width: 610px) {
  .row.color-boxes .col-lg-3 {
    padding-left: 8px;
  }
  .video-name-mobile-hide {
    display: none;
  }

  .vsih-mobile {
    display: block !important;
  }
  .vsih-mobile .nav-link {
    padding: 0.5rem 10px !important;
  }
  .bookmark-search-f input {
    width: 100% !important;
  }

  .download-btn .mb-hide {
    display: none;
  }
  .download-btn {
    width: 25px !important;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
  }

  .download-btn img {
    margin-right: 2px !important;
  }

  @media only screen and (min-width: 410px) and (max-width: 430px) {
    .row.color-boxes .col-lg-3 {
      padding-left: 20px;
    }
  }
  .cst-cases-wrapper .case-div {
    float: unset;
    margin-bottom: 15px !important;
    margin: 0 auto;
  }
  .filter-box {
    right: -30px;
  }

  .case-lower .wrap-text {
    width: 150px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .row.color-boxes .col-lg-3 {
    width: 100% !important;
    flex: 0 0 50%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .signin-stats {
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1025px) and (orientation: landscape) {
  .have-acc-box {
    padding: 40px 40px;
  }
  .signup-stats.signin-stats small {
    white-space: nowrap;
  }
  .signup-stats.signin-stats h5 {
    font-size: 16px !important;
    white-space: nowrap;
  }
}

.calcu {
  display: flex;
  justify-content: space-between;
}

.mb-21 {
  margin-bottom: 20px;
}
.font-14 {
  font-size: 14px !important;
}

.tr-sub-menu-pd-left {
  padding-left: 25px !important;
}
.font_size {
  font-size: 14px !important;
}
.dis-all {
  display: block !important;
}

.custm-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);

  /*opacity: 0.6;*/
}

// bookmark section css start

.bookmark-search-f input {
  background: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 200px;
  height: 35px;
  font-size: 12px;
  color: #606060;
  letter-spacing: -0.2px;
}

.bookmark-search-f .search-icon-b {
  position: absolute;
  height: 14px;
  width: 14px;
  top: 10px;
  right: 12px;
}

.bookmark-tabs {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-top: 29px;
}

.bookmark-tabs li a {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #606060;
  letter-spacing: 0px;
}

.bookmark-tabs .b-tabs-icon {
  height: 14px;
  padding-right: 3px;
  margin-top: -3px;
  filter: grayscale(1) invert(1);
}

.bookmark-tabs .nav-link.active,
.nav-pills .show > .nav-link {
  color: #606060 !important;
  background-color: transparent !important;
}

.bookmark-tab-content h6 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
}

.bookmark-tab-content h6 img {
  height: 14px;
  padding-left: 5px;
}

.del-time-box {
  padding: 6px 10px;
  position: relative;
  margin-bottom: 0px;
  margin-top: 5px;
}

.del-time-box a {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
}

.del-time-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #606060;
  letter-spacing: -0.27px;
  margin-bottom: 0px;
}

.del-time-box .fa {
  background: #f40820;
  border-radius: 10px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.del-time-box ul {
  list-style: none;
  padding-left: 0px;
  text-align: right;
  margin-bottom: 0px;
}

.del-time-box ul li {
  display: inline-block;
  padding-left: 10px;
}

.bookmark-tab-content {
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.box-shadow-0 {
  box-shadow: unset !important;
}

.bookmark-feild input {
  background: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 35px;
  font-size: 12px;
  color: #606060;
  letter-spacing: -0.2px;
}

#pills-notes .demo-editor.rdw-editor-main {
  background: #fff;
  border: 1px solid #fafafa;
  margin-top: -5px;
  padding: 15px;
  height: 250px;
}
.btn-notes-save {
  background: #f40820;
  color: #fff;
  padding: 3px 12px;
  font-size: 12px;
  float: right;
  margin-top: -30px;
  border-radius: 15px;
}
.time-show {
  background: #000;
  width: 60px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: #fff !important;
  height: 20px;
  align-items: center;
  z-index: 99;
  margin-top: -40px !important;
  margin-right: 5px !important;
  position: relative;
  margin-bottom: 21px;
  float: right;
  opacity: 1 !important;
}
.time-show.vd-time-show {
  margin-top: -16px !important;
  margin-right: 0px !important;
  font-size: 9px !important;
  width: 30px !important;
  height: 13px !important;
  border-radius: 4px !important;
}
.nav-pills .nav-link.active {
  color: #f40820 !important;
}

.nav-pills .nav-link.active img {
  filter: unset !important;
}

.del-time-box .time-show {
  margin-top: 2px !important;
  margin-right: 0px !important;
  font-size: 14px !important;
  width: 65px !important;
  height: 18px !important;
  border-radius: 8px !important;
  float: left !important;
  background: #f40820 !important;
}

.del-time-box input {
  height: 25px;
  width: 75%;
  font-size: 13px;
}

.del-time-box .btn-notes-save {
  margin-top: 0px !important;
  position: relative;
  bottom: -9px;
}

.btn-notes-save:hover {
  color: #fff;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .del-time-box .fa {
    height: 20px;
    width: 20px;
    font-size: 13px;
  }
  .del-time-box ul li {
    padding-left: 5px;
  }
  .del-time-box ul {
    text-align: left !important;
  }
  .bookmark-tabs {
    padding-top: 0px !important;
    margin-left: -10px;
  }

  .del-time-box {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px !important;
  }

  .del-time-box input {
    width: 100%;
  }
}

// jUne 25
.custom-admin-margin {
  margin-top: -30px !important;
}
// June 26
.date_picker_width .react-datepicker-wrapper {
  width: 100% !important;
}
// June 27
.set_btn {
  margin-left: 80px;
  margin-top: 14px;
}

//june 29
.w-16 {
  width: 16%;
}
.remove_sch {
  background: transparent !important;
  color: #003366 !important;
  font-weight: bold;
  font-size: 14px;
}

//June 30
.modal-dialog.daiilog-height-400 {
  height: 400px !important;
}
.daiilog-height-400 .modal-content {
  height: 100%;
}
.daiilog-height-400 .css-11unzgr {
  max-height: 115px !important;
}

.infinite-scroll-component.pl-1.cust-mb {
  margin-top: -30px;
}

.filter-box.inner-filter-box.admin-filter {
  width: 400px;
}

.btn-heads-admin {
  width: 400px !important;
}

.bookmark-tabs li a .fa {
  font-size: 15.2px;
  color: #888;
}

.bookmark-tabs li a.active .fa {
  color: #f40820;
}

.product-slect-txt-box {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  margin-bottom: 15px;
}

.product-slect-txt-box .cros-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.sep-blue {
  color: #f40820;
}

i.fa.fa-times.del-icon-bookmark {
  float: right;
  padding-right: 15px;
  cursor: pointer;
}

.plus-bookmark-icon {
  color: #f40820;
  font-size: 14px;
  width: 77px;
}

.plus-bookmark-icon .fa {
  color: #f40820;
  padding-right: 5px;
}

.bottom-0 {
  bottom: 0px !important;
}
.btm-class {
  position: relative;
  top: -9px;
}
// .vid-margin {
// 	margin-right: -25px !important;
// }
p.time-show.vd-time-show.vid-margin {
  // margin-right: -25px !important;
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 40px !important;
}

.case-div-sm {
  width: 190px !important;
  height: 150px !important;
}

.case-div-sm .case-upper {
  height: 100px !important;
}

.case-div-sm .case-upper img {
  height: 85px;
}

.case-div-sm .case-lower {
  padding: 10px 10px !important;
}

.case-div-sm .case-lower .cl-1 span {
  margin-top: -3px;
  width: 105px !important;
}

@media only screen and (min-width: 359px) and (max-width: 610px) {
  .cst-cases-wrapper .case-div.case-div-sm {
    float: left !important;
    margin: unset !important;
    margin-right: 10px !important;
    width: 155px !important;
    margin-bottom: 10px !important;
  }

  .case-div-sm .case-lower .cl-1 span {
    width: 80px !important;
  }

  .case-div-sm .upload_file_box img {
    height: 14px;
  }

  .case-div-sm .case-lower .wrap-text {
    width: 85px !important;
  }
}

.comment-wrapper button {
  background: #f40820;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -5px;
}

.comment-wrapper button img {
  height: 14px !important;
  filter: brightness(0) invert(1);
}

.help-sidebar {
  padding-left: 25px;
  padding-bottom: 50px;
}

.help-sidebar p {
  font-size: 14px;
  color: #003366;
  font-family: Proxima-Nova-regular;
  margin-bottom: 0px;
}

.help-sidebar a {
  font-size: 15px;
  font-family: proxima-nova-bold;
  color: #f40820;
}

.help-sidebar a:last-child {
  position: relative;
  top: -2px;
}

.help-sidebar img {
  height: 35px;
  margin-top: 14px;
}

.filter-box .status-list {
  margin-top: 10px;
}

.history-tab-table-wrapper .table thead th {
  width: 15%;
}

.table thead th {
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .thumb-box img {
    height: 60px;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .thumb-box img {
    height: 60px;
    width: 100% !important;
  }
}

h6.thumb-video-text-single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// july 9
ul.notesbtn-list {
  list-style: none;
  padding-left: 0px;
  float: right;
  margin-bottom: 0px;
  margin-top: -30px;
}
ul.notesbtn-list li {
  display: inline-block;
  padding-left: 15px;
}
ul.notesbtn-list .fa {
  color: #003366;
}
.notesbtn-list .btn-notes-save {
  margin-top: 0px !important;
  float: unset !important;
}
.fac-role {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.fac-role li {
  display: inline-block;
}
ul.fac-role img {
  padding-left: 15px;
}
.upcoming-class .side-vid {
  width: 90px;
  position: relative;
}
//july 10 dangi
.dragndrop {
  border: 1px dashed;
  width: 100%;
  height: 70px;
}
.dragging {
  text-align: center;
  margin-top: 25px;
}
/* 10 july css start */
.py-calculation-boxes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.py-calculation-boxes h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: #555555;
  letter-spacing: -0.27px;
  white-space: nowrap;
}
.py-calculation-boxes h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #555555;
  letter-spacing: -0.23px;
}
.py-calculation-boxes h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #555555;
  letter-spacing: -0.2px;
}
.py-calculation-boxes em {
  font-family: Proxima-Nova-Regular;
}
.payment-details-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
}
.box-shadow-0 {
  box-shadow: unset !important;
}
.border-radius-0 {
  border-radius: 0px !important;
}
.py-installment-box {
  padding: 15px 30px;
}
.py-installment-box p {
  margin-bottom: 5px;
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.2px;
}
.py-installment-box ul {
  padding-left: 0px;
  list-style: none;
}
.py-installment-box ul li {
  padding-bottom: 5px;
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
}
.py-installment-box h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
}
.btn.pay-now-btn {
  background: #f40820;
  border-radius: 12.5px;
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: -0.2px;
  height: 22px;
  width: 85px;
  display: flex;
  align-items: center;
  margin-top: -1px;
  justify-content: center;
}
.badge-green {
  background: #80c095;
  color: #fff;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.badge-red {
  background: #e16652;
  color: #fff;
  font-size: 12px;
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.badge-grey {
  background: #d1d1d1;
  color: #222;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.colorful-btn-list li {
  display: inline-block;
  padding-right: 5px;
}
.w-auto {
  width: auto !important;
}
.py-installment-box em {
  font-size: 12px;
  color: #555555;
  letter-spacing: -0.2px;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .py-calculation-boxes {
    display: block !important;
    text-align: center;
  }
  .py-installment-box {
    text-align: center;
  }
  .btn.pay-now-btn {
    margin: 0 auto;
    margin-bottom: 10px !important;
  }
  .cancel-watermark {
    font-size: 45px;
  }
}

// 13 July Css Start

.cou-payment-tb-box {
  background: #fbfbfb;
  border-radius: 8px;
  border: 1px solid #dee2e6;
}

.cou-payment-tb-box th {
  font-size: 13px;
  padding: 10px;
}

.cou-payment-tb-box th {
  font-family: Proxima-Nova-Bold;
  color: #555555;
  letter-spacing: -0.23px;
  border-top: 0 !important;
  padding: 10px 15px !important;
}

.cou-payment-tb-box .table {
  margin-bottom: 0px;
}

.cou-payment-tb-box td {
  font-family: Proxima-Nova-Bold;
  color: #003366;
  border-top: 0 !important;
  letter-spacing: -0.23px;
  font-size: 13px;
  padding: 10px 15px !important;
  white-space: nowrap;
}

.cou-payment-tb-box td a {
  font-family: Proxima-Nova-Regular;
  color: #f40820;
  font-size: 13px;
  letter-spacing: -0.23px;
}

.edit-cancel-list {
  list-style: none;
  padding-left: 0px;
  text-align: right;
}
.edit-cancel-list li {
  display: inline-block;
  padding-left: 10px;
}
.edit-cancel-list .edit-btn {
  background: #f40820;
  font-size: 12px;
  color: #fff;
  border-radius: 15px;
  padding: 4px 8px;
  cursor: pointer;
}

.edit-cancel-list .cancel-btn {
  font-family: Proxima-Nova-Regular;
  background: transparent;
  font-size: 13px;
  color: #003366;
  cursor: pointer;
}

.cancel-watermark {
  font-family: Proxima-Nova-Regular;
  font-size: 72px;
  color: #eee;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 28%;
  transform: rotate(-25deg);
  text-transform: uppercase;
  z-index: 1;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.cou-payment-tb-box input {
  height: 30px !important;
  font-size: 12px !important;
}

.cou-payment-tb-box select {
  height: 30px !important;
  font-size: 12px;
  padding: 2px;
}

.inr-outstanding {
  font-size: 20px !important;
  margin-bottom: 4px;
}

.cou-payment-tb-box .react-datepicker-popper {
  left: -7.4% !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cou-payment-tb-box .react-datepicker-popper {
    left: -18% !important;
  }
  .cou-payment-tb-box .react-datepicker__triangle {
    left: 50% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .cou-payment-tb-box .react-datepicker-popper {
    left: -15% !important;
  }
  .cou-payment-tb-box .react-datepicker__triangle {
    left: 40% !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .cou-payment-tb-box .react-datepicker-popper {
    left: -50% !important;
  }
  .cou-payment-tb-box .react-datepicker__triangle {
    left: 80% !important;
  }
}

a.edit-pencil-icon img {
  background: transparent;
  height: 15px;
  width: auto;
  object-fit: contain;
  border-radius: unset;
  margin-top: -5px;
  margin-left: 5px;
}

.crdts-fonts {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: #003366;
  margin-bottom: 3px;
  margin-top: 3px;
}

.course-err {
  color: red;
  text-transform: uppercase;
  text-align: center;
}

//july15
.wrap-text-archive {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
.std-fltr {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  right: 0px;
  // position: fixed;
  // float: left;
  // width: 250px;
  // margin-top: -30px;
  // margin-left: -30px;
  // z-index: 99;
}

.archive-page-u .cl-new ul li {
  position: absolute;
  right: 15px;
}

.left-minus-five {
  position: relative;
  left: -5px;
}

.content-wrapper-dark-background {
  background-color: #f6f6f6;
}

.pri {
  font-size: 12px;
  margin-bottom: 5px;
  color: black;
}

ul.list-sidebar.bg-defoult {
  padding-bottom: 50px !important;
}
.upload_file_box .fa {
  color: #858585;
  font-size: 20px;
}

// button.react-datepicker__navigation.react-datepicker__navigation--next:focus {
//   outline: none;
//   border: solid #ccc;
//   border-width: 0 3px 3px 0px;
//   padding: 3px;
//   transform: rotate(-45deg);
// }

// button.react-datepicker__navigation.react-datepicker__navigation--next {
//   outline: none !important;
//   border: solid #ccc !important;
//   border-width: 0 3px 3px 0px !important;
//   padding: 3px !important;
//   transform: rotate(-45deg) !important;
//   position: relative !important;
//   left: -15px;
//   top: 15px;
// }

// button.react-datepicker__navigation.react-datepicker__navigation--previous {
//   outline: none !important;
//   border: solid #ccc !important;
//   border-width: 0 3px 3px 0px !important;
//   padding: 3px !important;
//   transform: rotate(-225deg) !important;
//   top: 15px;
// }
// .react-datepicker__header:first-child {
//   width: 104% !important;
// }

// button.react-datepicker__navigation.react-datepicker__navigation--previous:focus {
//   outline: none !important;
//   border: solid #ccc !important;
//   border-width: 0 3px 3px 0px !important;
//   padding: 3px !important;
//   transform: rotate(-225deg) !important;
// }

.newfonts {
  font-size: 18px !important;
}
a.gotocls {
  color: #f40820 !important;
  padding-bottom: 2.5px;
  cursor: pointer;
  border-bottom: 1px solid #f40820;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 15px !important;
}

.h-45px {
  height: 45px !important;
}

.serach-pos-mng {
  width: 75%;
  margin-left: auto;
}
.txt-serch-front {
  width: 200px;
  background: #fff;
  position: relative;
  height: 45px;
  align-items: center;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px !important;
}

.listing-inside-serch {
  margin-top: 0px;
  background: #fff;
  border: 1px solid #f3f3f3 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  height: 45px !important;
  display: flex !important;
  align-items: center;
  width: 200px !important;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px !important;
}

form.in-search-box {
  margin-bottom: 30px;
}

form.in-search-box img.search-icn {
  position: absolute;
  top: 10px;
  right: 30px;
}
.in-checkbox-after .custom-control-label::before {
  top: 8px !important;
}
.in-checkbox-after .custom-control-label::after {
  top: 8px !important;
}

.show-search .scroll-dev1 {
  position: absolute;
  margin-left: 0px;
  width: 350px;
}
.scroll-dev1 .custom-control-label::before {
  top: 10px;
}
.scroll-dev1 .custom-control-label::after {
  top: 10px;
}
.justify-content-flex-end {
  justify-content: flex-end;
}

.vw-50 {
  width: 50vw !important;
}

.txt-serch-front::after {
  content: "\f105";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  font-size: 20px;
  transition: all ease 0.2s;
  transform: rotate(90deg);
  top: 2px;
  color: #003366;
  right: 5px;
  background: #fff;
  height: 45px;
  padding: 12px 10px;
}

.listing-inside-serch::after {
  content: "\f105";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  font-size: 20px;
  transition: all ease 0.2s;
  transform: rotate(90deg);
  top: 0px;
  color: #003366;
  right: 10px;
  right: 5px;
  background: #fff;
  height: 45px;
  padding: 12px 10px;
}

.modal-dialog.btch-notifications-popup .demo-wrapper.rdw-editor-wrapper {
  border: 1px solid #eee;
  height: 300px;
  overflow: hidden;
}

@media (max-width: 1279px) {
  .listing-inside-serch {
    width: 150px !important;
  }
  .txt-serch-front {
    width: 150px !important;
  }
}
.err-red-class {
  color: red;
}

// 30 July New Dashboard

.nd-course-heading {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: black;
  letter-spacing: 0;
}

.nd-progress-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 18px;
  padding: 35px;
  margin-top: 15px;
  overflow: hidden;
}

.nd-progress-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 22px;
  color: black;
  letter-spacing: 0;
  line-height: 23px;
}

.nd-progress-box p {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 5px;
}

.nd-progress-box .progress {
  height: 12px;
  border-radius: 11px;
}

.nd-progress-box .progress-bar {
  background: #f40820;
  border-radius: 11px;
}

.nd-progress-box h6 {
  margin-bottom: 0px;
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: 0;
}

.nd-progress-box p span {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0;
}

.btn-nd-join {
  background: #f40820;
  border-radius: 18px;
  color: #fff;
  width: 100px;
  height: 36px;
  display: flex;
  justify-content: center;
  float: right;
}

.btn-nd-join:hover {
  color: #fff;
}

.w-45 {
  width: 45%;
}

.w-82 {
  width: 82%;
}

.min-height-260 {
  min-height: 280px;
}

.font-w-bold {
  font-family: Proxima-Nova-bold;
  letter-spacing: 0.1px;
}

.course-report-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 20px;
}

.rc-box-icon {
  border-radius: 10px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rc-video {
  background: #e16652;
}

.rc-imp {
  background: #80c095;
}

.rc-view {
  background: #f2dd51;
}

.rc-complete {
  background: #4ba8d5;
}

.rc-box-icon img {
  height: 18px;
}

.course-report-box h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: black;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.course-report-box h4 {
  font-family: Proxima-Nova-Bold;
  font-size: 22px;
  color: #f40820;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.row.color-boxes .col-lg-2:nth-child(4n + 0) .dark-blue-box {
  background: #4ba8d5;
}

.row.color-boxes .col-lg-2:nth-child(4n + 1) .dark-blue-box {
  background: #f2dd51;
}

.row.color-boxes .col-lg-2:nth-child(4n + 2) .dark-blue-box {
  background: #80c095;
}

.row.color-boxes .col-lg-2:nth-child(4n + 3) .dark-blue-box {
  background: #e16652;
}

.calendar-tabs-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 16px;
  padding: 30px;
}

.cal-date {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 211px;
  display: flex;
  align-items: center;
  height: 40px;
  float: left;
  justify-content: center;
}

.cal-date .fa {
  color: #c3c3c3;
  font-size: 18px;
  cursor: pointer;
}

.cal-date span {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: black;
  letter-spacing: 0;
  text-transform: uppercase;
}

.weekly-monthlt-tabs {
  float: right;
}

.weekly-monthlt-tabs .nav li img {
  height: 15px;
  margin-right: 10px;
  margin-top: -4px;
}

.weekly-monthlt-tabs .nav {
  background: #f6f7f9;
  border-radius: 4px;
}

.weekly-monthlt-tabs .nav a {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #888;
  letter-spacing: 0;
  height: 32px;
  display: flex;
  align-items: center;
}

.weekly-monthlt-tabs .nav-pills .nav-link.active img {
  filter: brightness(0) invert(1) !important;
}

.weekly-monthlt-tabs .nav-pills .nav-link.active {
  color: #fff !important;
  background: #f40820;
}

.day-date-box h5 {
  font-family: Proxima-Nova-bold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.day-date-box {
  padding: 25px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.day-date-box:hover {
  background: #f5f8ff;
  border-radius: 10px;
}

.day-date-box:hover h5 {
  color: #f40820;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.day-active::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #80c095;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 15px;
}

.test-cal-icon-box {
  background: #e3f7fd;
  border-radius: 8px;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-cal-icon-box img {
  height: 18px;
}

.test-date-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  padding: 25px;
}

.test-date-box h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
  margin-bottom: 0px;
}

.test-date-box p {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #606060;
  letter-spacing: -0.2px;
}

.test-date-box h6 {
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #555555;
  letter-spacing: -0.25px;
  margin-bottom: 15px;
}

.test-date-box span {
  font-size: 12px;
  border-radius: 14px;
  text-transform: uppercase;
  font-family: Proxima-Nova-Bold;
  padding: 8px 16px;
}

.nd-blog-box {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 8px;
  height: 100%;
}

.nd-blog-img img {
  max-width: 100%;
  border-radius: 0px;
  width: 100%;
  border-bottom: 1px solid #eee;
  object-fit: cover;
  object-position: top left;
  height: 150px;
}

@media only screen and (min-width: 1400px) and (max-width: 2000px) {
}

.demo-course-box-bg-white .min-height-260.new-demo-main {
  min-height: 220px !important;
  padding-top: 25px;
}

.nd-blog-box-content {
  padding: 20px;
  height: 200px;
  position: relative;
}

.nd-blog-box-content h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: black;
  letter-spacing: -0.27px;
}

.nd-blog-box-content p {
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #555555;
  letter-spacing: -0.25px;
  margin-bottom: 15px;
}

.nd-blog-box-content a {
  font-family: Proxima-Nova-bold;
  font-size: 12px;
  color: black;
  letter-spacing: 0.2px;
  line-height: 18px;
  position: absolute;
  bottom: 20px;
}

.course-report-box .row {
  width: 225px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .nd-blog-box-content {
    height: 225px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 992px) {
  .course-report-box {
    margin-bottom: 25px;
  }

  .test-date-box {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .test-cal-icon-box {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 550px) {
  .btn-nd-join {
    float: left;
  }

  .cal-date {
    float: unset;
    margin: 0 auto;
  }

  .weekly-monthlt-tabs {
    float: unset;
    margin: 0 auto;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .nd-blog-box {
    margin-bottom: 25px;
  }
  .notification-fb-bell img {
    right: 0px !important;
  }
}

.pf-greybg-heading {
  background: #f3f3f3;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.pf-greybg-heading p {
  color: #f40820;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: Proxima-Nova-bold;
  padding-left: 10px;
  letter-spacing: 0.2px;
  line-height: 30px;
}

.other-comment-head {
  color: #f40820 !important;
  font-size: 16px !important;
}

.dragndrop {
  border: 2.5px dashed #eee !important;
  width: 100% !important;
  height: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px !important;
  border-radius: 10px;
}

.dragging {
  text-align: center;
  margin-top: 25px;
  font-size: 16px;
  font-family: Proxima-Nova-bold;
  color: #aeaeae;
}

.dragging a {
  color: #003366;
}

.profile-wrapper-student-profile .form-modal-div .form-control {
  margin-bottom: 20px;
}
// July 30
.wrap-text-testimony {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
}

// 4 Aug Css

.monthly-cal-picker .react-datepicker__current-month {
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  color: #003366;
  font-size: 14px;
  font-family: Proxima-Nova-regular;
  text-transform: uppercase;
}

.monthly-cal-picker
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  font-weight: lighter;
  position: absolute;
  top: -37px !important;
}

.monthly-cal-picker
  .react-datepicker__navigation.react-datepicker__navigation--next {
  font-weight: lighter;
  position: absolute !important;
  top: -37px !important;
  left: 190px;
}

.monthly-cal-picker .react-datepicker__header {
  position: relative;
  top: -60px;
  width: 210px !important;
  background: #fff;
  border-bottom: 0px;
}

.monthly-cal-picker .react-datepicker__day-names {
  position: absolute;
  top: 90px;
  left: 8px;
  width: 69.5vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d8d8d8;
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 0px 25px;
  padding-bottom: 1px;
}

.monthly-cal-picker .react-datepicker {
  width: 100%;
  border: unset !important;
}

.monthly-cal-picker .react-datepicker__week {
  display: flex;
  width: 69.5vw;
  justify-content: space-between;
  padding: 5px 25px;
  font-family: Proxima-Nova-regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
}

.monthly-cal-picker .react-datepicker__month {
  margin-bottom: 5px;
  margin-top: 20px;
}

// 5 August Css Start

.uph-list {
  padding-left: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
}

.uph-list li {
  display: inline-block;
  padding: 0px 10px;
}

.uph-list li a {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: black;
  letter-spacing: 0;
  position: relative;
  cursor: default;
}

.uph-upcoming::before {
  content: "";
  background: #ffc107;
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 5px;
}

.uph-previous::before {
  content: "";
  background: #28a745;
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 5px;
}

.uph-holidays::before {
  content: "";
  background: #e34d42;
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 5px;
}

.w-87 {
  width: 87.5vw;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .uph-list {
    text-align: center;
  }

  .monthly-cal-picker .react-datepicker__header {
    position: relative;
    top: -25px;
    width: 100% !important;
  }
  .monthly-cal-picker
    .react-datepicker__navigation.react-datepicker__navigation--previous {
    top: 0px !important;
  }
  .monthly-cal-picker
    .react-datepicker__navigation.react-datepicker__navigation--next {
    top: 0px !important;
    left: auto;
  }
  .weekly-monthlt-tabs li {
    text-align: center !important;
    width: 100% !important;
  }
  .weekly-monthlt-tabs .nav a {
    display: block;
  }
  .weekly-monthlt-tabs {
    float: unset;
    margin-top: 15px;
  }
  .monthly-cal-picker {
    margin-top: 25px;
  }

  .monthly-cal-picker .react-datepicker__day-names {
    top: 65px;
  }
}

.fp-pas-modal .modal-content .landing-demo-account-wrapper {
  height: 50vh !important;
}

.fp-pas-modal .close-landing-popup a {
  bottom: -40% !important;
}

.fp-pas-modal .modal-body {
  margin-top: 0%;
}

.course-box-height {
  .css-11unzgr {
    height: 163px !important;
    max-height: 163px !important;
    border: 1px solid #f3f3f3;
    border-top: none;
  }
  .css-1jllj6i-control,
  .css-6y1x9t-control {
    margin: 0px;
  }
}

.custom_popup.fp-pas-modal.h-100vh {
  overflow: scroll;
}

// 7 august css

.ul-header-user-info {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  float: right;
  padding-right: 25px;
}

.ul-header-user-info li {
  display: inline-block;
  padding: 0px 25px;
}

.ul-header-user-info .dropdown {
  position: relative;
  top: 0;
}

.ul-header-user-info .dropdown a {
  color: #fff;
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
}

.ul-header-user-info .user-img-drp {
  height: 32px !important;
  filter: unset !important;
  width: 32px !important;
  object-fit: cover;
  border-radius: 25px;
  margin-right: 10px;
  margin-top: -2px;
}

.ul-header-user-info .icon-arrow-user-drp {
  height: 5px !important;
  margin-left: 5px;
  filter: brightness(0.5);
}
.ul-header-user-info .dropdown a span {
  color: #09101D;
}
.ul-header-user-info .dropdown-menu {
  top: 10px !important;
  left: -40px !important;
}

.ul-header-user-info .dropdown-menu .dropdown-item {
  color: #003366 !important;
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
  border-bottom: 1px solid #eee;
}

.ul-header-user-info .dropdown-menu .dropdown-item:last-child {
  border-bottom: unset;
}

.notification-fb-bell img {
  height: 24px;
  cursor: pointer;
  right: -15px;
  position: relative;
}

.notification-fb-bell {
  position: relative;
}

.notification-fb-bell p {
  position: absolute;
  font-family: Proxima-Nova-bold;
  font-size: 8px;
  color: #ffffff;
  letter-spacing: 0;
  background: #ff0000;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  top: -2px;
  right: -16px;
}

.notification-box-fb {
  position: absolute;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  max-width: 450px;
  width: 450px;
  right: 0;
  top: 40px;
  min-height: 400px;
  max-height: 100px;
  overflow-y: auto;
  height: 100%;
  box-shadow: 0 2px 0 0 #f2f1f1;
}

.nt-box-fb-close img {
  height: 15px;
  float: right;
  margin-top: 4px;
  cursor: pointer;
}

.notification-box-fb h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: black;
  letter-spacing: 0;
}

.date-nt-fb {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #f40820;
  letter-spacing: 0;
  padding-top: 10px;
  margin-bottom: 10px;
}

.nt-group-fb h6 {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: black;
  letter-spacing: -0.27px;
  margin-bottom: 0px;
}
.nt-group-fb span {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: #606060;
  letter-spacing: -0.2px;
  position: relative;
  top: -5px;
}

.nt-group-fb p {
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  margin-top: -5px;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.nt-group-fb {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.labelData {
  font-size: 12px;
  position: absolute;
  top: -23px;
  left: 5px;
}

.nt-group-fb:last-child {
  border-bottom: unset;
}

.notification-fb {
  position: relative;
}

@media only screen and (min-width: 300px) and (max-width: 992px) {
  .ul-header-user-info {
    padding-right: 0px;
  }

  .ul-header-user-info li {
    padding: 0px 10px;
  }
  .notification-box-fb {
    max-width: 450px;
    width: 310px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .notification-fb {
    position: unset;
  }
}

/* Akash 7 aug */

.chart-bottom {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  padding-bottom: 15px;
}
.nd-progress-box {
  padding-bottom: 0px;
}
.chart-bottom ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.chart-bottom ul li {
  font-family: Proxima-Nova-Bold;
  font-size: 12px;
  color: black;
  letter-spacing: 0;
  white-space: nowrap;
}

.chart-bottom ul li span.pink-cr {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #ff6799;
  display: inline-block;
  margin-right: 5px;
}
.chart-bottom ul li span.white-cr {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #fff;
  display: inline-block;
  margin-right: 5px;
}

.chart-bottom ul li span.blue-cr {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 8px;
  background: #21bcff;
  display: inline-block;
}
.chart-bottom h3 {
  font-family: Proxima-Nova-Regular;
  font-size: 16px;
  color: black;
  letter-spacing: 0;
}
.chart-bottom h3 span {
  font-family: Proxima-Nova-Bold;
  font-size: 32px;
  color: #f40820;
  letter-spacing: 0;
}
.pay-bc ul li span.pink-cr {
  background: #ffda83;
}
.pay-bc ul li span.blue-cr {
  background: #a4a0fc;
}
.chart-bottom.pay-bc h3 {
  text-align: right;
  font-family: Proxima-Nova-Regular;
  font-size: 12px;
  color: black;
  letter-spacing: 0;
  margin-bottom: 3px;
}
.chart-bottom.pay-bc h3 span {
  display: block;
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #f40820;
  letter-spacing: 0;
}
.min-height-260 {
  min-height: 280px;
  position: relative;
}
// .careousal-btn {
//     position: absolute;
//     right: 0px;
//     top: -65px;
//     bottom: initial;
// }
// .session-content {
//     position: absolute;
//     top: -35px;
//     right: 0px;
// }
.careousal-btn ul {
  width: 100%;
  display: flex;
}
.careousal-btn ul li {
  display: inline-block;
  padding: 0px;
}
.btn-careousal {
  box-shadow: 0px 0px 0px;
  padding: 0px 8px;
}
.careousal-btn button.owl-prev {
  border-radius: 10px 0px 0px 10px;
}
.careousal-btn button.owl-next {
  border-radius: 0px 10px 10px 0px;
}
.onetwo {
  font-family: Proxima-Nova-Bold;
  font-size: 16px;
  color: #f40820;
  padding-left: 5px;
  letter-spacing: 0;
}
.new-demo-main h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
}
.new-demo-main h6 span {
  font-family: Proxima-Nova-Bold;
}
.new-demo-main .main-demo-bottom {
  max-width: 400px;
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.main-demo-bottom h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: black;
  letter-spacing: 0;
  margin-right: 15px;
  margin-bottom: 0px;
}
.main-demo-bottom a {
  background: #f40820;
  border-radius: 18px;
  padding: 5px 10px;
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
}
.new-demo-main {
  padding-right: 250px;
  background: url("/img/demo_bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 390px;
}

.new-course {
  height: 130px;
  width: 100%;
  border-radius: 8px;
  background: #000a27 url("/img/demo-enroll-bg.png");
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.new-course .main-demo-bottom h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 32px;
  color: #ffffff;
  letter-spacing: 0;
  margin-right: 25px;
  margin-bottom: 0px;
}
.new-course .main-demo-bottom {
  width: 100%;
  display: flex;
  padding: 15px 50px;
}
.new-course .main-demo-bottom a {
  background: #f40820;
  border-radius: 25px;
  padding: 5px 20px;
  font-family: Proxima-Nova-Bold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  height: 48px;
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
}
.landing-demo-account-box.new-forgot {
  position: absolute;
  left: 39%;
}

.validity_batch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

// 10 August Css

.demo-course-box-bg-white {
  background: #ffffff;
  box-shadow: 0 2px 0 0 #f2f1f1;
  border-radius: 18px;
}

@media only screen and (min-width: 250px) and (max-width: 767px) {
  .new-demo-main {
    padding-right: 25px;
    background: #fff !important;
  }

  .new-course .main-demo-bottom {
    width: 100%;
    display: block;
    padding: 15px 15px;
  }

  .new-course .main-demo-bottom h5 {
    font-size: 25px;
  }

  .new-course .main-demo-bottom a {
    font-size: 14px;
    margin-top: 10px;
  }
  .new-course {
    height: 160px;
  }

  .new-demo-main .main-demo-bottom {
    display: block !important;
    margin-top: 60px;
  }
  .demo-course-box-bg-white .min-height-260.new-demo-main {
    min-height: 260px !important;
  }
}

.error-msg-nondash {
  margin-top: 15px;
  background: #dff6ff;
  width: 175px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.signup-form .landing-terms-service ul {
  height: 340px;
  overflow-y: auto;
}
@media only screen and (min-width: 250px) and (max-width: 368px) {
  .header-top .col-4,
  .col-8 {
    padding: 0px;
  }
}

.txt-small-color-feild {
  font-family: Proxima-Nova-Regular;
  font-size: 10px;
  margin-top: -5px;
  color: #353535;
  margin-bottom: 5px;
}

.Installment-edit-box .react-datepicker__header:first-child {
  width: 100% !important;
}

// .Installment-edit-box
//   button.react-datepicker__navigation.react-datepicker__navigation--next {
//   left: unset !important;
//   right: -92% !important;
// }

.Installment-edit-box .react-datepicker__month-container {
  float: left;
  margin-top: -25px !important;
}

.Installment-edit-box .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 8px !important;
}

.Installment-edit-box .react-datepicker {
  left: -90px;
}

.react-datepicker__triangle {
  position: absolute;
  left: 115px !important;
}

.monthly-cal-picker {
  padding-bottom: 0px;
}

.error-msg-nondash-ttl-vdo {
  position: absolute;
  bottom: 20px;
}

.none-demo-ttl-desc {
  font-family: Proxima-Nova-Regular !important;
}

@media only screen and (min-width: 250px) and (max-width: 1100px) {
  .nd-blog-box-content h5 {
    height: auto !important;
  }
  .nd-blog-box-content p {
    height: auto !important;
  }
}

.chart-set-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cp-itlaic {
  font-family: Proxima-Nova-Regular !important;
  font-size: 13px !important;
  font-style: italic !important;
  padding-top: 5px;
  color: #000 !important;
}

.validity-date-vl {
  font-family: Proxima-Nova-bold;
  font-size: 13px;
  font-style: italic;
  padding-top: 5px;
  color: black;
  background: #89d9f4;
  padding: 5px 10px;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  float: right;
}

.cst-cases-wrapper .upl-cv-div {
  width: 210px !important;
  height: 160px !important;
}

.colorful-btn-list li .active {
  border: 0.5px solid #222;
}

@media only screen and (min-width: 250px) and (max-width: 992px) {
  .nd-course-heading {
    font-size: 20px;
  }

  .monthly-cal-picker {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .monthly-cal-picker .react-datepicker__header {
    top: -110px;
  }
  .monthly-cal-picker
    .react-datepicker__navigation.react-datepicker__navigation--previous {
    top: -85px !important;
  }
  .monthly-cal-picker
    .react-datepicker__navigation.react-datepicker__navigation--next {
    top: -85px !important;
  }

  .monthly-cal-picker .react-datepicker__day-names {
    position: absolute;
    top: 125px;
  }

  .weekly-monthlt-tabs {
    float: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
}

.monthly-cal-picker {
  width: 100%;
  display: flex;
  justify-content: center;
}

.weekly-monthlt-tabs #pills-tab {
  opacity: 0;
}

/* 17 August css  */

.error-msg-page {
  height: 63vh !important;
  display: flex !important;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
}
.error-msg-page img {
  text-align: center;
  margin: 0 auto;
  display: block;
  max-width: 100%;
  object-fit: cover;
}

.error-msg-page h6 {
  font-family: Proxima-Nova-Bold;
  font-size: 28px;
  color: black;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding-top: 25px;
  margin-bottom: 5px;
  text-align: center;
}

.error-msg-page a {
  color: #f40820;
  font-size: 14px;
  text-align: center;
  display: block;
  letter-spacing: 0.2px;
}

.error-msg-page p {
  font-size: 12px;
  text-align: center;
  display: block;
  padding-top: 2px;
  color: #003366;
}
.landing-hover-show-profile.review-box-landing h5 {
  width: 100% !important;
  white-space: break-spaces !important;
  overflow: unset !important;
}
// 21 august css

.react-datepicker-popper {
  z-index: 99999 !important;
}
.form-group.search-field.mb-1 .search-icn {
  position: absolute;
  top: 13px;
  right: 30px;
  height: 20px;
}

.infinite-scroll-component {
  overflow: auto hidden !important;
}

.change-profile li a {
  color: #757575;
  font-size: 15px;
  position: relative;
}
.profile-pic-css {
  position: absolute;
  width: 55px;
  height: 20px;
  top: -2px;
  opacity: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .nd-progress-box h6 {
    white-space: nowrap !important;
  }
  .nd-progress-box h5 {
    line-height: 23px !important;
  }
}

.landing-hover-show-profile.review-box-landing {
  max-height: 75vh;
  overflow-y: scroll;
}
.landing-hover-show-profile.review-box-landing::-webkit-scrollbar {
  width: 1px !important;
}

.dl-ul-list ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  position: relative;
}

.dl-ul-list ul li {
  display: inline-block;
  margin-left: 10px;
  position: absolute;
  width: 100px;
  float: left;
  left: 0;
  top: -20px;
}
.dl-ul-list ul li button {
  width: 40px !important;
  background: #38b5f3;
  color: #fff;
  border-radius: 28px !important;
  height: 40px !important;
}

.dl-ul-list {
  width: 130px;
}

.dl-ul-list .upload-video-file .fake-btn {
  width: 40px !important;
  margin-left: 5px !important;
}

.dl-ul-list .upload-video-file .fake-btn input {
  top: 0px !important;
  width: 50px;
  left: auto !important;
  right: -10px;
}

.dl-ul-list .upload-video-file {
  width: 50px;
  left: 50px !important;
}
.right-flex-header.w-85 {
  width: 85% !important;
}
.change-wid {
  width: 275px !important;
}
.class_join_btn {
  justify-content: space-between;
}

button.btn.btn-join {
  background: #38b5f3;
  color: #fff;
  height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  border-radius: 14px;
  font-family: Proxima-Nova-Bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}
.table-less-size td {
  font-size: 14px;
  padding: 0px 10px !important;
}
.table-less-size th:nth-child(3) {
  width: 15% !important;
}
.table-less-size tbody td b {
  white-space: nowrap;
  font-weight: 500;
}
.last-col-padding-0 {
  width: 101.4% !important;
}
.last-col-padding-0 .col-lg-4:last-child {
  padding-right: 0px;
}
.ny-choose-input {
  opacity: 0;
  position: absolute;
  top: 0px !important;
  cursor: pointer;
  left: -25px !important;
  width: 125px !important;
  height: 45px !important;
  display: block;
  clear: both;
}

ul.join-download-user-dashboard {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

ul.join-download-user-dashboard li {
  display: inline-block;
  margin-left: 15px;
}

.download-upcoming-class {
  height: 18px;
  float: right;
  cursor: pointer;
  position: relative;
  top: 6px;
  margin-top: 0px !important;
}

.size-limit {
	color: #45b5f3 !important;
    margin: 0px 5px;
    margin-bottom: 5px;
}

.ibm-set-class {
	background: #000;
	img {
		object-fit: contain;
		margin-bottom: 0px;
	}	
}		
.case-study-upload-download-student {
  li {
    img {
      height: 18px;
      margin-top: -4px;
    }
  }
  label {
    cursor: pointer;
  }
}

.list-with-small {
  .table thead th {
    width: auto;
  }
}
.order-err {
  float: right;
  margin-left: auto;
  margin-right: 20px;
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.case-study-note-zipfile {
  font-size: 14px;
  color: #45b5f3;
  float: right;
  padding-top: 10px;
  margin-bottom: 0px;
  right: 0;
  text-transform: uppercase;
  span {
    font-family: Proxima-Nova-Bold;
  }
}



.upload-file-admin-btn {
  background: #f40820 !important;
  color: #fff !important;
  border-radius: 36px !important;
  border: 0px !important;
  height: 40px;
  width: 40px;
  float: right;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.hor-scroll-hide .infinite-scroll-component {
  overflow-x: hidden !important;
}

.delete-comment {
  color: #003367 !important;
  margin-left: 283px;
  cursor: pointer;
}

.reply-comment .delete-comment {
  margin-left: 230px;
}

.redirect-student {
  font-family: Proxima-Nova-Bold;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
}

.auth-cutsom-button {
   background: white !important;
  color: #f40820 !important;
  border: 1px solid #f40820 !important;
}

.order-inactive-border {
  border-radius: 8px 8px 0 0 !important;
}

.red-order {
  border: 1px solid red;
}

.image-student-list {
  height: 30px;
  width:  30px;
  object-fit: cover;
}

// 30 march css 

.payment-dt-mod .py-calculation-boxes .fa {
  color: #f40820;
  margin-top: 10px;
}

.payment-dt-mod .py-calculation-boxes h6 {
  font-size: 12px;
}

.payment-dt-mod .py-calculation-boxes h3 {
  color: #053367;
  letter-spacing: -0.23px;
}

.payment-dt-mod .py-calculation-boxes h5 {
  font-size: 12px;
  white-space: nowrap;
}

td.ul-list-edit {
  position: relative;
  top: 10px;
}
.react-switch-bg {
  height: 23px !important;
}
.react-switch-handle {
  height: 21px !important;
  width: 20px !important;
}
@media only screen and (min-width: 550px) and (max-width: 1092px) { 

  .payment-dt-mod .py-calculation-boxes {
    display: block;
  }

  .payment-dt-mod-box {
        display: inline-block;
        margin-right: 15px;
    }

    .payment-dt-mod-sign {
      display: inline-block;
      width: 50px;
    }
}
.admin-cancel-order {
  color: #e3e3e3;
  opacity: 0.7;
}

.checkbox-list-wrp-dm {
  background-color: #ffffff4d;
  box-shadow: 0px 2px 25px #c6c6c694;
  padding: 15px;
  min-height: 110px;
  border-radius: 0px 0px 10px 10px;
}

.block-student-download {
  font-size: 20px;
  cursor: pointer;
}

.center_h {
  height: 34px;
}
.admin-dashboard-to-picker .react-datepicker-popper{
  left: -56px !important;
}
.workoutplane-tab-content .weakdaysbar {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    margin-top: 20px;
}
.workoutplane-tab-content .weakdaysbar li {
    width: 72px;
    height: 105px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.workoutplane-tab-content .weakdaysbar li h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 12px;
}
.workoutplane-tab-content .weakdaysbar li p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #818181;
}
.workoutplane-tab-content .weakdaysbar li.active {
  background: #f40820;
  border-color: #f40820;
}
.workoutplane-tab-content .weakdaysbar li.active h6 , .workoutplane-tab-content .weakdaysbar li.active p {
  color: #ffffff;
}
.workoutplane-head .lefthead h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.workoutplane-head .lefthead p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #818181;
}
.workoutplane-head .Rythead {
    text-align: right;
}
.btn-overall {
    background: #f40820;
    border-radius: 29px;
    padding: 20px 50px;
    border: 1px solid #f40820;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    transition: .5s ease;
}
.btn-overall:hover {
    background: #ffffff;
    color: #f40820;
}
.assign-exercise .assignleft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.assign-exercise .assignleft h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 0;
    margin-right: 20px;
}
.assign-exercise .assignleft .form-group {
    margin: 0;
    width: 80%;
}
.assign-exercise .assignleft .form-group .form-control {
    height: auto;
    padding: 13px 23px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000 !important;
}
.assign-exercise .assignryt ul.assign-btnbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin-bottom: 0;
}
.assign-exercise .assignryt ul.assign-btnbar li {
    margin-left: 30px;
}
.workoutplane-head {
    margin-bottom: 30px;
}
.assign-exercise .assignryt ul.assign-btnbar li button {
    padding: 11px 27px;
    border-radius: 50px;
}
.assign-exercise .assignryt ul.assign-btnbar li button.btn-transparent {
  background: transparent;
  color: #f40820;
  border: 1px solid #f40820;
}
.assign-exercise .assignryt ul.assign-btnbar li svg {
  font-size: 30px;
  color: #3cb4f4;
  cursor: pointer;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-toggle {
  padding: 0;
  background: transparent;
  border: none;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ADADAD;
  text-align: center;
  transform: translate(0px, 0px) !important;
  top: inherit !important;
  bottom: 40px !important;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-menu ul {
    padding: 0;
    list-style: none;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-menu ul li {
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.assign-exercise .assignryt ul.assign-btnbar li .dropdown .dropdown-menu ul li input[type="checkbox"] {
    width: 21px;
    height: 21px;
    border: 1px solid #818181;
    margin-right: 10px;
    border-radius: 3px;
}
.config-bar {
  padding: 20px 30px;
}
.config-bar h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 32.3534px;
  line-height: 39px;
  color: black;
  margin-bottom: 40px;
}
.config-bar .set-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
}
.config-bar .set-title h6 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 50px;
}
.config-bar .set-title .form-group {
  margin: 0;
  flex: 0 0 82%;
}
.config-bar .set-title .form-group input.form-control {
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  padding: 13px 24px !important;
  height: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.config-bar .set-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-bottom: 15px;
  border: none;
}
.config-bar .set-tabs li button {
  padding: 0 0 10px 0 !important;
  border: none !important;
  text-align: center;
  min-width: 142px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #818181;
  background: transparent !important;
  border: 2px solid transparent;
}
.config-bar .set-tabs li button.active , .config-bar .set-tabs li button:hover {
  border-bottom: 2px solid #000000 !important;
  color: #f40820;
}
.config-bar .set-content .setcontent-inner label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.config-bar .set-content .setcontent-inner input {
  padding: 13px 21px;
  height: auto;
  border: 1px solid #CBCBCB !important;
  color: #000000 !important;
}
.config-bar .set-content .setcontent-inner .formbtn {
  text-align: right;
}

.submitBtn {
  background-color: black;
}

.splitPart {
  color: #f40820 !important;
  cursor: pointer;
}

.form-split-div {
  background-color: #f8f8f8;
}